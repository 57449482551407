import React, { useState } from "react";

const RenderFile = ({ path, name, mimetype, ...props }) => {
  console.log("mimesType", mimetype);
  const [mime] = useState(new String(mimetype).split("/")[0]);
  const [src] = useState(
    (window.location.hostname == "localhost" ? "//localhost:2000" : "") + path
  );
  console.log({ src, mime });
  switch (mime) {
    case "image":
      return <img {...props} src={src} alt={name} />;
    case "video":
      return (
        <video {...props} src={src}>
          {name}
        </video>
      );
    case "audio":
      return (
        <audio {...props} src={src}>
          {name}
        </audio>
      );
    default:
      return (
        <iframe
          style={{ width: "100%", height: 500 }}
          src={src}
          frameborder="0"
          {...props}
        ></iframe>
      );
  }
};

export default RenderFile;
