import Webservice from "../../utils/networkApis";

export const LOST_REPORTS = "LOST_REPORTS";
export const LOST_REPORTS_SUCCESS = "LOST_REPORTS_SUCCESS";
export const LOST_REPORTS_FAILED = "LOST_REPORTS_FAILED";

export const ADD_LOST_REPORT = "ADD_LOST_REPORT";
export const ADD_LOST_REPORT_SUCCESS = "ADD_LOST_REPORT_SUCCESS";
export const ADD_LOST_REPORT_FAILED = "ADD_LOST_REPORT_FAILED";

export const EDIT_LOST_REPORT = "EDIT_LOST_REPORT";
export const EDIT_LOST_REPORT_SUCCESS = "EDIT_LOST_REPORT_SUCCESS";
export const EDIT_LOST_REPORT_FAILED = "EDIT_LOST_REPORT_FAILED";

export const DELETE_LOST_REPORT = "DELETE_LOST_REPORT";
export const DELETE_LOST_REPORT_SUCCESS = "DELETE_LOST_REPORT_SUCCESS";
export const DELETE_LOST_REPORT_FAILED = "DELETE_LOST_REPORT_FAILED";

export function getLostReports() {
  return dispatch => {
    dispatch({ type: LOST_REPORTS });
    Webservice.getLostReports().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: LOST_REPORTS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: LOST_REPORTS_FAILED, payload: response.error });
      }
    });
  };
}

export function addLostReport(data, history) {
  return dispatch => {
    dispatch({ type: ADD_LOST_REPORT });
    Webservice.addLostReport(data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_LOST_REPORT_SUCCESS, payload: response.data });
        history.push("/admin/LOST_REPORTs");
      } else {
        dispatch({ type: ADD_LOST_REPORT_FAILED, error: response.error });
      }
    });
  };
}

export function editLostReport(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_LOST_REPORT });
    Webservice.editLostReport(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_LOST_REPORT_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_LOST_REPORT_FAILED, error: response.error });
      }
    });
  };
}

export function deleteLostReport(id) {
  return dispatch => {
    dispatch({ type: DELETE_LOST_REPORT });
    Webservice.deleteLostReport(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_LOST_REPORT_SUCCESS,
          payload: { id }
        });
      } else {
        dispatch({ type: DELETE_LOST_REPORT_FAILED, error: response.error });
      }
    });
  };
}
