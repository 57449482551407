import Webservice from "../../utils/networkApis";

export const CONTENTS = "CONTENTS";
export const CONTENTS_SUCCESS = "CONTENTS_SUCCESS";
export const CONTENTS_FAILED = "CONTENTS_FAILED";

export const ADD_CONTENT = "ADD_CONTENT";
export const ADD_CONTENT_SUCCESS = "ADD_CONTENT_SUCCESS";
export const ADD_CONTENT_FAILED = "ADD_CONTENT_FAILED";

export const EDIT_CONTENT = "EDIT_LANGUAGE";
export const EDIT_CONTENT_SUCCESS = "EDIT_LANGUAGE_SUCCESS";
export const EDIT_CONTENT_FAILED = "EDIT_LANGUAGE_FAILED";

export const DELETE_CONTENT = "DELETE_CONTENTS";
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS";
export const DELETE_CONTENT_FAILED = "DELETE_CONTENT_FAILED";

export function getContents(type) {
  return dispatch => {
    dispatch({ type: CONTENTS });
    Webservice.getContents(type).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: CONTENTS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: CONTENTS_FAILED, payload: response.error });
      }
    });
  };
}

export function addContent(data, history) {
  return dispatch => {
    dispatch({ type: ADD_CONTENT });
    Webservice.addContent(data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_CONTENT_SUCCESS, payload: response.data });
        history.push("/admin/contents/all");
      } else {
        dispatch({ type: ADD_CONTENT_FAILED, error: response.error });
      }
    });
  };
}

export function editContent(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_CONTENT });
    Webservice.editContent(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_CONTENT_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_CONTENT_FAILED, error: response.error });
      }
    });
  };
}

export function deleteContent(id) {
  return dispatch => {
    dispatch({ type: DELETE_CONTENT });
    Webservice.deleteContent(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_CONTENT_SUCCESS,
          payload: { id }
        });
      } else {
        dispatch({ type: DELETE_CONTENT_FAILED, error: response.error });
      }
    });
  };
}
