import React, { PureComponent } from "react";
import { Col, Container, Row } from "reactstrap";
import Table from "./components/DataTable";
import { connect } from "react-redux";
import {
  getImportedHotels,
  deleteImportedHotel
} from "../../redux/actions/importedHotelsActions";

class ImportedHotels extends PureComponent {
  componentDidMount() {
    this.props.doGetImportHotel();
  }

  render() {
    // const data = this.props.attendances.map(item => {
    //   return {
    //     user: item.User ? `${item.User.first_name} ${item.User.last_name}` : "",
    //     itinerary: item.title,
    //     attending: item.attending ? "true" : "false",
    //     rating: item.rating ? item.rating : "-"
    //   };
    // });

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Imported Hotels</h3>
          </Col>
        </Row>
        <Row>
          <Table
            // entityName={entityName}
            data={this.props.importedHotels || []}
            error={this.props.error}
            success={id => {
              this.props.doDeleteImportHotel(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ importedHotel }) => {
  return {
    loading: importedHotel.loading,
    error: importedHotel.error,
    importedHotels: importedHotel.importedHotels
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doGetImportHotel: () => dispatch(getImportedHotels()),
    doDeleteImportHotel: id => dispatch(deleteImportedHotel(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportedHotels);
