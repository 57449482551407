import React, { Component } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import PropTypes from "prop-types";
import renderMultiSelectField from "../../../shared/components/form/MultiSelect";
import RadioButton from "../../../shared/components/form/CheckBox";
const renderField = ({ input, placeholder, type, meta: { touched, error } }) =>
	<div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
		<input {...input} placeholder={placeholder} type={type} />
		{touched &&
			error &&
			<span className="form__form-group-error">
				{error}
			</span>}
	</div>;

class AddUserForm extends Component {
	static propTypes = {
		t: PropTypes.func,
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired
		// error: PropTypes.string
	};

	constructor() {
		super();
		this.state = {
			showPassword: false,
			is_super_admin: true
		};
	}

	showPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({ showPassword: !prevState.showPassword }));
	};

	render() {
		const { handleSubmit, reset, t } = this.props;

		return (
			<form className="form form--horizontal" onSubmit={handleSubmit}>
				<div className="form__form-group">
					<span className="form__form-group-label">Email</span>
					<div className="form__form-group-field">
						<Field
							name="email"
							component={renderField}
							type="text"
							placeholder="Email"
						/>
					</div>
				</div>
				<div className="form__form-group">
					<span className="form__form-group-label">Password</span>
					<div className="form__form-group-field">
						<Field
							name="encrypted_password"
							component={renderField}
							type="password"
							placeholder="Password"
						/>
					</div>
				</div>
				<div className="form__form-group">
					<span className="form__form-group-label">Super Admin</span>
					<div className="form__form-group-field">
						<Field
							name="is_super_admin"
							value={this.state.is_super_admin}
							component={RadioButton}
							onChange={(ev) => {
								this.setState({
									is_super_admin: this.state.is_super_admin?false:true
								})
							}}
							label={'yes'}
							type="text"
							placeholder="Email"
						/>
					</div>
				</div>
				{!this.state.is_super_admin &&
					<div className="form__form-group">
						<span className="form__form-group-label">Groups</span>
						<div className="form__form-group-field">
							<Field
								name="groups"
								component={renderMultiSelectField}
								options={this.props.group}
							/>
						</div>
					</div>
				}

				<ButtonToolbar className="form__button-toolbar">
					<Button color="primary" type="submit">
						Submit
          </Button>
					<Button type="button" onClick={reset}>
						Cancel
          </Button>
				</ButtonToolbar>
			</form>
		);
	}
}

export default reduxForm({
	form: "add_admin_user_form",
	validate // a unique identifier for this form
})(AddUserForm);
