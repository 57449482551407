import {
    ADD_APP_SETTING,
    ADD_APP_SETTING_FAILED,
    ADD_APP_SETTING_SUCCESS,
    EDIT_APP_SETTING,
    EDIT_APP_SETTING_FAILED,
    EDIT_APP_SETTING_SUCCESS,
    DELETE_APP_SETTING,
    DELETE_APP_SETTING_FAILED,
    DELETE_APP_SETTING_SUCCESS,
    APP_SETTINGS,
    APP_SETTINGS_FAILED,
    APP_SETTINGS_SUCCESS
  } from "../actions/appSettingsActions";
  
  const initialState = {
    loading: false,
    appSettings: [],
    message: null,
    error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case APP_SETTINGS:
        return { ...state, loading: true, error: null };
      case APP_SETTINGS_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          appSettings: action.payload
        };
      case APP_SETTINGS_FAILED:
        return { ...state, loading: false, error: action.payload };
      case ADD_APP_SETTING:
        return { ...state, loading: true, error: null };
      case ADD_APP_SETTING_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          appSettings: [...state.appSettings, action.payload]
        };
      case ADD_APP_SETTING_FAILED:
        return { ...state, loading: false, error: action.error };
      case DELETE_APP_SETTING:
        return { ...state, loading: true, error: null };
      case DELETE_APP_SETTING_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          appSettings: state.appSettings.filter(
            item => item.id !== action.payload.id
          )
        };
      case DELETE_APP_SETTING_FAILED:
        return { ...state, loading: false, error: action.error };
      case EDIT_APP_SETTING:
        return { ...state, loading: true, error: null };
      case EDIT_APP_SETTING_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          message: action.payload.message,
          appSettings: state.appSettings.map(
            item =>
              item.id == action.payload.data.id ? action.payload.data : item
          )
        };
      case EDIT_APP_SETTING_FAILED:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  }
  