import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Table from './components/DataTable';
import { connect } from 'react-redux';
import {
  getAnnouncements,
  deleteAnnouncement,
} from '../../redux/actions/announcementsActions';
import Loading from '../../shared/components/Loading';

class Announcements extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: Announcements._parseData(props.announcements || [], props.meta),
      announcements: props.announcements,
      meta: props.meta,
    };
  }
  componentDidMount() {
    this.props.doGetAnnouncement();
  }
  static _parseData(announcements, meta) {
    console.log({ announcements, meta: JSON.stringify(meta, null, 0) });
    return announcements.map((item) => {
      return {
        id: item.id,
        groups:
          meta && meta.groupsKeys
            ? item.groups && item.groups.length
              ? item.groups.map((g) => meta.groupsKeys[g])
              : 'None'
            : 'Loading',
        title: item.title || '',
        important: item.important ? 'true' : 'false',
      };
    });
  }
  static getDerivedStateFromProps(props, state) {
    if (
      props.announcements !== state.announcements ||
      state.meta !== props.meta
    ) {
      return {
        data: Announcements._parseData(props.announcements || [], props.meta),
        announcements: props.announcements,
        meta: props.meta,
      };
    }
    return null;
  }
  render() {
    // const data = this.props.announcements.map(item => {
    //   return {
    //     id: item.id,
    //     groups: item.groups.length ? item.groups.join(',') : 'None',
    //     title: item.title || "",
    //     important: item.important ? "true" : "false"
    //   };
    // });

    return (
      <Container>
        <Row>
          <Col md={9}>
            <h3 className="page-title">Announcements</h3>
          </Col>
          <Col md={3}>
            <Link
              to="/admin/add-announcement"
              className="btn btn-primary btn-sm products-list__btn-add"
            >
              New Message
            </Link>
          </Col>
        </Row>
        <Row>
          {this.props.loading && !this.state.data.length && (
            <Loading loading={!this.props.loading ? true : false} />
          )}

          <Table
            // entityName={entityName}
            meta={this.state.meta}
            refreshData={() => this.props.doGetAnnouncement()}
            data={this.state.data}
            error={this.props.error}
            success={(id) => {
              alert(id);
              this.props.doDeleteItineraries(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ announcement, metaData }) => {
  return {
    loading: announcement.loading,
    error: announcement.error,
    announcements: announcement.announcements,
    meta: metaData.metaData,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAnnouncement: () => dispatch(getAnnouncements()),
    doDeleteItineraries: (id) => dispatch(deleteAnnouncement(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
