import Webservice from "../../utils/networkApis";

export const IMPORTED_HOTELS = "IMPORTED_HOTELS";
export const IMPORTED_HOTELS_SUCCESS = "IMPORTED_HOTELS_SUCCESS";
export const IMPORTED_HOTELS_FAILED = "IMPORTED_HOTELS_FAILED";

export const ADD_IMPORTED_HOTEL = "ADD_IMPORTED_HOTEL";
export const ADD_IMPORTED_HOTEL_SUCCESS = "ADD_IMPORTED_HOTEL_SUCCESS";
export const ADD_IMPORTED_HOTEL_FAILED = "ADD_IMPORTED_HOTEL_FAILED";

export const EDIT_IMPORTED_HOTEL = "EDIT_IMPORTED_HOTEL";
export const EDIT_IMPORTED_HOTEL_SUCCESS = "EDIT_IMPORTED_HOTEL_SUCCESS";
export const EDIT_IMPORTED_HOTEL_FAILED = "EDIT_IMPORTED_HOTEL_FAILED";

export const DELETE_IMPORTED_HOTEL = "DELETE_IMPORTED_HOTEL";
export const DELETE_IMPORTED_HOTEL_SUCCESS = "DELETE_IMPORTED_HOTEL_SUCCESS";
export const DELETE_IMPORTED_HOTEL_FAILED = "DELETE_IMPORTED_HOTEL_FAILED";

export function getImportedHotels() {
  return dispatch => {
    dispatch({ type: IMPORTED_HOTELS });
    Webservice.getImportedHotels().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: IMPORTED_HOTELS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: IMPORTED_HOTELS_FAILED, payload: response.error });
      }
    });
  };
}

export function addImportedHotel(data, history) {
  return dispatch => {
    dispatch({ type: ADD_IMPORTED_HOTEL });
    Webservice.addImportedHotel(data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_IMPORTED_HOTEL_SUCCESS, payload: response.data });
        history.push("/admin/importedHotels");
      } else {
        dispatch({ type: ADD_IMPORTED_HOTEL_FAILED, error: response.error });
      }
    });
  };
}

export function editImportedHotel(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_IMPORTED_HOTEL });
    Webservice.editImportedHotel(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_IMPORTED_HOTEL_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_IMPORTED_HOTEL_FAILED, error: response.error });
      }
    });
  };
}

export function deleteImportedHotel(id) {
  return dispatch => {
    dispatch({ type: DELETE_IMPORTED_HOTEL });
    Webservice.deleteImportedHotel(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_IMPORTED_HOTEL_SUCCESS,
          payload: { id }
        });
      } else {
        dispatch({ type: DELETE_IMPORTED_HOTEL_FAILED, error: response.error });
      }
    });
  };
}
