import Webservice from "../../utils/networkApis";

export const META_DATA = "META_DATA";
export const META_DATA_SUCCESS = "META_DATA_SUCCESS";
export const META_DATA_FAILED = "META_DATA_FAILED";

export function getMetaData() {
  return dispatch => {
    dispatch({ type: META_DATA });
    Webservice.metaData().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: META_DATA_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: META_DATA_FAILED, payload: response.error });
      }
    });
  };
}
