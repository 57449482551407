import React from "react";
import ShowEntity from "../../shared/components/ShowEntity";
import { addGuide, editGuide } from "../../redux/actions/guidesActions";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import webservice from "../../utils/networkApis";
import LeafletMap from "../GoogleMap/components/BasicMap";
import { Link } from "react-router-dom";
import RenderFile from "../../shared/components/RenderFile";

class ShowWhiteBoard extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null,
  };

  fetchIt = (id) => {
    this.setState({ loading: true, id });
    webservice.getOnewhiteboard(id).then((resp) => {
      let data = resp.data.data;

      this.setState({ initialValues: data, loading: false });
    });
  };

  componentDidMount() {
    this.fetchIt(this.props.match.params.id);
  }

  componentWillReceiveProps(props) {
    let id = props.match.params.id;

    if (id != this.state.id) this.fetchIt(id);
  }

  render() {
    const { loading, initialValues } = this.state;

    return (
      <ShowEntity
        values={
          initialValues
            ? [
                {
                  label: "GROUP",
                  value:
                    initialValues &&
                    initialValues.groupsData.length == 0 ? null : (
                      <Table responsive hover>
                        <thead>
                          <tr>
                            <th>Leader</th>
                            <th>Mobile</th>
                            <th>Starts On</th>
                            <th>Ends On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialValues.groupsData.map((item) => (
                            <tr>
                              <td>{item.leader}</td>
                              <td>{item.mobile}</td>
                              <td>{item.starts_on}</td>
                              <td>{item.ends_on}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ),
                },
                {
                  label: "IMPORTANT",
                  value: initialValues.important ? "true" : "false",
                },
                {
                  label: "LAT",
                  value: initialValues.lat,
                },
                {
                  label: "LONG",
                  value: initialValues.long,
                },
                {
                  label: "Location",
                  value: (
                    <LeafletMap
                      value={
                        (initialValues.lat &&
                          initialValues.long && {
                            lat: initialValues.lat,
                            lng: initialValues.lat,
                          }) ||
                        null
                      }
                    />
                  ),
                },
                {
                  label: "ATTACHMENT",
                  value: (initialValues.attachment || []).map((file) => (
                    <RenderFile {...file} />
                  )),
                },
                {
                  label: "LINK",
                  value: initialValues.link,
                },
                {
                  label: "CREATED AT",
                  value: initialValues.created_at,
                },
              ]
            : []
        }
        loading={loading}
        label={`Show WhiteBoard`}
      />
    );
  }
}

const mapStateToProps = ({ guide, metaData }) => {
  return {
    loading: guide.loading,
    error: guide.error,
    contents: guide.contents,
    meta: metaData.metaData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddGuide: (obj, props) => dispatch(addGuide(obj, props)),
    doEditGuide: (obj, id) => dispatch(editGuide(obj, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowWhiteBoard);
