import React from "react";
import { login } from "../../redux/actions/loginActions";
import { connect } from "react-redux";
import LogInForm from "./components/LogInForm";

const LogIn = (props) => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            Welcome to
            <span className="account__logo">
              Mishkaat
              <span className="account__logo-accent">Admin</span>
            </span>
          </h3>
        </div>
        <p>{props.error ? props.error.error : null}</p>
        <LogInForm
          onSubmit={(values) => props.doLogin(values, props)}
          loading={props.loading}
        />
      </div>
    </div>
  </div>
);

const mapStateToProps = ({ login }) => {
  return {
    loading: login.loading,
    error: login.error,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doLogin: (obj, props) => dispatch(login(obj, props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
