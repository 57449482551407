import React from "react";
import ShowEntity from "../../shared/components/ShowEntity";
import { addGuide, editGuide } from "../../redux/actions/guidesActions";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import webservice from "../../utils/networkApis";
import LeafletMap from "../GoogleMap/components/BasicMap";
import { Link } from "react-router-dom";
import RenderFile from "../../shared/components/RenderFile";

class ShowWhiteBoard extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null
  };

  fetchIt = id => {
    this.setState({ loading: true, id });
    webservice.getOneHelpme(id).then(resp => {
      let data = resp.data.data;

      this.setState({ initialValues: data, loading: false });
    });
  };

  componentDidMount() {
    this.fetchIt(this.props.match.params.id);
  }

  componentWillReceiveProps(props) {
    let id = props.match.params.id;

    if (id != this.state.id) this.fetchIt(id);
  }

  render() {
    const { loading, initialValues } = this.state;

    return (
      <ShowEntity
        values={
          initialValues
            ? [
                {                
                  label: "TYPE",
                  value: initialValues.type 
                },
                {
                  label: "NAME",
                  value: initialValues.name
                },
                {
                  label: "CREATED AT",
                  value: initialValues.created_at
                },
                {
                  label: "UPDATED AT",
                  value: initialValues.updated_at
                }
              ]
            : []
        }
        loading={loading}
        label={`Show Help Me`}
      />
    );
  }
}

const mapStateToProps = ({ guide, metaData }) => {
  return {
    loading: guide.loading,
    error: guide.error,
    contents: guide.contents,
    meta: metaData.metaData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doAddGuide: (obj, props) => dispatch(addGuide(obj, props)),
    doEditGuide: (obj, id) => dispatch(editGuide(obj, id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowWhiteBoard);
