import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { addContact } from "../../redux/actions/contactsActions";
import { connect } from "react-redux";
import ShowEntity from "../../shared/components/ShowEntity";
import webservice from "../../utils/networkApis";
import { formatDate } from "../../utils/general";

class AddContacts extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null,
  };

  fetchIt = (id) => {
    this.setState({ loading: true, id });
    webservice.showContact(id).then((resp) => {
      let data = resp.data.data;
      this.setState({ initialValues: data, loading: false });
    });
  };

  componentDidMount() {
    this.fetchIt(this.props.match.params.id);
  }

  componentWillReceiveProps(props) {
    let id = props.match.params.id;
    //console.log(id)

    if (id != this.state.id) this.fetchIt(id);
  }

  render() {
    const { loading, initialValues } = this.state;

    const { meta } = this.props;
    //console.log(initialValues)
    return (
      <ShowEntity
        values={
          initialValues
            ? [
                {
                  label: "Group",
                  value:
                    initialValues.groupsData &&
                    initialValues.groupsData.map((value, index) => (
                      <Link
                        style={{ margin: "10px 15px" }}
                        to={`/admin/group/${value.id}`}
                      >
                        {(value.leader && value.leader) || ""}
                      </Link>
                    )),
                },
                { label: "Id", value: initialValues.id },
                {
                  label: "SORT INDEX",
                  value: initialValues.sort_index,
                },
                {
                  label: "TITLE",
                  value: initialValues.translations.title,
                },
                {
                  label: "PHONE",
                  value: initialValues.phone,
                },
                {
                  label: "MEDINA ROOM",
                  value: initialValues.room_medina,
                },
                {
                  label: "MAKKAH ROOM",
                  value: initialValues.room_makkah,
                },
                {
                  label: "Created At",
                  value: formatDate(initialValues.createdAt),
                },
                {
                  label: "Updated At",
                  value: formatDate(initialValues.updatedAt),
                },
              ]
            : []
        }
        loading={loading}
        label={`Show ${initialValues ? initialValues.translations.title : ""}`}
      />
    );
  }
}

const mapStateToProps = ({ user, metaData }) => {
  return {
    loading: user.loading,
    error: user.error,
    meta: metaData.metaData,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddContact: (obj, props) => dispatch(addContact(obj, props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContacts);
