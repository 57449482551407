import {
    CONTACTS,
    CONTACTS_SUCCESS,
    CONTACTS_FAILED,
    ADD_CONTACT,
    ADD_CONTACT_FAILED,
    ADD_CONTACT_SUCCESS,
    EDIT_CONTACT,
    EDIT_CONTACT_FAILED,
    EDIT_CONTACT_SUCCESS,
    DELETE_CONTACT,
    DELETE_CONTACT_FAILED,
    DELETE_CONTACT_SUCCESS
  } from "../actions/contactsActions";
  
  const initialState = {
    loading: false,
    contacts: [],
    message: null,
    data: null,
    error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case CONTACTS:
        return { ...state, loading: true, error: null };
      case CONTACTS_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          contacts: action.payload
        };
      case CONTACTS_FAILED:
        return { ...state, loading: false, error: action.payload };
      case ADD_CONTACT:
        return { ...state, loading: true, error: null };
      case ADD_CONTACT_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          contacts: [...state.contacts, action.payload]
        };
      case ADD_CONTACT_FAILED:
        return { ...state, loading: false, error: action.error };
      case DELETE_CONTACT:
        return { ...state, loading: true, error: null };
      case DELETE_CONTACT_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          contacts: state.contacts.filter(
            item => item.id !== action.payload.id
          )
        };
      case DELETE_CONTACT_FAILED:
        return { ...state, loading: false, error: action.error };
      case EDIT_CONTACT:
        return { ...state, loading: true, error: null };
      case EDIT_CONTACT_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          message: action.payload.message,
          contacts: state.contacts.map(
            item =>
              item.id == action.payload.data.id ? action.payload.data : item
          )
        };
      case EDIT_CONTACT_FAILED:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  }
  