import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AddGuideForm from "./components/addGuideForm";
import { addGuide, editGuide } from "../../redux/actions/guidesActions";
import { translateTo } from "../../redux/actions/googleTranslation";
import { connect } from "react-redux";
import webservice from "../../utils/networkApis";
import {
  transformTransalationsFromBackend,
  transformTransalationsForBackend,
} from "../../utils/dataTransformerForAPI";

class AddGuides extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null,
  };
  componentDidMount() {
    const id = this.props.match.params.id;
    webservice.getOneGuide(id).then((resp) => {
      let data = resp.data.data;
      console.log({ data });
      data = {
        ...data,
        translations: transformTransalationsFromBackend(
          data.translations || []
        ),
      };
      this.setState({ initialValues: data, loading: false });
    });
  }

  onTranslate = (terms, source, target, callback) => {
    this.props.doTranslate(terms, source, target, () => {
      callback(this.props.translations);
    });
  };

  render() {
    const { loading, initialValues } = this.state;

    const { error } = this.props;
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Edit Guide </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                {loading ? (
                  <div className={`load`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path
                          fill="#4ce1b6"
                          d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <AddGuideForm
                    isEditing
                    _error={error}
                    initialValues={initialValues}
                    languages={this.props.meta ? this.props.meta.languages : []}
                    guides={this.props.meta ? this.props.meta.guides : []}
                    sect={this.props.meta ? this.props.meta.sect : []}
                    onTranslate={this.onTranslate}
                    onSubmit={(values) => {
                      this.props.doEditGuide(
                        {
                          ...values,
                          sect: values.sect.value,
                          translations: transformTransalationsForBackend(
                            values.translations || {}
                          ),
                        },
                        this.props.match.params.id
                      );
                      const id = this.props.match.params.id;
                      this.props.history.push(`/admin/guide/${id}`);
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ guide, metaData, googleTranslation }) => {
  return {
    loading: guide.loading || googleTranslation.loading,
    error: guide.error || googleTranslation.error,
    contents: guide.contents,
    meta: metaData.metaData,
    translations: googleTranslation.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddGuide: (obj, props) => dispatch(addGuide(obj, props)),
    doEditGuide: (obj, id) => dispatch(editGuide(obj, id)),
    doTranslate: (terms, source, target, cb) =>
      dispatch(translateTo(terms, source, target, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGuides);
