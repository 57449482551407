import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import PropTypes from "prop-types";
// import Table from "./components/DataTable";
import AddLanguageForm from "./components/addLanguageForm";
import { addLanguage } from "../../redux/actions/languagesActions";
import { connect } from "react-redux";
import Alert from "../../shared/components/Alert";

class AddLanguages extends React.Component {
  render() {
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">New Language </h3>
            {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
                information
          </h3> */}
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Add Language</h5>
                  {/* <h5 className="subhead">Labels are left from fields</h5> */}
                </div>
                <Alert
                  color="danger"
                  visible={this.props.error !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Warning!</span>
                    {this.props.error}
                  </p>
                </Alert>
                <AddLanguageForm
                  onSubmit={values => {
                    const setDataForPost = {
                      title: values.title,
                      locale: values.locale,
                      direction: values.direction,
                      is_default: false
                    };

                    this.props.doAddLanguage(
                      setDataForPost,
                      this.props.history
                    );
                  }}
                  error={this.props.error}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ language }) => {
  return {
    loading: language.loading,
    error: language.error
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doAddLanguage: (obj, props) => dispatch(addLanguage(obj, props))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLanguages);
