import React from "react";
import { addContact } from "../../redux/actions/contactsActions";
import { connect } from "react-redux";
import ShowEntity from "../../shared/components/ShowEntity";
import webservice from "../../utils/networkApis";

class ShowLanguages extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null
  };

  fetchIt = id => {
    this.setState({ loading: true, id });
    webservice.getOneLanguage(id).then(resp => {
      let data = resp.data.data;

      this.setState({ initialValues: data, loading: false });
    });
  };

  componentDidMount() {
    this.fetchIt(this.props.match.params.id);
  }

  componentWillReceiveProps(props) {
    let id = props.match.params.id;

    if (id != this.state.id) this.fetchIt(id);
  }

  render() {
    const { loading, initialValues } = this.state;

    const { meta } = this.props;
    return (
      <ShowEntity
        values={
          initialValues
            ? [
                { label: "ID", value: initialValues.id },
                {
                  label: "TITLE",
                  value: initialValues.title
                },
                {
                  label: "LOCALE",
                  value: initialValues.locale
                },
                {
                  label: "DIRECTION",
                  value: initialValues.direction === 0 ? "Ltr" : "Rtl"
                },
                {
                  label: "IS DEFAULT",
                  value: initialValues.is_default ? "true" : "false"
                },
                {
                  label: "CREATED AT",
                  value: initialValues.created_at
                },
                {
                  label: "UPDATED AT",
                  value: initialValues.updated_at
                }
              ]
            : []
        }
        loading={loading}
        label={`Show ${initialValues ? initialValues.title : ""}`}
      />
    );
  }
}

const mapStateToProps = ({ user, metaData }) => {
  return {
    loading: user.loading,
    error: user.error,
    meta: metaData.metaData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doAddContact: (obj, props) => dispatch(addContact(obj, props))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowLanguages);
