import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import PropTypes from 'prop-types';
// import Table from "./components/DataTable";
import AddFormInput from './components/addFormInput';
import { addContact } from '../../redux/actions/contactsActions';
import { connect } from 'react-redux';
import Alert from '../../shared/components/Alert';
import { transformTransalationsForBackend } from '../../utils/dataTransformerForAPI';

class AddContacts extends React.Component {
  render() {
    // const { languages, genders, sect } = this.props.meta;

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">New Contact </h3>
            {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
                information
          </h3> */}
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Add Contact</h5>
                </div>
                <Alert
                  color="danger"
                  visible={this.props.error !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Warning!</span>
                    {this.props.error}
                  </p>
                </Alert>
                <AddFormInput
                  language={this.props.meta ? this.props.meta.languages : []}
                  group={this.props.meta ? this.props.meta.groups : []}
                  onSubmit={(values) => {
                    console.log('values', values);
                    this.props.doAddContact(
                      {
                        ...values,
                        translations: transformTransalationsForBackend(
                          values.translations || {}
                        ),
                      },
                      this.props.history
                    );
                    //console.log(value)
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ user, metaData }) => {
  return {
    loading: user.loading,
    error: user.error,
    meta: metaData.metaData,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddContact: (obj, props) => dispatch(addContact(obj, props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContacts);
