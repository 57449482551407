import {
    ANNOUNCEMENTS,
    ANNOUNCEMENTS_FAILED,
    ANNOUNCEMENTS_SUCCESS,
    ADD_ANNOUNCEMENT,
    ADD_ANNOUNCEMENT_FAILED,
    ADD_ANNOUNCEMENT_SUCCESS,
    DELETE_ANNOUNCEMENT,
    DELETE_ANNOUNCEMENT_FAILED,
    DELETE_ANNOUNCEMENT_SUCCESS,
    EDIT_ANNOUNCEMENT,
    EDIT_ANNOUNCEMENT_FAILED,
    EDIT_ANNOUNCEMENT_SUCCESS
  } from "../actions/announcementsActions";
  
  const initialState = {
    loading: false,
    announcements: [],
    message: null,
    data: null,
    error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case ANNOUNCEMENTS:
        return { ...state, loading: true, error: null };
      case ANNOUNCEMENTS_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          announcements: action.payload
        };
      case ANNOUNCEMENTS_FAILED:
        return { ...state, loading: false, error: action.payload };
      case ADD_ANNOUNCEMENT:
        return { ...state, loading: true, error: null };
      case ADD_ANNOUNCEMENT_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          announcements: [...state.announcements, action.payload]
        };
      case ADD_ANNOUNCEMENT_FAILED:
        return { ...state, loading: false, error: action.error };
      case DELETE_ANNOUNCEMENT:
        return { ...state, loading: true, error: null };
      case DELETE_ANNOUNCEMENT_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          announcements: state.announcements.filter(
            item => item.id !== action.payload.id
          )
        };
      case DELETE_ANNOUNCEMENT_FAILED:
        return { ...state, loading: false, error: action.error };
      case EDIT_ANNOUNCEMENT:
        return { ...state, loading: true, error: null };
      case EDIT_ANNOUNCEMENT_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          message: action.payload.message,
          announcements: state.announcements.map(
            item =>
              item.id == action.payload.data.id ? action.payload.data : item
          )
        };
      case EDIT_ANNOUNCEMENT_FAILED:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  }
  