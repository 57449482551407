import Webservice from "../../utils/networkApis";

export const HELPME = "HELPMES";
export const HELPME_SUCCESS = "HELPMES_SUCCESS";
export const HELPME_FAILED = "HELPMES_FAILED";


export const EDIT_HELPME = "EDIT_HELPME";
export const EDIT_HELPME_SUCCESS = "EDIT_HELPME_SUCCESS";
export const EDIT_HELPME_FAILED = "EDIT_HELPME_FAILED";

export const DELETE_HELPME = "DELETE_HELPME";
export const DELETE_HELPME_SUCCESS = "DELETE_HELPME_SUCCESS";
export const DELETE_HELPME_FAILED = "DELETE_HELPME_FAILED";

export function getHelpme() {
  return dispatch => {
    dispatch({ type: HELPME });
    Webservice.getHelpme().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: HELPME_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: HELPME_FAILED, payload: response.error });
      }
    });
  };
}



export function editHelpme(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_HELPME });
    Webservice.editHelpme(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_HELPME_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_HELPME_FAILED, error: response.error });
      }
    });
  };
}

export function deleteHelpme(id) {
  return dispatch => {
    dispatch({ type: DELETE_HELPME });
    Webservice.deleteHelpme(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_HELPME_SUCCESS,
          payload: { id }
        });
        // dispatch(getHELPMEsTree())
      } else {
        dispatch({ type: DELETE_HELPME_FAILED, error: response.error });
      }
    });
  };
}
