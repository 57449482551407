import Webservice from "../../utils/networkApis";

export const GROUPS = "GROUPS";
export const GROUPS_SUCCESS = "GROUPS_SUCCESS";
export const GROUPS_FAILED = "GROUPS_FAILED";

export const ADD_GROUP = "ADD_GROUP";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_FAILED = "ADD_GROUP_FAILED";

export const EDIT_GROUP = "EDIT_GROUP";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";
export const EDIT_GROUP_FAILED = "EDIT_GROUP_FAILED";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILED = "DELETE_GROUP_FAILED";

export function getGroups() {
  return dispatch => {
    dispatch({ type: GROUPS });
    Webservice.getGroups().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: GROUPS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: GROUPS_FAILED, payload: response.error });
      }
    });
  };
}

export function addGroup(data, history) {
  return dispatch => {
    dispatch({ type: ADD_GROUP });
    Webservice.addGroups(data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_GROUP_SUCCESS, payload: response.data });
        history.push("/admin/groups");
      } else {
        dispatch({ type: ADD_GROUP_FAILED, error: response.error });
      }
    });
  };
}

export function editGroup(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_GROUP });
    Webservice.editGroup(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_GROUP_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_GROUP_FAILED, error: response.error });
      }
    });
  };
}

export function deleteGroup(id) {
  return dispatch => {
    dispatch({ type: DELETE_GROUP });
    Webservice.deleteGroup(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_GROUP_SUCCESS,
          payload: { id }
        });
      } else {
        dispatch({ type: DELETE_GROUP_FAILED, error: response.error });
      }
    });
  };
}
