import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

class DateTimePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      date: null,
    };
  }

  handleChange = (date) => {
    const { onChange } = this.props;
    this.setState({
      date,
    });
    onChange(date.toString());
  };
  componentWillReceiveProps(props) {
    if (props.value !== this.props.value) {
      console.log(props.value);
      let newDate = new Date(props.value);
      if (newDate !== this.state.date)
        //(props)
        this.setState({
          date: newDate,
        });
    }
  }
  render() {
    const { date } = this.state;

    const { timezoneDate, timezone } = this.props;

    console.log(date);

    return (
      <div className="date-picker">
        <DatePicker
          timeFormat="HH:mm"
          className="form__form-group-datepicker"
          selected={date}
          onChange={this.handleChange}
          showTimeSelect
          dateFormat="MM/dd/yyyy HH:mm"
          dropDownMode="select"
        />
      </div>
    );
  }
}

const renderDateTimePickerField = (props) => {
  const { input, timezone, timezoneDate } = props;
  console.log(props);
  return (
    <DateTimePickerField
      {...input}
      timezone={timezone}
      timezoneDate={timezoneDate}
    />
  );
};

renderDateTimePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default renderDateTimePickerField;
