import {
  LOST_REPORTS,
  LOST_REPORTS_FAILED,
  LOST_REPORTS_SUCCESS,
  ADD_LOST_REPORT,
  ADD_LOST_REPORT_FAILED,
  ADD_LOST_REPORT_SUCCESS,
  EDIT_LOST_REPORT,
  EDIT_LOST_REPORT_FAILED,
  EDIT_LOST_REPORT_SUCCESS,
  DELETE_LOST_REPORT,
  DELETE_LOST_REPORT_FAILED,
  DELETE_LOST_REPORT_SUCCESS
} from "../actions/lostReportsActions";

const initialState = {
  loading: false,
  lostReports: [],
  message: null,
  data: null,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOST_REPORTS:
      return { ...state, loading: true, error: null };
    case LOST_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        lostReports: action.payload
      };
    case LOST_REPORTS_FAILED:
      return { ...state, loading: false, error: action.payload };
    case ADD_LOST_REPORT:
      return { ...state, loading: true, error: null };
    case ADD_LOST_REPORT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        lostReports: [...state.LOST_REPORTs, action.payload]
      };
    case ADD_LOST_REPORT_FAILED:
      return { ...state, loading: false, error: action.error };
    case DELETE_LOST_REPORT:
      return { ...state, loading: true, error: null };
    case DELETE_LOST_REPORT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        lostReports: state.lostReports.filter(
          item => item.id !== action.payload.id
        )
      };
    case DELETE_LOST_REPORT_FAILED:
      return { ...state, loading: false, error: action.error };
    case EDIT_LOST_REPORT:
      return { ...state, loading: true, error: null };
    case EDIT_LOST_REPORT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        message: action.payload.message,
        lostReports: state.lostReports.map(
          item =>
            item.id == action.payload.data.id ? action.payload.data : item
        )
      };
    case EDIT_LOST_REPORT_FAILED:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
}
