import {
    GUIDES,
    GUIDES_FAILED,
    GUIDES_SUCCESS,
    ADD_GUIDE,
    ADD_GUIDE_FAILED,
    ADD_GUIDE_SUCCESS,
    DELETE_GUIDE,
    DELETE_GUIDE_FAILED,
    DELETE_GUIDE_SUCCESS,
    EDIT_GUIDE,
    EDIT_GUIDE_FAILED,
    EDIT_GUIDE_SUCCESS
  } from "../actions/guidesActions";
  
  const initialState = {
    loading: false,
    guides: [],
    message: null,
    data: null,
    error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case GUIDES:
        return { ...state, loading: true, error: null };
      case GUIDES_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          guides: action.payload
        };
      case GUIDES_FAILED:
        return { ...state, loading: false, error: action.payload };
      case ADD_GUIDE:
        return { ...state, loading: true, error: null };
      case ADD_GUIDE_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          guides: [...state.guides, action.payload]
        };
      case ADD_GUIDE_FAILED:
        return { ...state, loading: false, error: action.error };
      case DELETE_GUIDE:
        return { ...state, loading: true, error: null };
      case DELETE_GUIDE_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          guides: state.guides.filter(
            item => item.guide_id !== action.payload.id
          )
        };
      case DELETE_GUIDE_FAILED:
        return { ...state, loading: false, error: action.error };
      case EDIT_GUIDE:
        return { ...state, loading: true, error: null };
      case EDIT_GUIDE_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          message: action.payload.message,
          guides: state.guides.map(
            item =>
              item.id == action.payload.data.id ? action.payload.data : item
          )
        };
      case EDIT_GUIDE_FAILED:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  }
  