import React, { PureComponent } from "react";
import { Col, Container, Row } from "reactstrap";
import Table from "./components/DataTable";
import {
  getItineraries,
  deleteItinerarie,
} from "../../redux/actions/itinerariesActions";
import { connect } from "react-redux";
import Loading from "../../shared/components/Loading";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/general";

class Itineraries extends PureComponent {
  componentDidMount() {
    this.props.doGetItineraries();
  }

  render() {
    console.log(
      "itineraries",
      this.props.itineraries.find((i) => i.title === "4:00 pm")
    );

    const data = this.props.itineraries.map((item) => {
      // console.log(`time:21:${item.title}`, {
      //   initial: item.time,
      //   time: new Date(item.time).getTime(),
      //   type: typeof item.time,
      // });

      return {
        id: item.id,
        leader: item.group ? item.group.leader : "N/A",
        group_id: item.group_id,
        title: item.title || "N/A",
        place: item.content ? item.content.type : "N/A",
        time: item.time
          ? formatDate(item.time, item.zone || "Asia/Riyadh")
          : "N/A",
      };
    });
    // .sort((a, b) => new Date(b.time) - new Date(a.time));

    return (
      <Container>
        <Row>
          <Col md={9}>
            <h3 className="page-title">Programs</h3>
          </Col>
          <Col md={3}>
            <Link
              to="/admin/itinerary/new"
              className="btn btn-primary products-list__btn-add"
            >
              New Program
            </Link>
          </Col>
        </Row>
        <Row>
          {this.props.loading ? (
            <Loading loading />
          ) : (
            <Table
              refreshData={() => this.props.doGetItineraries()}
              // entityName={entityName}
              data={data || []}
              meta={this.props.meta}
              error={this.props.error}
              success={(id) => {
                this.props.doDeleteItineraries(id);
              }}
            />
          )}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ Itinerarie, metaData }) => {
  return {
    loading: Itinerarie.loading,
    error: Itinerarie.error,
    itineraries: Itinerarie.itineraries,
    meta: metaData.metaData,

    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetItineraries: (type) => dispatch(getItineraries()),
    doDeleteItineraries: (id) => dispatch(deleteItinerarie(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Itineraries);
