import Webservice from "../../utils/networkApis";

export const USERS = "USERS";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAILED = "USERS_FAILED";

export const ADD_USER = "ADD_USERS";
export const ADD_USER_SUCCESS = "ADD_USERS_SUCCESS";
export const ADD_USER_FAILED = "ADD_USERS_FAILED";

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const IMPORT_AVATARS_USER = "IMPORT_AVATARS_USER";
export const IMPORT_AVATARS_USER_SUCCESS = "IMPORT_AVATARS_USER_SUCCESS";
export const IMPORT_AVATARS_USER_FAILED = "IMPORT_AVATARS_USER_FAILED";

export const VPP_FILENAME_CHANGER = "VPP_FILENAME_CHANGER";
export const VPP_FILENAME_CHANGER_SUCCESS = "VPP_FILENAME_CHANGER_SUCCESS";
export const VPP_FILENAME_CHANGER_FAILED = "VPP_FILENAME_CHANGER_FAILED";

export function importUsersAvatars() {
  return (dispatch) => {
    dispatch({ type: IMPORT_AVATARS_USER });
    Webservice.importUserAvatars().then((resp) => {
      const response = resp.data;
      console.log("response", response);
      if (response.success) {
        dispatch({ type: IMPORT_AVATARS_USER_SUCCESS, payload: response });
      } else {
        dispatch({ type: IMPORT_AVATARS_USER_FAILED, payload: response.error });
      }
    });
  };
}

export function vppFilenameChanger() {
  return (dispatch) => {
    dispatch({ type: VPP_FILENAME_CHANGER });
    Webservice.vppFilenameChanger().then((resp) => {
      const response = resp.data;
      console.log("response", response);
      if (response.success) {
        dispatch({ type: VPP_FILENAME_CHANGER_SUCCESS, payload: response });
      } else {
        dispatch({
          type: VPP_FILENAME_CHANGER_FAILED,
          payload: response.error,
        });
      }
    });
  };
}

export function getUsers() {
  return (dispatch) => {
    dispatch({ type: USERS });
    Webservice.getUsers().then((resp) => {
      const response = resp.data;
      console.log("response", response);
      if (response.success) {
        dispatch({ type: USERS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: USERS_FAILED, payload: response.error });
      }
    });
  };
}

export function addUser(data, history) {
  return (dispatch) => {
    dispatch({ type: ADD_USER });
    Webservice.addUsers(data).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_USER_SUCCESS, payload: response.data });
        history.push("/admin/users");
      } else {
        dispatch({ type: ADD_USER_FAILED, error: response.error });
      }
    });
  };
}

export function editUser(data, id) {
  return (dispatch) => {
    dispatch({ type: EDIT_USER });
    Webservice.editUser(id, data).then((resp) => {
      const response = resp.data;
      if (response.success) {
        console.log("resp", resp);
        dispatch({
          type: EDIT_USER_SUCCESS,
          payload: {
            data: { ...response.data, id },
            message: response.message,
          },
        });
      } else {
        dispatch({ type: EDIT_USER_FAILED, error: response.error });
      }
    });
  };
}

export function deleteUser(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_USER });
    Webservice.deleteUser(id).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_USER_SUCCESS,
          payload: { id },
        });
      } else {
        dispatch({ type: DELETE_USER_FAILED, error: response.error });
      }
    });
  };
}
