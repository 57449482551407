import React from "react";
import { addContact } from "../../redux/actions/contactsActions";
import { connect } from "react-redux";
import ShowEntity from "../../shared/components/ShowEntity";
import webservice from "../../utils/networkApis";

class ShowAppTranslation extends React.Component {
	state = {
		loading: true,
		initialValues: null,
		error: null
	};

	fetchIt = id => {
		this.setState({ loading: true, id });
		webservice.showAppTranslation(id).then(resp => {
			let data = resp.data.data;

			this.setState({ initialValues: data, loading: false });
		});
	};

	componentDidMount() {
		this.fetchIt(this.props.match.params.id);
	}

	componentWillReceiveProps(props) {
		let id = props.match.params.id;

		if (id != this.state.id) this.fetchIt(id);
	}

	render() {
		const { loading, initialValues } = this.state;

		const { meta } = this.props;
		return (
			<ShowEntity
				values={
					initialValues
						? [
							{ label: "ID", value: initialValues.id },
							{
								label: "KEY",
								value: initialValues.key
							},
							{
								label: "VALUE",
								value: initialValues.translations ? initialValues.translations.value : ""
							},
							{
								label: "CREATED AT",
								value: initialValues.created_at
							},
							{
								label: "UPDATED AT",
								value: initialValues.updated_at
							}
						]
						: []
				}
				loading={loading}
				label={`Show ${initialValues ? initialValues.key : ""}`}
			/>
		);
	}
}

const mapStateToProps = ({ user, metaData }) => {
	return {
		loading: user.loading,
		error: user.error,
		meta: metaData.metaData
	};
};

const mapDispatchToProps = dispatch => {
	return {
		doAddContact: (obj, props) => dispatch(addContact(obj, props))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowAppTranslation);
