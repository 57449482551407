/* eslint-disable */
const validate = (values, props) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email field shouldn’t be empty";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.encrypted_password) {
      errors.encrypted_password = "Password field shouldn’t be empty";
    }
  //   if (!values.select) {
  //     errors.select = "Please select the option";
  //   }
  
    return errors;
  };
  
  export default validate;
  