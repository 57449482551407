import {
    IMPORTED_HOTELS,
    IMPORTED_HOTELS_FAILED,
    IMPORTED_HOTELS_SUCCESS,
    ADD_IMPORTED_HOTEL,
    ADD_IMPORTED_HOTEL_FAILED,
    ADD_IMPORTED_HOTEL_SUCCESS,
    DELETE_IMPORTED_HOTEL,
    DELETE_IMPORTED_HOTEL_FAILED,
    DELETE_IMPORTED_HOTEL_SUCCESS,
    EDIT_IMPORTED_HOTEL,
    EDIT_IMPORTED_HOTEL_FAILED,
    EDIT_IMPORTED_HOTEL_SUCCESS
  } from "../actions/importedHotelsActions";
  
  const initialState = {
    loading: false,
    importedHotels: [],
    message: null,
    data: null,
    error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case IMPORTED_HOTELS:
        return { ...state, loading: true, error: null };
      case IMPORTED_HOTELS_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          importedHotels: action.payload
        };
      case IMPORTED_HOTELS_FAILED:
        return { ...state, loading: false, error: action.payload };
      case ADD_IMPORTED_HOTEL:
        return { ...state, loading: true, error: null };
      case ADD_IMPORTED_HOTEL_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          importedHotels: [...state.importedHotels, action.payload]
        };
      case ADD_IMPORTED_HOTEL_FAILED:
        return { ...state, loading: false, error: action.error };
      case DELETE_IMPORTED_HOTEL:
        return { ...state, loading: true, error: null };
      case DELETE_IMPORTED_HOTEL_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          importedHotels: state.importedHotels.filter(
            item => item.id !== action.payload.id
          )
        };
      case DELETE_IMPORTED_HOTEL_FAILED:
        return { ...state, loading: false, error: action.error };
      case EDIT_IMPORTED_HOTEL:
        return { ...state, loading: true, error: null };
      case EDIT_IMPORTED_HOTEL_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          message: action.payload.message,
          importedHotels: state.importedHotels.map(
            item =>
              item.id == action.payload.data.id ? action.payload.data : item
          )
        };
      case EDIT_IMPORTED_HOTEL_FAILED:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  }
  