import {
    WHITEBOARDS,
    WHITEBOARDS_FAILED,
    WHITEBOARDS_SUCCESS,
    ADD_WHITEBOARD,
    ADD_WHITEBOARD_FAILED,
    ADD_WHITEBOARD_SUCCESS,
    DELETE_WHITEBOARD,
    DELETE_WHITEBOARD_FAILED,
    DELETE_WHITEBOARD_SUCCESS,
    EDIT_WHITEBOARD,
    EDIT_WHITEBOARD_FAILED,
    EDIT_WHITEBOARD_SUCCESS
  } from "../actions/whiteboardActions";
  
  const initialState = {
    loading: false,
    whiteboards: [],
    message: null,
    data: null,
    error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case WHITEBOARDS:
        return { ...state, loading: true, error: null };
      case WHITEBOARDS_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          whiteboards: action.payload
        };
      case WHITEBOARDS_FAILED:
        return { ...state, loading: false, error: action.payload };
      case ADD_WHITEBOARD:
        return { ...state, loading: true, error: null };
      case ADD_WHITEBOARD_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          whiteboards: [...state.whiteboards, action.payload]
        };
      case ADD_WHITEBOARD_FAILED:
        return { ...state, loading: false, error: action.error };
      case DELETE_WHITEBOARD:
        return { ...state, loading: true, error: null };
      case DELETE_WHITEBOARD_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          whiteboards: state.whiteboards.filter(
            item => item.id !== action.payload.id
          )
        };
      case DELETE_WHITEBOARD_FAILED:
        return { ...state, loading: false, error: action.error };
      case EDIT_WHITEBOARD:
        return { ...state, loading: true, error: null };
      case EDIT_WHITEBOARD_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          message: action.payload.message,
          whiteboards: state.whiteboards.map(
            item =>
              item.id == action.payload.data.id ? action.payload.data : item
          )
        };
      case EDIT_WHITEBOARD_FAILED:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  }
  