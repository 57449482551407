import React, { useEffect } from "react";
import { addAdminUser } from "../../redux/actions/adminUsersActions";
import webservice from "../../utils/networkApis";
import { connect } from "react-redux";
import ShowEntity from "../../shared/components/ShowEntity";
import Alert from "../../shared/components/Alert";
import { Link } from "react-router-dom";

class AddAdminUsers extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null
  };

  fetchIt = id => {
    this.setState({ loading: true, id });
    webservice.getOneAdminUser(id).then(resp => {
      let data = resp.data.data;

      this.setState({ initialValues: data, loading: false });
    });
  };

  componentDidMount() {
    this.fetchIt(this.props.match.params.id);
  }

  componentWillReceiveProps(props) {
    let id = props.match.params.id;

    if (id != this.state.id) this.fetchIt(id);
  }

  render() {
    const { loading, initialValues } = this.state;

    const { meta } = this.props;

    return (
      <ShowEntity
        values={
          initialValues
            ? [
				{
					label: "Group",
					value: initialValues.groupsData&& initialValues.groupsData.map((value,index)=>(
					  <Link style={{margin: "10px 15px"}} to={`/admin/group/${value.id}`}>
						{(value.leader && value.leader) ||
						  ""}
					  </Link>
					))
				  },
                { label: "ID", value: initialValues.id },
                {
                  label: "EMAIL",
                  value: initialValues.email
                },
                {
                  label: "LAST SIGN IN AT",
                  value: ""
                },
                {
                  label: "CREATED AT",
                  value: initialValues.created_at
                }
              ]
            : []
        }
        loading={loading}
        label={`Show ${initialValues ? initialValues.email : ""}`}
      />
    );
  }
}

const mapStateToProps = ({ adminUser }) => {
  return {
    loading: adminUser.loading,
    error: adminUser.error,
    adminUsers: adminUser.adminUsers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doAddAdminUsers: (obj, props) => dispatch(addAdminUser(obj, props))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAdminUsers);
