import Webservice from "../../utils/networkApis";

export const LANGUAGES = "LANGUAGES";
export const LANGUAGES_SUCCESS = "LANGUAGES_SUCCESS";
export const LANGUAGES_FAILED = "LANGUAGES_FAILED";

export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const ADD_LANGUAGE_SUCCESS = "ADD_LANGUAGE_SUCCESS";
export const ADD_LANGUAGE_FAILED = "ADD_LANGUAGE_FAILED";

export const EDIT_LANGUAGE = "EDIT_LANGUAGE";
export const EDIT_LANGUAGE_SUCCESS = "EDIT_LANGUAGE_SUCCESS";
export const EDIT_LANGUAGE_FAILED = "EDIT_LANGUAGE_FAILED";

export const DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_FAILED = "DELETE_LANGUAGE_FAILED";

export function getLanguages() {
  return dispatch => {
    dispatch({ type: LANGUAGES });
    Webservice.getLanguages().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: LANGUAGES_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: LANGUAGES_FAILED, payload: response.error });
      }
    });
  };
}

export function addLanguage(data, history) {
  return dispatch => {
    dispatch({ type: ADD_LANGUAGE });
    Webservice.addLanguages(data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_LANGUAGE_SUCCESS, payload: response.data });
        history.push("/admin/languages");
      } else {
        dispatch({ type: ADD_LANGUAGE_FAILED, error: response.error });
      }
    });
  };
}

export function editLanguage(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_LANGUAGE });
    Webservice.editLanguage(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_LANGUAGE_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_LANGUAGE_FAILED, error: response.error });
      }
    });
  };
}

export function deleteLanguage(id) {
  return dispatch => {
    dispatch({ type: DELETE_LANGUAGE });
    Webservice.deleteLanguage(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_LANGUAGE_SUCCESS,
          payload: { id }
        });
      } else {
        dispatch({ type: DELETE_LANGUAGE_FAILED, error: response.error });
      }
    });
  };
}
