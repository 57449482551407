import { combineReducers, createStore, applyMiddleware } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import {
  sidebarReducer,
  themeReducer,
  metaDataReducer,
  rtlReducer,
  loginReducer,
  adminUsersReducer,
  usersReducer,
  languagesReducer,
  contactsReducer,
  contentsReducer,
  guideReducer,
  announcementReducer,
  groupsReducer,
  lostReportReducer,
  ItinerariesReducer,
  appTranslationReducer,
  attendanceReducer,
  importedUsersReducer,
  importedGroupsReducer,
  importedHotelsReducer,
  whiteboardReducer,
  helpmeReducer,
  appSettingsReducer,
  assistanceReducer,
  googleTranslationReducer,
} from "../../redux/reducers/index";
import thunk from "redux-thunk";

const middleware = applyMiddleware(thunk);

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  metaData: metaDataReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  login: loginReducer,
  adminUser: adminUsersReducer,
  user: usersReducer,
  language: languagesReducer,
  contact: contactsReducer,
  content: contentsReducer,
  guide: guideReducer,
  announcement: announcementReducer,
  group: groupsReducer,
  lostReport: lostReportReducer,
  Itinerarie: ItinerariesReducer,
  appTranslation: appTranslationReducer,
  attendance: attendanceReducer,
  importedUser: importedUsersReducer,
  importedGroup: importedGroupsReducer,
  importedHotel: importedHotelsReducer,
  whiteboard: whiteboardReducer,
  helpme: helpmeReducer,
  appSetting: appSettingsReducer,
  assistance: assistanceReducer,
  googleTranslation: googleTranslationReducer,
});

const store = createStore(reducer, middleware);

export default store;
