import React from "react";
import AddPlaceForm from "./components/Form/index";
import { addContent } from "../../redux/actions/contentsActions";
import { connect } from "react-redux";
import { transformTransalationsForBackend } from "../../utils/dataTransformerForAPI";
import { translateTo } from "../../redux/actions/googleTranslation";

class AddPlaces extends React.Component {

  onTranslate = (terms, source, target, callback) => {
    this.props.doTranslate(terms, source, target, () => {
      callback(this.props.translations);
    });
  };

  render() {
    return (
      <AddPlaceForm
        heading={"Add Place"}
        error
        languages={this.props.meta ? this.props.meta.languages : []}
        onTranslate={this.onTranslate}
        onSubmit={(values) => {
          this.props.doAddContent(
            {
              ...values,
              translations: transformTransalationsForBackend(
                values.translations || {}
              ),
            },
            this.props.history
          );
        }}
      />
    );
  }
}

const mapStateToProps = ({ content, metaData, googleTranslation }) => {
  return {
    loading: content.loading || googleTranslation.loading,
    error: content.error || googleTranslation.error,
    contents: content.contents,
    meta: metaData.metaData,
    translations: googleTranslation.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddContent: (obj, props) => dispatch(addContent(obj, props)),
    doTranslate: (terms, source, target, cb) =>
      dispatch(translateTo(terms, source, target, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlaces);
