import React, { useEffect } from "react";
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  Button,
  ButtonToolbar
} from "reactstrap";
import moment from "moment";
import { editLanguage } from "../../redux/actions/languagesActions";
import Alert from "../../shared/components/Alert";
import Webservice from "../../utils/networkApis";
import Select from "react-select";
import { connect } from "react-redux";
import TimetableIcon from "mdi-react/TimetableIcon";
import DatePicker from "react-datepicker";
const scrollToTop = () => {
	const c = document.documentElement.scrollTop || document.body.scrollTop;
	if (c > 0) {
	  window.requestAnimationFrame(scrollToTop);
	  window.scrollTo(0, c - c / 8);
	}
  };
class EditAdminUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      directionOptions: [
        { value: 0, label: "Ltr", name: "direction" },
        { value: 1, label: "Rtl", name: "direction" }
      ],
      title: "",
      locale: "",
      direction: ""
    };
  }
  componentDidMount() {
    const id = this.props.match.params.id;
    Webservice.getOneLanguage(id).then(resp => {
      const data = resp.data.data;

      this.setState({
        title: data.title,
        locale: data.locale,
        direction: data.direction
      });
    });
  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeHandlerDropdown = e => {
    this.setState({ [e.name]: e.value });
  };

  changeHandlerCreatorForDate = name => value => {
    this.setState({ [name]: value });
  };

  componentDidUpdate() {
    scrollToTop();
  }
  editUser = e => {
    e.preventDefault();
    const id = this.props.match.params.id;

    const setDataForPost = {
      title: this.state.title,
      locale: this.state.locale,
      direction: this.state.direction
    };

    this.props.doUpdateLanguage(setDataForPost, id);
	this.props.history.push(`/admin/language/${id}`)
  };

  render() {
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title"> Edit User </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Edit</h5>
                </div>
                <Alert
                  color="success"
                  visible={this.props.message !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Success </span>
                    {this.props.message}
                  </p>
                </Alert>
                <form
                  onSubmit={this.editUser}
                  className="form form--horizontal"
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label">Title</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        // placeholder="Email"
                        name="title"
                        value={this.state.title}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Locale</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        // placeholder="Email"
                        name="locale"
                        value={this.state.locale}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Direction</span>
                    <div className="form__form-group-field">
                      <Select
                        name="direction"
                        value={this.state.directionOptions.filter(
                          item => item.value === this.state.direction
                        )}
                        onChange={this.changeHandlerDropdown}
                        options={this.state.directionOptions}
                        clearable={false}
                        className="react-select"
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Update
                    </Button>
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ language }) => {
  return {
    loading: language.loading,
    error: language.error,
    languages: language.languages,
    message: language.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doUpdateLanguage: (obj, id) => dispatch(editLanguage(obj, id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAdminUsers);
