import Webservice from "../../utils/networkApis";

export const ASSISTANCES = "ASSISTANCES";
export const ASSISTANCES_SUCCESS = "ASSISTANCES_SUCCESS";
export const ASSISTANCES_FAILED = "ASSISTANCES_FAILED";

export function getAssistances() {
  return (dispatch) => {
    dispatch({ type: ASSISTANCES });
    Webservice.getAssistances().then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ASSISTANCES_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: ASSISTANCES_FAILED, payload: response.error });
      }
    });
  };
}