import React, { Component } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, Fields } from 'redux-form';
import Webservice from '../../../utils/networkApis';
import renderSelectField from '../../../shared/components/form/Select';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderFileInputField from '../../../shared/components/form/FileInput';
import renderMultiSelectField from '../../../shared/components/form/MultiSelect';
import LeafletMap from '../../GoogleMap/components/BasicMap';
// import validate from "./validate";
import PropTypes from 'prop-types';
import renderTextEditor from '../../../shared/components/text-editor/reduxFormPort';
import renderTextEditorNew from '../../../shared/components/text-editor_new/renderTextEditorNew';

const renderField = ({ input, placeholder, type, meta: { touched, error } }) => (
	<div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
		<input {...input} placeholder={placeholder} type={type} />
		{touched && error && <span className="form__form-group-error">{error}</span>}
	</div>
);
const renderTextArea = ({ input, placeholder, type, meta: { touched, error } }) => (
	<div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
		<textarea {...input} placeholder={placeholder} type={type} />
		{touched && error && <span className="form__form-group-error">{error}</span>}
	</div>
);

class AddWhiteBoardForm extends Component {
	static propTypes = {
		t: PropTypes.func,
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		// error: PropTypes.string
	};

	constructor(props) {
		super(props);
		this.state = {
			languages: [],
		};
	}

	render() {
		const { handleSubmit, reset, t } = this.props;

		return (
			<form className="form form--horizontal" onSubmit={handleSubmit}>
				{/* <div className="form__form-group">
          <span className="form__form-group-label">Groups</span>
          <div className="form__form-group-field">
            <Field
              name="groups"
              component={renderMultiSelectField}
              options={this.props.groups}
            />
          </div>
        </div> */}
				<div className="form__form-group">
					<span className="form__form-group-label">Title</span>
					<div className="form__form-group-field">
						{this.props.languages.map((item) => {
							return (
								<div key={item.value} style={{ display: 'block', marginLeft: 20 }}>
									<Field
										name={`translations.${item.value}.title`}
										component={renderField}
										type="text"
										placeholder={item.label}
									/>
								</div>
							);
						})}
					</div>
				</div>
				<div className="form__form-group">
					<span className="form__form-group-label">Buttons</span>
					<div
						className="form__form-group-field"
						style={{
							flexWrap: 'wrap',
							justifyContent: 'space-between',
						}}
					>
						{this.props.languages.map((item) => {
							return (
								<Col key={item.value} style={{ padding: 0 }} sm={12} md={5}>
									<Field
										name={`translations.${item.value}.button`}
										component={renderField}
										type="text"
										placeholder={item.label}
									/>
								</Col>
							);
						})}
					</div>
				</div>
				<div className="form__form-group">
					<span className="form__form-group-label">Body</span>
					<div className="form__form-group-field wraped">
						{this.props.languages.map((item) => {
							return (
								<div key={item.value} style={{ display: 'block', marginLeft: 20, width: '100%' }}>
									<Field
										rtl={item.direction}
										name={`translations.${item.value}.body`}
										component={renderTextEditorNew}
										type="text"
										placeholder={item.label}
									/>
								</div>
							);
						})}
					</div>
				</div>

				<ButtonToolbar className="form__button-toolbar">
					<Button color="primary" type="submit">
						Submit
					</Button>
					<Button type="button" onClick={reset}>
						Cancel
					</Button>
				</ButtonToolbar>
			</form>
		);
	}
}

export default reduxForm({
	form: 'add_whiteboard_form',
	//   validate // a unique identifier for this form
})(AddWhiteBoardForm);
