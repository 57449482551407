import React, { PureComponent } from "react";
import { Col, Container, Row } from "reactstrap";
import moment from "moment";
import Table from "./components/DataTable";
import {
  getAppTranslations,
  deleteAppTranslation,
} from "../../redux/actions/appTranslationsActions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class AppTranslations extends PureComponent {
  componentDidMount() {
    this.props.doGetAppTranslations();
  }

  render() {
    console.log(this.props.appTranslations);
    const data = this.props.appTranslations.map((item) => {
      return {
        ...item,
        id: item.app_translation_id || item.id,
        key: item.key,
        value: item.value || "",
        created_at: moment(item.createdAt).format("M/DD/YYYY HH:mm"),
        updated_at: moment(item.updatedAt).format("M/DD/YYYY HH:mm"),
      };
    });

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">App Translations</h3>
          </Col>
          <Col md={2}>
            <Link
              to="/admin/add-app-translation"
              className="btn btn-primary products-list__btn-add"
            >
              New Translation
            </Link>
          </Col>
        </Row>
        <Row>
          <Table
            // entityName={entityName}
            data={data || []}
            error={this.props.error}
            props={this.props}
            success={(id) => {
              this.props.doDeleteAppTranslations(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ appTranslation }) => {
  return {
    loading: appTranslation.loading,
    error: appTranslation.error,
    appTranslations: appTranslation.appTranslations,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAppTranslations: () => dispatch(getAppTranslations()),
    doDeleteAppTranslations: (id) => dispatch(deleteAppTranslation(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppTranslations);
