import Webservice from "../../utils/networkApis";

export const APP_TRANSLATIONS = "APP_TRANSLATIONS";
export const APP_TRANSLATIONS_SUCCESS = "APP_TRANSLATIONS_SUCCESS";
export const APP_TRANSLATIONS_FAILED = "APP_TRANSLATIONS_FAILED";

export const ADD_APP_TRANSLATION = "ADD_APP_TRANSLATION";
export const ADD_APP_TRANSLATION_SUCCESS = "ADD_APP_TRANSLATION_SUCCESS";
export const ADD_APP_TRANSLATION_FAILED = "ADD_APP_TRANSLATION_FAILED";

export const EDIT_APP_TRANSLATION = "EDIT_APP_TRANSLATION";
export const EDIT_APP_TRANSLATION_SUCCESS = "EDIT_APP_TRANSLATION_SUCCESS";
export const EDIT_APP_TRANSLATION_FAILED = "EDIT_APP_TRANSLATION_FAILED";

export const DELETE_APP_TRANSLATION = "DELETE_APP_TRANSLATION";
export const DELETE_APP_TRANSLATION_SUCCESS = "DELETE_APP_TRANSLATION_SUCCESS";
export const DELETE_APP_TRANSLATION_FAILED = "DELETE_APP_TRANSLATION_FAILED";

export function getAppTranslations() {
  return dispatch => {
    dispatch({ type: APP_TRANSLATIONS });
    Webservice.getAppTranslations().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: APP_TRANSLATIONS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: APP_TRANSLATIONS_FAILED, payload: response.error });
      }
    });
  };
}

export function addAppTranslation(data, history) {
  return dispatch => {
    dispatch({ type: ADD_APP_TRANSLATION });
    Webservice.addAppTranslation(data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_APP_TRANSLATION_SUCCESS, payload: response.data });
        history.push(`/admin/app-translation/${response.data.appTranslation.id}`);
      } else {
        dispatch({ type: ADD_APP_TRANSLATION_FAILED, error: response.error });
      }
    });
  };
}

export function editAppTranslation(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_APP_TRANSLATION });
    Webservice.editAppTranslation(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_APP_TRANSLATION_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_APP_TRANSLATION_FAILED, error: response.error });
      }
    });
  };
}

export function deleteAppTranslation(id) {
  return dispatch => {
    dispatch({ type: DELETE_APP_TRANSLATION });
    Webservice.deleteAppTranslation(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_APP_TRANSLATION_SUCCESS,
          payload: { id }
        });
      } else {
        dispatch({
          type: DELETE_APP_TRANSLATION_FAILED,
          error: response.error
        });
      }
    });
  };
}
