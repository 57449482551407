import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const renderTextEditorNew = ({
  input: { onFocus, onBlur, ...input },
  placeholder,
  rtl,
  meta: { touched, error },
}) => {
  console.log(input);
  return (
    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
      {placeholder && <span>{placeholder}</span>}
      <ReactQuill
        className={rtl && 'rtl'}
        onFocus={() => onFocus()}
        onBlur={() => onBlur()}
        theme="snow"
        {...input}
      />
      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </div>
  );
};

export default renderTextEditorNew;
