import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import Table from "./components/DataTable";
import AddItineraryForm from "./components/addItineraryForm";
import { addItinerarie } from "../../redux/actions/itinerariesActions";
import { translateTo } from "../../redux/actions/googleTranslation";
import { connect } from "react-redux";
import Alert from "../../shared/components/Alert";
import { transformTransalationsForBackend } from "../../utils/dataTransformerForAPI";
import Loading from "../../shared/components/Loading";
import { changeTimezoneOfDateBy, getZones } from "../../utils/general";

class AddGuides extends React.Component {
  parseQuery(queryString) {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }

  onTranslate = (terms, source, target, callback) => {
    this.props.doTranslate(terms, source, target, () => {
      callback(this.props.translations);
    });
  };

  render() {
    const { groupId } = this.parseQuery(this.props.location.search);

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">New Program </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                {/* <div className="card__title">
                  <h5 className="bold-text">Adding Guides</h5>
                  {/* <h5 className="subhead">Labels are left from fields</h5> 
    </div> */}
                <Alert
                  color="danger"
                  visible={this.props.error !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Warning!</span>
                    {this.props.error}
                  </p>
                </Alert>
                {this.props.meta ? (
                  <AddItineraryForm
                    initialValues={{ group_id: groupId, zone: "Asia/Riyadh" }}
                    languages={this.props.meta ? this.props.meta.languages : []}
                    groups={this.props.meta ? this.props.meta.groups : []}
                    places={this.props.meta ? this.props.meta.places : []}
                    zones={getZones()}
                    onTranslate={this.onTranslate}
                    onSubmit={(values) => {
                      values.time = changeTimezoneOfDateBy(
                        values.time,
                        values.zone
                      );
                      this.props.doAddItinerary(
                        {
                          ...values,
                          translations: transformTransalationsForBackend(
                            values.translations || {}
                          ),
                        },
                        this.props.history
                      );
                    }}
                  />
                ) : (
                  <Loading loading />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ Itinerarie, metaData, googleTranslation }) => {
  return {
    loading: Itinerarie.loading || googleTranslation.loading,
    error: Itinerarie.error || googleTranslation.error,
    message: Itinerarie.message || googleTranslation.message,
    contents: Itinerarie.contents,
    meta: metaData.metaData,
    translations: googleTranslation.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddItinerary: (obj, props) => dispatch(addItinerarie(obj, props)),
    doTranslate: (terms, source, target, cb) =>
      dispatch(translateTo(terms, source, target, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGuides);
