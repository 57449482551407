import jsonToFormData from "json-form-data";
import { normalizeDate } from "./date";
import moment from "moment";
import tz from "moment-timezone";

const axios = require("axios");
const API_URL = "/api/v1";
// const API_URL = "http://app.mishkaat.no:2000/api/v1";
// const API_URL = "http://185.125.169.87:2000/api/v1";
// const API_URL = "http://localhost:5001/api/v1";
// const API_URL = "http://localhost:2000/api/v1";

const GOOGLE_TRANSLATION_API_KEY = "AIzaSyC-IKUNKoojMhkXPrL41UM3Co55PwdNEtE";
const GOOGLE_TRANSLATION_API_URL =
  "https://translation.googleapis.com/language/translate/v2";

var googleClient = axios.create({
  baseURL: GOOGLE_TRANSLATION_API_URL,
});

var client = axios.create({
  baseURL: API_URL,
});

const getHeaderConfig = () => ({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

class WebService {
  isLoggedIn() {
    return this.user != null;
  }

  metaData() {
    return client.get("/metaData/getDropdowns", getHeaderConfig());
  }

  logout() {
    delete client.defaults.headers["Authorization"];
    localStorage.clear("token");
  }

  login(payload) {
    return client.post("/admin-users/login", payload);
  }

  setUser(token) {
    client.defaults.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }

  getAdminUsers() {
    return client.get("/admin-users/getAdminUsers", getHeaderConfig());
  }

  addAdminUsers(data) {
    return client.post("/admin-users/create", data, getHeaderConfig());
  }

  editAdminUser(id, data) {
    return client.post(`/admin-users/update/${id}`, data, getHeaderConfig());
  }

  getOneAdminUser(id) {
    return client.get(`/admin-users/getOneAdminUser/${id}`, getHeaderConfig());
  }

  deleteAdminUser(id) {
    return client.get(`/admin-users/delete/${id}`, getHeaderConfig());
  }

  getUsers() {
    return client.get("/users/getUsers", getHeaderConfig());
  }

  importUserAvatars() {
    return client.get("/users/import_avatars", getHeaderConfig());
  }

  vppFilenameChanger() {
    return client.get("/users/vpp_filename_changer", getHeaderConfig());
  }

  addUsers(data) {
    return client.post("/users/create", data, getHeaderConfig());
  }

  editUser(id, data) {
    return client.post(`/users/update/${id}`, data, getHeaderConfig());
  }

  getOneUser(id) {
    return client.get(`/users/getOneUser/${id}`, getHeaderConfig());
  }

  deleteUser(id) {
    return client.get(`/users/delete/${id}`, getHeaderConfig());
  }

  usersBulkDelete(ids) {
    return client.post(
      "/users/bulkDelete",
      { user_ids: ids },
      getHeaderConfig()
    );
  }

  getLanguages() {
    return client.get("/languages/getLanguages", getHeaderConfig());
  }

  addLanguages(data) {
    return client.post("/languages/create", data, getHeaderConfig());
  }

  editLanguage(id, data) {
    return client.post(`/languages/update/${id}`, data, getHeaderConfig());
  }

  getOneLanguage(id) {
    return client.get(`/languages/getOneLanguage/${id}`, getHeaderConfig());
  }

  deleteLanguage(id) {
    return client.get(`/languages/delete/${id}`, getHeaderConfig());
  }

  getContacts() {
    return client.get(`/contacts/getContacts`, getHeaderConfig());
  }

  addContact(data) {
    let body = jsonToFormData(data);
    return client.post(`/contacts/create`, body, getHeaderConfig());
  }

  showContact(id) {
    return client.get(`/contacts/showContact/${id}`, getHeaderConfig());
  }

  editContact(id, data) {
    let body = jsonToFormData(data);
    return client.post(`/contacts/update/${id}`, body, getHeaderConfig());
  }

  contactsBulkCopy(ids, to) {
    if (!to) return;
    return client.post(`/contacts/copy`, { ids, to }, getHeaderConfig());
  }

  contactsBulkDelete(ids) {
    return client.post(
      "/contacts/bulkDelete",
      { contactsIds: ids },
      getHeaderConfig()
    );
  }

  deleteContact(id) {
    return client.get(`/contacts/delete/${id}`, getHeaderConfig());
  }

  getOneContact(id) {
    return client.get(`/contacts/getOneContact/${id}`, getHeaderConfig());
  }

  getContents(type) {
    return client.get(
      "/contents/getContents" + (type ? `?type=${type}` : ""),
      getHeaderConfig()
    );
  }

  addContent(data) {
    const { add_images, ...rest } = data;
    let body = jsonToFormData(rest);
    (add_images || []).forEach((value) => body.append("add_images[]", value));
    return client.post("/contents/create", body, getHeaderConfig());
  }

  editContent(id, data) {
    const { add_images, ...rest } = data;
    let body = jsonToFormData(rest);
    (add_images || []).forEach((value) => body.append("add_images[]", value));
    return client.post(`/contents/update/${id}`, body, getHeaderConfig());
  }

  getOneContent(id) {
    return client.get(`/contents/getOneContent/${id}`, getHeaderConfig());
  }

  deleteContent(id) {
    return client.get(`/contents/delete/${id}`, getHeaderConfig());
  }

  addGuide(data) {
    const { add_images, ...rest } = data;
    let body = jsonToFormData(rest);
    (add_images || []).forEach((value) => body.append("add_images[]", value));
    return client.post("/guides/create", body, getHeaderConfig());
  }

  getGuides() {
    return client.get("/guides/getGuides", getHeaderConfig());
  }
  getGuidesTree() {
    return client.get("/guides/getTree", getHeaderConfig());
  }
  editGuide(id, data) {
    const { add_images, ...rest } = data;

    let body = jsonToFormData(rest);
    (add_images || []).forEach((value) => body.append("add_images[]", value));
    return client.post(`/guides/update/${id}`, body, getHeaderConfig());
  }

  getOneGuide(id, show) {
    return client.get(
      `/guides/getOneGuide/${id}${show ? "?view=show" : ""}`,
      getHeaderConfig()
    );
  }

  deleteGuide(id) {
    return client.get(`/guides/delete/${id}`, getHeaderConfig());
  }

  getLostReports() {
    return client.get("/lostReports/getLostReports", getHeaderConfig());
  }

  editLostReport(id, data) {
    return client.post(`/lostReports/update/${id}`, data, getHeaderConfig());
  }

  getOneLostReport(id) {
    return client.get(`/lostReports/getOneLostReport/${id}`, getHeaderConfig());
  }

  lostReportBulkDelete(ids) {
    return client.post(
      "/lostReports/bulkDelete",
      { lostReportIds: ids },
      getHeaderConfig()
    );
  }

  getGroups() {
    return client.get("/groups/getGroups", getHeaderConfig());
  }

  deleteGroup(id) {
    return client.get(`/groups/delete/${id}`, getHeaderConfig());
  }

  groupBulkDelete(ids) {
    return client.post(
      "/groups/bulkDelete",
      { group_ids: ids },
      getHeaderConfig()
    );
  }

  getOneGroup(id) {
    return client.get(`/groups/getOneGroup/${id}`, getHeaderConfig());
  }

  getItineraries(id) {
    return client.get(
      "/Itineraries/getItineraries" + (id ? `?group_id=${id}` : ""),
      getHeaderConfig()
    );
  }

  copyItinerary(from, to, shift_in_days = 0) {
    if (!from || !to) return;
    return client.post(
      `/Itineraries/copy`,
      { from, to, shift_in_days },
      getHeaderConfig()
    );
  }
  itinerariesBulkCopy(ids, to, shift_in_days = 0) {
    if (!to) return;
    return client.post(
      `/Itineraries/copy`,
      { ids, to, shift_in_days },
      getHeaderConfig()
    );
  }

  itinerariesBulkDelete(ids) {
    return client.post(
      "/Itineraries/bulkDelete",
      { itinerariesIds: ids },
      getHeaderConfig()
    );
  }

  getOneitinerary(id) {
    return client.get(`/Itineraries/getOneItinerary/${id}`, getHeaderConfig());
  }

  showitinerary(id) {
    return client.get(`/Itineraries/showItinerary/${id}`, getHeaderConfig());
  }

  addItinerarie(data) {
    // alert(moment(data.time).format('LLL'));
    // alert(
    //   moment(normalizeDate.doWithAllDatesInObject(data, 'save').time).format(
    //     'LLL'
    //   )
    // );
    const modified = {
      ...data,
      time: moment(data.time).tz(data.zone).toDate(),
    };

    console.log("addItinerarie", modified);
    return client.post(`/Itineraries/create`, modified, getHeaderConfig());
  }

  deleteItinerarie(id) {
    return client.get(`/Itineraries/delete/${id}`, getHeaderConfig());
  }

  editItinerarie(id, data) {
    const modified = {
      ...data,
      time: moment(data.time).toDate(),
    };
    return client.post(
      `/Itineraries/update/${id}`,
      modified,
      getHeaderConfig()
    );
  }

  getAppTranslations() {
    return client.get(`/appTranslations/getAppTranslations`, getHeaderConfig());
  }

  getOneAppTranslation(id) {
    return client.get(
      `/appTranslations/getOneAppTranslation/${id}`,
      getHeaderConfig()
    );
  }

  showAppTranslation(id) {
    return client.get(
      `/appTranslations/showAppTranslation/${id}`,
      getHeaderConfig()
    );
  }

  addAppTranslation(data) {
    return client.post(`/appTranslations/create`, data, getHeaderConfig());
  }

  deleteAppTranslation(id) {
    return client.get(`/appTranslations/delete/${id}`, getHeaderConfig());
  }

  editAppTranslation(id, data) {
    return client.post(
      `/appTranslations/update/${id}`,
      data,
      getHeaderConfig()
    );
  }

  getAttendances(groupId, itineraryId, mode) {
    let query = `?mode=${mode}`;

    if (groupId) query = `${query}&group_id=${groupId}`;
    if (itineraryId) query = `${query}&itinerary_id=${itineraryId}`;

    return client.get(`/attendances/getAttendances${query}`, getHeaderConfig());
  }

  deleteAttendance(data) {
    return client.post(`/attendances/bulkDelete`, data, getHeaderConfig());
  }

  getImportedUsers() {
    return client.get(`/importedUsers/getImportedUsers`, getHeaderConfig());
  }

  getImportedGroups() {
    return client.get(`/importedGroups/getImportedGroups`, getHeaderConfig());
  }

  getImportedHotels() {
    return client.get(`/importedHotels/getImportedHotels`, getHeaderConfig());
  }

  addAnnouncement(data) {
    const { add_attachment, ...rest } = data;
    let body = jsonToFormData(rest);
    (add_attachment || []).forEach((value) =>
      body.append("add_attachment[]", value)
    );
    return client.post(`/announcements/create`, body, getHeaderConfig());
  }

  getAnnouncements() {
    return client.get(`/announcements/getAnnouncements`, getHeaderConfig());
  }

  editAnnouncement(id, data) {
    // if (
    //   data.attachment &&
    //   !(data.attachment.constructor.toString().indexOf("File") != -1)
    // )
    //   delete data.attachment;
    const { add_attachment, ...rest } = data;
    let body = jsonToFormData(rest);
    (add_attachment || []).forEach((value) =>
      body.append("add_attachment[]", value)
    );
    return client.post(`/announcements/update/${id}`, body, getHeaderConfig());
  }

  deleteAnnouncement(id) {
    return client.get(`/announcements/delete/${id}`, getHeaderConfig());
  }

  announcementBulkDelete(ids) {
    return client.post(
      "/announcements/bulkDelete",
      { announcementIds: ids },
      getHeaderConfig()
    );
  }

  getOneAnnouncement(id) {
    return client.get(
      `/announcements/getOneAnnouncement/${id}`,
      getHeaderConfig()
    );
  }

  addWhiteboard(data) {
    const { add_attachment, ...rest } = data;
    let body = jsonToFormData(rest);
    (add_attachment || []).forEach((value) =>
      body.append("add_attachment[]", value)
    );
    return client.post(`/whiteboards/create`, body, getHeaderConfig());
  }

  getWhiteboards() {
    return client.get(`/whiteboards/getwhiteboards`, getHeaderConfig());
  }

  editWhiteboard(id, data) {
    // if (
    //   data.attachment &&
    //   !(data.attachment.constructor.toString().indexOf("File") != -1)
    // )
    //   delete data.attachment;
    const { add_attachment, ...rest } = data;
    let body = jsonToFormData(rest);
    (add_attachment || []).forEach((value) =>
      body.append("add_attachment[]", value)
    );
    return client.post(`/whiteboards/update/${id}`, body, getHeaderConfig());
  }

  whiteboardBulkDelete(ids) {
    return client.post("/whiteboards/bulkDelete", { ids }, getHeaderConfig());
  }

  deleteWhiteboard(id) {
    return client.get(`/whiteboards/delete/${id}`, getHeaderConfig());
  }

  getOnewhiteboard(id) {
    return client.get(`/whiteboards/getOnewhiteboard/${id}`, getHeaderConfig());
  }

  getHelpme() {
    return client.get(`/helpme/getHelpme`, getHeaderConfig());
  }

  editHelpme(id, data) {
    return client.post(`/helpme/update/${id}`, data);
  }

  getOneHelpme(id) {
    return client.get(`/helpme/getOneHelpme/${id}`, getHeaderConfig());
  }

  getAppSettings() {
    return client.get(`/appSettings/getAppSettings`, getHeaderConfig());
  }

  addAppSetting(data) {
    return client.post(`/appSettings/create`, data, getHeaderConfig());
  }

  editAppSetting(id, data) {
    return client.post(`/appSettings/update/${id}`, data, getHeaderConfig());
  }

  deleteAppSetting(id) {
    return client.get(`/appSettings/delete/${id}`, getHeaderConfig());
  }

  appSettingsBulkDelete(ids) {
    return client.post(`/appSettings/bulkDelete`, { ids }, getHeaderConfig());
  }

  getOneAppSetting(id) {
    return client.get(`/appSettings/getOneAppSetting/${id}`, getHeaderConfig());
  }
  run_replications() {
    return client.put(`/run_replications`, getHeaderConfig());
  }

  getAssistances() {
    return client.get("/assistances/all", getHeaderConfig());
  }

  assistanceBulkDelete(ids) {
    return client.post(
      "/assistances/bulkDelete",
      { assistanceIds: ids },
      getHeaderConfig()
    );
  }

  translateTo(terms, source, target) {
    return googleClient.post(`?key=${GOOGLE_TRANSLATION_API_KEY}`, {
      q: terms,
      source,
      target,
    });
  }
}

const webservice = new WebService();
export default webservice;
