import React, { Component } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import TimetableIcon from "mdi-react/TimetableIcon";
import EyeIcon from "mdi-react/EyeIcon";

import renderSelectField from "../../../shared/components/form/Select";
import renderDateTimePickerField from "../../../shared/components/form/DateTimePicker";
import validate from "./validate";
import PropTypes from "prop-types";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

class AddFormInput extends Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    // error: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { handleSubmit, reset, t } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">First Name</span>
          <div className="form__form-group-field">
            <Field
              name="first_name"
              component={renderField}
              type="text"
              placeholder="First Name"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Last Name</span>
          <div className="form__form-group-field">
            <Field
              name="last_name"
              component={renderField}
              type="text"
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <Field
              name="email"
              component={renderField}
              type="text"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <Field
              name="username"
              component={renderField}
              type="text"
              placeholder="Username"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <Field
              name="encrypted_password"
              component={renderField}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
            />
            <button
              className={`form__form-group-button${
                showPassword ? " active" : ""
              }`}
              onClick={(e) => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Sect</span>
          <div className="form__form-group-field">
            <Field
              name="sect"
              component={renderSelectField}
              options={this.props.sect}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Gender</span>
          <div className="form__form-group-field">
            <Field
              name="gender"
              component={renderSelectField}
              options={this.props.gender}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Place Of Birth</span>
          <div className="form__form-group-field">
            <Field
              name="place_of_birth"
              component={renderField}
              type="text"
              placeholder="Place Of Birth"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Language</span>
          <div className="form__form-group-field">
            <Field
              name="language"
              component={renderSelectField}
              options={this.props.language}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Born On</span>
          <div className="form__form-group-field">
            <Field name="born_on" component={renderDateTimePickerField} />
            <div className="form__form-group-icon">
              <TimetableIcon />
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Passport</span>
          <div className="form__form-group-field">
            <Field
              name="passport"
              component={renderField}
              type="text"
              placeholder="Passport"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Phone</span>
          <div className="form__form-group-field">
            <Field
              name="phone"
              component={renderField}
              type="text"
              placeholder="Phone"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Ksa phone</span>
          <div className="form__form-group-field">
            <Field
              name="ksa_phone"
              component={renderField}
              type="text"
              placeholder="KSA Phone"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Nationality</span>
          <div className="form__form-group-field">
            <Field
              name="nationality"
              component={renderField}
              type="text"
              placeholder="Nationality"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Zipcode</span>
          <div className="form__form-group-field">
            <Field
              name="zipcode"
              component={renderField}
              type="text"
              placeholder="Passport"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Visa status</span>
          <div className="form__form-group-field">
            <Field
              name="select"
              component={renderSelectField}
              options={[
                { value: "New Visa", label: "New Visa" },
                {
                  value: "Waiting for documents",
                  label: "Waiting for documents",
                },
                { value: "Ready To Apply", label: "Ready To Apply" },
                { value: "To Embassy", label: "To embassy" },
                { value: "Visa issued", label: "Visa issued" },
                { value: "Passport Returned", label: "Passport Returned" },
              ]}
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">
            Submit
          </Button>
          <Button type="button" onClick={reset}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: "add_user_form",
  validate, // a unique identifier for this form
})(AddFormInput);
