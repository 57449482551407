import moment from "moment";
import tz from "moment-timezone";
import { normalizeDate } from "../../utils/date";
import Webservice from "../../utils/networkApis";

export const ITINERARIES = "ITINERARIES";
export const ITINERARIES_SUCCESS = "ITINERARIES_SUCCESS";
export const ITINERARIES_FAILED = "ITINERARIES_FAILED";

export const ADD_ITINERARIE = "ADD_ITINERARIE";
export const ADD_ITINERARIE_SUCCESS = "ADD_ITINERARIE_SUCCESS";
export const ADD_ITINERARIE_FAILED = "ADD_ITINERARIE_FAILED";

export const EDIT_ITINERARIE = "EDIT_ITINERARIE";
export const EDIT_ITINERARIE_SUCCESS = "EDIT_ITINERARIE_SUCCESS";
export const EDIT_ITINERARIE_FAILED = "EDIT_ITINERARIE_FAILED";

export const DELETE_ITINERARIE = "DELETE_ITINERARIE";
export const DELETE_ITINERARIE_SUCCESS = "DELETE_ITINERARIE_SUCCESS";
export const DELETE_ITINERARIE_FAILED = "DELETE_ITINERARIE_FAILED";

export function getItineraries(id) {
  return (dispatch) => {
    dispatch({ type: ITINERARIES });
    Webservice.getItineraries(id).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: ITINERARIES_SUCCESS,
          payload: response.data || [],
        });
      } else {
        dispatch({ type: ITINERARIES_FAILED, payload: response.error });
      }
    });
  };
}

export function addItinerarie(data, history) {
  // console.log('addItinerarie ' + JSON.stringify(data));
  return (dispatch) => {
    dispatch({ type: ADD_ITINERARIE });
    Webservice.addItinerarie(data).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: ADD_ITINERARIE_SUCCESS,
          payload: {
            ...response.data,
            time: moment(response.data.time)
              .tz(response.data.zone)
              .format("YYYY-MM-DDThh:mm:ss"),
          },
        });
        history.goBack();
      } else {
        dispatch({ type: ADD_ITINERARIE_FAILED, error: response.error });
      }
    });
  };
}

export function editItinerarie(data, id, cb) {
  return (dispatch) => {
    dispatch({ type: EDIT_ITINERARIE });
    Webservice.editItinerarie(id, data).then((resp) => {
      cb && cb();
      const response = resp.data;
      if (response.success) {
        // dispatch({
        //   type: EDIT_ITINERARIE_SUCCESS,
        //   payload: {
        //     data: { ...response.data, id },
        //     message: response.message,
        //   },
        // });
      } else {
        dispatch({ type: EDIT_ITINERARIE_FAILED, error: response.error });
      }
    });
  };
}

export function deleteItinerarie(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_ITINERARIE });
    Webservice.deleteItinerarie(id).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_ITINERARIE_SUCCESS,
          payload: { id },
        });
      } else {
        dispatch({ type: DELETE_ITINERARIE_FAILED, error: response.error });
      }
    });
  };
}
