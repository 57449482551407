import Webservice from "../../utils/networkApis";

export const GUIDES = "GUIDES";
export const GUIDES_SUCCESS = "GUIDES_SUCCESS";
export const GUIDES_FAILED = "GUIDES_FAILED";

export const ADD_GUIDE = "ADD_GUIDE";
export const ADD_GUIDE_SUCCESS = "ADD_GUIDE_SUCCESS";
export const ADD_GUIDE_FAILED = "ADD_GUIDE_FAILED";

export const EDIT_GUIDE = "EDIT_GUIDE";
export const EDIT_GUIDE_SUCCESS = "EDIT_GUIDE_SUCCESS";
export const EDIT_GUIDE_FAILED = "EDIT_GUIDE_FAILED";

export const DELETE_GUIDE = "DELETE_GUIDE";
export const DELETE_GUIDE_SUCCESS = "DELETE_GUIDE_SUCCESS";
export const DELETE_GUIDE_FAILED = "DELETE_GUIDE_FAILED";

export function getGuides() {
  return dispatch => {
    dispatch({ type: GUIDES });
    Webservice.getGuides().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: GUIDES_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: GUIDES_FAILED, payload: response.error });
      }
    });
  };
}
export function getGuidesTree() {
  return dispatch => {
    dispatch({ type: GUIDES });
    Webservice.getGuidesTree().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: GUIDES_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: GUIDES_FAILED, payload: response.error });
      }
    });
  };
}

export function addGuide(data, history) {
  return dispatch => {
    dispatch({ type: ADD_GUIDE });
    Webservice.addGuide(data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_GUIDE_SUCCESS, payload: response.data });
        history.push("/admin/guides");
      } else {
        dispatch({ type: ADD_GUIDE_FAILED, error: response.error });
      }
    });
  };
}

export function editGuide(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_GUIDE });
    Webservice.editGuide(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_GUIDE_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_GUIDE_FAILED, error: response.error });
      }
    });
  };
}

export function deleteGuide(id) {
  return dispatch => {
    dispatch({ type: DELETE_GUIDE });
    Webservice.deleteGuide(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_GUIDE_SUCCESS,
          payload: { id }
        });
        dispatch(getGuidesTree())
      } else {
        dispatch({ type: DELETE_GUIDE_FAILED, error: response.error });
      }
    });
  };
}
