import React, { Component } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "../../../shared/components/form/Select";
import TimetableIcon from "mdi-react/TimetableIcon";
import renderDateTimePickerField from "../../../shared/components/form/DateTimePicker";
import validate from "./validate";
import PropTypes from "prop-types";

const renderField = ({ input, placeholder, type, meta: { touched, error } }) =>
	<div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
		<input {...input} placeholder={placeholder} type={type} />
		{touched &&
			error &&
			<span className="form__form-group-error">
				{error}
			</span>}
	</div>;

class AddFormInput extends Component {
	static propTypes = {
		t: PropTypes.func,
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired
		// error: PropTypes.string
	};

	constructor(props) {
		super(props);
		this.state = {
			showPassword: false
		};
	}

	showPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({ showPassword: !prevState.showPassword }));
	};

	render() {
		const { handleSubmit, reset, t } = this.props;
		const { showPassword } = this.state;

		return (
			<form className="form form--horizontal" onSubmit={handleSubmit}>
				<div className="form__form-group">
					<span className="form__form-group-label">Key</span>
					<div className="form__form-group-field">
						<Field
							name="key"
							component={renderField}
							type="text"
							placeholder="Key"
						/>
					</div>
				</div>
				<div className="form__form-group">
					<span className="form__form-group-label">Value</span>
					<div className="form__form-group-field" style={{
						flexWrap: "wrap",
						justifyContent:"space-between"
					}}>
						{this.props.language.map(item => {
							return (
								<Col
									key={item.value}
									style={{ padding:0 }}
									sm={12}
									md={5}
								>
									<Field
										name={`translations.${item.value}.value`}
										component={renderField}
										type="text"
										placeholder={item.label}
									/>
								</Col>
							);
						})}
						{/* <FieldArray name="title" component={RenderMembers} data={languages}  /> */}
					</div>
				</div>

				<ButtonToolbar className="form__button-toolbar">
					<Button color="primary" type="submit">
						Submit
          </Button>
					<Button type="button" onClick={reset}>
						Cancel
          </Button>
				</ButtonToolbar>
			</form>
		);
	}
}

export default reduxForm({
	form: "add_user_form",
	validate // a unique identifier for this form
})(AddFormInput);
