/* eslint-disable consistent-return */
import React, { PureComponent } from 'react';
import ReactDataGrid from 'react-data-grid';
import PropTypes from 'prop-types';

export default class DataCustomTable extends PureComponent {
  static propTypes = {
    heads: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        editable: PropTypes.bool,
        sortable: PropTypes.bool,
      })
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onSorting: PropTypes.func.isRequired,
    getCellActions: PropTypes.func,
    extraProps: PropTypes.object,
    pagination: PropTypes.objectOf({
      itemsToShow: PropTypes.number.isRequired,
      pageOfItems: PropTypes.number.isRequired,
    }),
  };

  getRows = () => {
    const { rows, pagination } = this.props;
    return pagination ? this.getPaginatedRows(rows, pagination) : rows;
  };
  getPaginatedRows = (rows, { pageOfItems, itemsToShow }) => {
    const rowsFrom = itemsToShow * (pageOfItems - 1);
    const rowsTo = rowsFrom + itemsToShow;
    return rows.slice(rowsFrom, rowsTo);
  };
  render() {
    const { heads, onSorting, getCellActions, extraProps } = this.props;
    let rows = this.getRows();
    console.log(rows.length);
    return (
      <div className="table">
        <ReactDataGrid
          columns={heads}
          rowGetter={(i) => rows[i]}
          rowsCount={rows.length}
          rowHeight={35}
          onGridSort={onSorting}
          getCellActions={getCellActions}
          {...(extraProps || {})}
        />
      </div>
    );
  }
}
