import {
  TRANSLATING,
  TRANSLATING_FAILED,
  TRANSLATING_SUCCESS,
} from "../actions/googleTranslation";

const initialState = {
  loading: false,
  translations: [],
  message: null,
  data: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TRANSLATING:
      return { ...state, loading: true, error: null };
    case TRANSLATING_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        translations: action.payload,
      };
    case TRANSLATING_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
