import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Table from "./components/DataTable";
import { connect } from "react-redux";
import moment from "moment";

import { getAssistances } from "../../redux/actions/assistanceActions";

class Assistances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: Assistances._parseData(props.assistance || [], props.meta),
      assistance: props.assistance,
      meta: props.meta,
    };
  }
  componentDidMount() {
    this.props.doGetAssistance();
  }
  static _parseData(assistance, meta) {
    console.log({ assistance, meta: JSON.stringify(meta, null, 0) });
    return assistance.map((item) => {
      return {
        id: item.id,
        user: item.user
          ? `${item.user.first_name} ${item.user.last_name}`
          : "N/A",
        group:
          (item.group &&
            ((meta && meta.groupsKeys && meta.groupsKeys[item.groupId]) ||
              item.groupId)) ||
          "N/A",
        userId: item.userId,
        roomNumber: item.room_number,
        status: `${item.status.charAt(0).toUpperCase()}${item.status.slice(1)}`,
        type: `${item.type.charAt(0).toUpperCase()}${item.type.slice(1)}`,
        createdAt: moment(item.createdAt).format("M/DD/YYYY HH:mm"),
      };
    });
  }
  static getDerivedStateFromProps(props, state) {
    if (props.assistance !== state.assistance || state.meta !== props.meta) {
      return {
        data: Assistances._parseData(props.assistance || [], props.meta),
        assistance: props.assistance,
        meta: props.meta,
      };
    }
    return null;
  }

  render() {
    console.log(this.state.data);

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title"> Assistance List </h3>
          </Col>
        </Row>
        <Row>
          <Table
            refreshData={() => this.props.doGetAssistance()}
            data={
              (this.state.data && this.state.data.filter((el) => el.userId)) ||
              []
            }
            meta={this.state.meta}
            success={(id) => {
              this.props.doDeleteUser(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ assistance, metaData }) => {
  return {
    loading: assistance.loading,
    error: assistance.error,
    assistance: assistance.assistances,
    meta: metaData.metaData,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAssistance: () => dispatch(getAssistances()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assistances);
