import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Table from "./components/DataTable";
import {
  getLanguages,
  deleteLanguage
} from "../../redux/actions/languagesActions";
import { connect } from "react-redux";

class LanguageTable extends React.Component {
  componentDidMount() {
    this.props.doGetLanguages();
  }

  render() {
    const renderData = this.props.languages.map(item => {
      return {
        id: item.id,
        title: item.title,
        locale: item.locale,
        direction: item.direction === 0 ? "Ltr" : "Rtl",
        is_default: item.is_default === true ? "true" : "false"
      };
    });

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title"> Languages </h3>
            {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
                information
          </h3> */}
          </Col>
          <Col  md={2}>
            <Link
              to="/admin/add-languages"
              className="btn btn-primary products-list__btn-add"
            >
              New Language
            </Link>
          </Col>
        </Row>
        <Row>
          <Table
            data={renderData || []}
            success={id => {
              this.props.doDeleteLanguage(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ language }) => {
  return {
    loading: language.loading,
    error: language.error,
    languages: language.languages
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doGetLanguages: () => dispatch(getLanguages()),
    doDeleteLanguage: id => dispatch(deleteLanguage(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageTable);
