import Webservice from '../../utils/networkApis';

export const ADMIN_USERS = 'ADMIN_USERS';
export const ADMIN_USERS_SUCCESS = 'ADMIN_USERS_SUCCESS';
export const ADMIN_USERS_FAILED = 'ADMIN_USERS_FAILED';

export const ADD_ADMIN_USER = 'ADD_ADMIN_USERS';
export const ADD_ADMIN_USER_SUCCESS = 'ADD_ADMIN_USERS_SUCCESS';
export const ADD_ADMIN_USER_FAILED = 'ADD_ADMIN_USERS_FAILED';

export const EDIT_ADMIN_USER = 'EDIT_ADMIN_USERS';
export const EDIT_ADMIN_USER_SUCCESS = 'EDIT_ADMIN_USERS_SUCCESS';
export const EDIT_ADMIN_USER_FAILED = 'EDIT_ADMIN_USERS_FAILED';

export const DELETE_ADMIN_USER = 'DELETE_ADMIN_USERS';
export const DELETE_ADMIN_USER_SUCCESS = 'DELETE_ADMIN_USERS_SUCCESS';
export const DELETE_ADMIN_USER_FAILED = 'DELETE_ADMIN_USERS_FAILED';

export function getAdminUsers() {
  return (dispatch) => {
    dispatch({ type: ADMIN_USERS });
    Webservice.getAdminUsers().then((resp) => {
      const response = resp.data;
      console.log('response', response);
      if (response.success) {
        dispatch({ type: ADMIN_USERS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: ADMIN_USERS_FAILED, payload: response.error });
      }
    });
  };
}

export function addAdminUser(data, history) {
  return (dispatch) => {
    dispatch({ type: ADD_ADMIN_USER });
    Webservice.addAdminUsers(data).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_ADMIN_USER_SUCCESS, payload: response.data });
        history.push(`/admin/admin-user/${response.data.id}`);
      } else {
        dispatch({ type: ADD_ADMIN_USER_FAILED, error: response.error });
      }
    });
  };
}

export function editAdminUser(data, id) {
  return (dispatch) => {
    dispatch({ type: EDIT_ADMIN_USER });
    Webservice.editAdminUser(id, data).then((resp) => {
      const response = resp.data;
      if (response.success) {
        console.log('resp', resp);
        dispatch({
          type: EDIT_ADMIN_USER_SUCCESS,
          payload: {
            data: { ...response.data, id },
            message: response.message,
          },
        });
      } else {
        dispatch({ type: EDIT_ADMIN_USER_FAILED, error: response.error });
      }
    });
  };
}

export function deleteAdminUser(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_ADMIN_USER });
    debugger;
    Webservice.deleteAdminUser(id).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_ADMIN_USER_SUCCESS,
          payload: { id },
        });
      } else {
        dispatch({ type: DELETE_ADMIN_USER_FAILED, error: response.error });
      }
    });
  };
}
