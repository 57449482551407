import React, { Component } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm, Fields } from "redux-form";
import Webservice from "../../../utils/networkApis";
import renderSelectField from "../../../shared/components/form/Select";
import TimetableIcon from "mdi-react/TimetableIcon";
import moment from "moment";
import DatePicker from "react-datepicker";
import LeafletMap from "../../GoogleMap/components/BasicMap";
// import validate from "./validate";
import PropTypes from "prop-types";

const renderField = ({ input, placeholder, type, meta: { touched, error } }) =>
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched &&
      error &&
      <span className="form__form-group-error">
        {error}
      </span>}
  </div>;
const renderTextArea = ({
  input,
  placeholder,
  type,
  meta: { touched, error }
}) =>
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <textarea {...input} placeholder={placeholder} type={type} />
    {touched &&
      error &&
      <span className="form__form-group-error">
        {error}
      </span>}
  </div>;

class EditLostReport extends Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
    // error: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      languages: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    return { favor: props.favcol };
  }

  changeHandlerCreatorForDate = name => value => {
    this.setState({ [name]: value });
  };

  showPassword = e => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { handleSubmit, reset, t, initialValues } = this.props;

    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">User</span>
          <div className="form__form-group-field">
            <Field
              name="user_id"
              component={renderSelectField}
              options={this.props.users}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Resolved By</span>
          <div className="form__form-group-field">
            <Field
              name="resolved_by_id"
              component={renderSelectField}
              options={this.props.users}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Resolved at</span>
          <div className="form__form-group-field">
            {/* {this.state.born_on && */}
            <DatePicker
              name="resolved_at"
              className="form__form-group-datepicker"
              // selected={moment(initialValues.resolved_at).toDate()}
              // onChange={this.changeHandlerCreatorForDate("born_on")}
              dateFormat="MMMM d, yyyy"
              dropDownMode="select"
            />
            <div className="form__form-group-icon">
              <TimetableIcon />
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Lat</span>
          <div className="form__form-group-field">
            <Field
              name="lat"
              component={renderField}
              type="text"
              placeholder="Lat"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Long</span>
          <div className="form__form-group-field">
            <Field
              name="long"
              component={renderField}
              type="text"
              placeholder="Long"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Location</span>
          <Fields
            names={["lat", "long"]}
            component={({ lat: { input: lat }, long: { input: long } }) =>
              <LeafletMap
                value={
                  (lat.value &&
                  long.value && {
                    lat: lat.value,
                    lng: long.value
                  }) ||
                  null
                }
                onChange={({ lat: lt, lng }) => {
                  lat.onChange(lt);
                  long.onChange(lng);
                }}
              />}
          />
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">
            Submit
          </Button>
          <Button type="button" onClick={reset}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: "edit_lost_report"
  // validate // a unique identifier for this form
})(EditLostReport);
