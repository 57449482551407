import React, { Component } from 'react';
import webservice from '../../../utils/networkApis';
import { Button } from 'reactstrap';
export class RunReplications extends Component {
  state = {
    loading: false,
    success: null,
  };
  run_replications() {
    this.setState({ loading: true });
    webservice.run_replications().then(({ data }) => {
      console.log(data);
      this.setState({ loading: false, success: data.status });
    });
  }
  render() {
    return (
      <Button
        onClick={() => this.run_replications()}
        disabled={this.state.loading}
        color={
          this.state.success == false
            ? 'denger'
            : this.state.success == true
            ? 'primary'
            : undefined
        }
      >
        {this.state.loading
          ? 'Running'
          : this.state.success == false
          ? 'Retry'
          : this.state.success == true
          ? 'Re-Run'
          : 'Run'}{' '}
        Replications
      </Button>
    );
  }
}
