/* eslint-disable */
const validate = (values, props) => {
  const errors = {};
  

  if (!values.title) {
    errors.title = "title field shouldn't be empty";
  }
  if (!values.locale) {
    errors.locale = "Locale field shouldn't be empty";
  }

  return errors;
};

export default validate;
