import React, { Component } from 'react';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';

const ToolbarOptions = {
  options: [
    'inline',
    'blockType',
    'list',
    'textAlign',
    'link',
    'emoji',
    'image',
    'history',
  ],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
};

export default class TextEditor extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      editorState: EditorState.createEmpty(),
      html: '',
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  onEditorStateChange = (editorState) => {
    const { onChange } = this.props;
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.setState({
      editorState,
      html,
    });
    if (onChange) {
      onChange(html);
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value && props.value !== state.html) {
      console.log(props);
      const blocksFromHTML = convertFromHTML(props.value);
      if (!blocksFromHTML || !blocksFromHTML.contentBlocks) return null;
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return {
        html: props.value,
        editorState: EditorState.createWithContent(contentState),
      };
    }

    // Return null if the state hasn't changed
    return null;
  }
  render() {
    const { editorState } = this.state;
    const { onChange, value, ...props } = this.props;
    return (
      <div className="text-editor">
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={ToolbarOptions}
          {...props}
        />
      </div>
    );
  }
}
