import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Table from "./components/DataTable";
import {
  getUsers,
  deleteUser,
  importUsersAvatars,
  vppFilenameChanger,
} from "../../redux/actions/usersActions";
import { connect } from "react-redux";
import Loading from "../../shared/components/Loading";
import Alert from "../../shared/components/Alert";

class users extends React.Component {
  constructor() {
    super();
    this.state = {
      modal: {
        open: false,
        message: "",
        title: "",
        btn: "",
        color: "",
        success: () => {},
        toggle: () => {
          this.setState((prevState) => ({
            modal: { ...prevState.modal, open: !prevState.modal.open },
          }));
        },
      },
    };
  }
  componentDidMount() {
    this.props.doGetUsers();
  }

  onImportAvatarsClick = () => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        open: true,
        title: "Pay Attention!",
        message:
          'To do this correctly, make sure you put the desired images in the correct path on "{backend_path}/public/uploads/" by naming the passport for their name!',
        color: "primary",
        success: () => {
          this.props.importUsersAvatars();
          this.setState((prevState) => ({
            modal: {
              ...prevState,
              open: false,
            },
          }));
        },
      },
    }));
  };

  onVppFilenameChanger = () => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        open: true,
        title: "Warning!",
        message:
          'Are you sure to want change all images names in the "Visa", "Passports" and "Photos" folders?',
        color: "primary",
        success: () => {
          this.props.vppFilenameChanger();
          this.setState((prevState) => ({
            modal: {
              ...prevState,
              open: false,
            },
          }));
        },
      },
    }));
  };

  render() {
    const data = this.props.users.map((item) => {
      return {
        id: item.id,
        avatar: item.avatar, //&& item.avatar.path,
        visa: item.visa_file_link, //&& item.avatar.path,
        staff: item.staff ? "Yes" : "No",
        assistant: item.assistant ? "Yes" : "No",
        first_name: item.first_name,
        last_name: item.last_name,
        username: item.username,
        nationality: item.nationality,
        language: item.language ? item.language.title : "",
      };
    });

    return (
      <Container>
        <Row>
          <Col md={8}>
            <h3 className="page-title"> Users </h3>
          </Col>
          {false && (
            <Col md={2}>
              <Link
                style={{ display: "none" }}
                to="/admin/add-users"
                className="btn btn-primary products-list__btn-add"
              >
                New User
              </Link>
            </Col>
          )}
          <Col md={4}>
            {/* <Button
              color={"outline-primary"}
              onClick={this.onImportAvatarsClick}
            >
              Import Avatars
            </Button> */}
            <Button
              color={"outline-success"}
              onClick={this.onVppFilenameChanger}
            >
              Renaming
            </Button>
          </Col>
        </Row>
        <Alert color="danger" visible={this.props.error}>
          <p>
            <span className="bold-text">Warning!&nbsp;</span>
            {this.props.error}
          </p>
        </Alert>
        <Alert color="success" visible={this.props.message}>
          <p>
            <span className="bold-text">Success!&nbsp;</span>
            {this.props.message}
          </p>
        </Alert>
        <Row>
          {this.props.loading && (
            <Loading loading={!this.props.loading ? true : false} />
          )}
          <Table
            refreshData={() => this.props.doGetUsers()}
            entityName={"user(s)"}
            data={data || []}
            meta={this.props.meta}
            error={this.props.error}
            success={(id) => {
              this.props.doDeleteUser(id);
            }}
            modal={this.state.modal}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    loading: user.loading,
    error: user.error,
    users: user.users,
    message: user.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetUsers: () => dispatch(getUsers()),
    doDeleteUser: (id) => dispatch(deleteUser(id)),
    importUsersAvatars: () => dispatch(importUsersAvatars()),
    vppFilenameChanger: () => dispatch(vppFilenameChanger()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(users);
