import React, { PureComponent } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Table from "./components/Datatable";
import { connect } from "react-redux";
import {
  getWhiteboards,
  deleteWhiteboard,
} from "../../redux/actions/whiteboardActions";
import Loading from "../../shared/components/Loading";

class Whiteboards extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: Whiteboards._parseData(props.announcements || [], props.meta),
      announcements: props.announcements,
      meta: props.meta,
    };
  }
  componentDidMount() {
    this.props.doGetWhiteboards();
  }
  static _parseData(announcements, meta) {
    return announcements.map((item) => {
      return {
        id: item.id,
        groups:
          meta && meta.groupsKeys
            ? item.groups && item.groups.length
              ? item.groups.map((g) => meta.groupsKeys[g])
              : "None"
            : "Loading",
        title: item.title || "",
        important: item.important ? "true" : "false",
        sort: item.sort_index,
      };
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.announcements !== state.announcements ||
      state.meta !== props.meta
    ) {
      return {
        data: Whiteboards._parseData(props.announcements || [], props.meta),
        announcements: props.announcements,
        meta: props.meta,
      };
    }
    return null;
  }

  render() {
    console.log(this.state.data);
    return (
      <Container>
        <Row>
          <Col md={9}>
            <h3 className="page-title">Whiteboards</h3>
          </Col>
          <Col md={3}>
            <Link
              to="/admin/add-whiteboard"
              className="btn btn-primary products-list__btn-add"
            >
              New Whiteboard
            </Link>
          </Col>
        </Row>
        <Row>
          {!this.state.data && (
            <Loading loading={this.state.data ? true : false} />
          )}

          <Table
            // entityName={entityName}
            meta={this.state.meta}
            refreshData={() => this.props.doGetWhiteboards()}
            data={this.state.data}
            error={this.props.error}
            success={(id) => {
              this.props.doDeleteWhiteboard(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ whiteboard, metaData }) => {
  return {
    loading: whiteboard.loading,
    error: whiteboard.error,
    announcements: whiteboard.whiteboards,
    meta: metaData.metaData,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetWhiteboards: () => dispatch(getWhiteboards()),
    doDeleteWhiteboard: (id) => dispatch(deleteWhiteboard(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Whiteboards);
