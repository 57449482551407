import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import Table from "./components/DataTable";
import HelpmeForm from "./components/HelpmeForm";
import { connect } from "react-redux";
import Alert from "../../shared/components/Alert";
import { transformTransalationsForBackend } from "../../utils/dataTransformerForAPI";
import { addAppSetting } from "../../redux/actions/appSettingsActions";

class AddAppSetting extends React.Component {
  render() {
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">New App Setting </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Alert
                  color="danger"
                  visible={this.props.error !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Warning!</span>
                    {this.props.error}
                  </p>
                </Alert>
                <HelpmeForm
                  // languages={this.props.meta ? this.props.meta.languages : []}
                  // groups={this.props.meta ? this.props.meta.groups : []}
                  onSubmit={values => {
                    this.props.doAddAppSetting(
                      {
                        ...values,
                        translations: transformTransalationsForBackend(
                          values.translations || {}
                        )
                      },
                      this.props.history
                    );
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ appSetting, metaData }) => {
  return {
    loading: appSetting.loading,
    error: appSetting.error,
    appSetting: appSetting.appSetting,
    // meta: metaData.metaData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doAddAppSetting: (obj, props) => dispatch(addAppSetting(obj, props))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAppSetting);
