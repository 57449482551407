import Webservice from "../../utils/networkApis";

export const TRANSLATING = "TRANSLATING";
export const TRANSLATING_SUCCESS = "TRANSLATING_SUCCESS";
export const TRANSLATING_FAILED = "TRANSLATING_FAILED";

export function translateTo(terms, source, target, cb) {
  return (dispatch) => {
    dispatch({ type: TRANSLATING });
    Webservice.translateTo(terms, source, target)
      .then((resp) => {
        if (resp.status != 200) {
          dispatch({
            type: TRANSLATING_FAILED,
            payload: "Some error occured on translating your terms",
          });
          return;
        }
        const response = resp.data;
        dispatch({
          type: TRANSLATING_SUCCESS,
          payload: response.data.translations,
        });

        cb();
      })
      .catch(() =>
        dispatch({
          type: TRANSLATING_FAILED,
          payload: "Some error occured on translating your terms",
        })
      );
  };
}
