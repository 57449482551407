/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from 'react'
import { Card, CardBody, Col, Button } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import Modal from '../../../shared/components/Modal'
import CollapsibleTable from '../../../shared/components/table/CollapsibleTable'

class DataTableNew extends PureComponent {
  sectkeys = {
    0: 'Both',
    1: 'Sunni',
    2: 'Shia'
  }

  heads = [
    {
      render: row => {
        return (
          <div
            style={{ paddingLeft: row.metadata.depth * 5 + 'px' }}
            className={
              row.metadata.hasChildren ? 'with-children' : 'without-children'
            }
          >
            {row.metadata.hasChildren ? (
              <Button onClick={row.toggleChildren} close aria-label='Cancel'>
                <span aria-hidden>&ndash;</span>
              </Button>
            ) : (
              ''
            )}
            <span
              onClick={() =>
                this.props.history.push(`/admin/guide/${row.data.guide_id}`)
              }
            >
              {row.data.title}
            </span>
          </div>
        )
      },
      name: 'Title'
    },
    {
      key: 'order',
      name: 'Order',
      events: {
        onClick: (ev, args) => {
          this.props.history.push(`/admin/guide/${args.rowId}`)
        }
      }
    },
    {
      render: row => this.sectkeys[row.data.sect] || this.sectkeys[0],
      name: 'Sect',
      events: {
        onClick: (ev, args) => {
          this.props.history.push(`/admin/guide/${args.rowId}`)
        }
      }
    }
  ]
  constructor (props) {
    super(props)
    this.state = {
      modal: false,
      id: null
    }
  }

  actions = row => (
    <div className='ActionCell'>
      <Button
        style={{ marginRight: 10 }}
        size='sm'
        onClick={ev => {
          this.setState({ modal: true, id: row.data.guide_id })
        }}
        className='badge badge-success'
      >
        Destroy
      </Button>
      <Button
        style={{ marginRight: 10 }}
        size='sm'
        className='badge badge-success'
        onClick={ev => {
          this.props.history.push(`/admin/guide/${row.data.guide_id}/edit`)
        }}
      >
        edit
      </Button>
    </div>
  )

  toggle = () => {
    this.setState(prevState => ({ modal: !prevState.modal }))
  }

  render () {
    return (
      <Col md={12} lg={12}>
        <Modal
          open={this.state.modal}
          success={() => {
            this.props.doDeleteGuide(this.state.id)
            this.setState({ modal: false })
          }}
          toggle={this.toggle}
          color='danger'
          title='Warning!'
          colored
          btn='Danger'
          message='Are you sure you want to delete Guide ?'
        />
        <Card>
          <CardBody>
            <CollapsibleTable
              heads={this.heads}
              rows={this.props.data}
              getActions={this.actions}
            />
          </CardBody>
        </Card>
      </Col>
    )
  }
}

export default withRouter(DataTableNew)
