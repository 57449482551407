import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import Table from "./components/DataTable";
import AddAnnouncementForm from "./components/addAnnouncementForm";
import { addAnnouncement } from "../../redux/actions/announcementsActions";
import { connect } from "react-redux";
import Alert from "../../shared/components/Alert";
import { transformTransalationsForBackend } from "../../utils/dataTransformerForAPI";
import { translateTo } from "../../redux/actions/googleTranslation";

class AddAnnouncement extends React.Component {
  onTranslate = (terms, source, target, callback) => {
    this.props.doTranslate(terms, source, target, () => {
      callback(this.props.translations);
    });
  };

  render() {
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">New Message </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Alert
                  color="danger"
                  visible={this.props.error !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Warning!</span>
                    {this.props.error}
                  </p>
                </Alert>
                <AddAnnouncementForm
                  languages={this.props.meta ? this.props.meta.languages : []}
                  groups={this.props.meta ? this.props.meta.groups : []}
                  onTranslate={this.onTranslate}
                  onSubmit={(values) => {
                    // console.log('values doAddAnnouncement ' + values);
                    this.props.doAddAnnouncement(
                      {
                        ...values,
                        translations: transformTransalationsForBackend(
                          values.translations || {}
                        ),
                      },
                      this.props.history
                    );
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ announcement, metaData, googleTranslation }) => {
  return {
    loading: announcement.loading || googleTranslation.loading,
    error: announcement.error || googleTranslation.error,
    announcements: announcement.announcements,
    meta: metaData.metaData,
    translations: googleTranslation.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddAnnouncement: (obj, props) => {
      // console.log('addAnnouncement ' + JSON.stringify(obj));
      return dispatch(addAnnouncement(obj, props));
    },
    doTranslate: (terms, source, target, cb) =>
      dispatch(translateTo(terms, source, target, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAnnouncement);
