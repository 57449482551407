import React, { useEffect } from "react";
import { addUser } from "../../redux/actions/usersActions";
import webservice from "../../utils/networkApis";
import ShowEntity from "../../shared/components/ShowEntity";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class ShowUser extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null
  };

  fetchIt = id => {
    this.setState({ loading: true, id });
    webservice.getOneUser(id).then(resp => {
      let data = resp.data.data;

      this.setState({ initialValues: data, loading: false });
    });
  };

  componentDidMount() {
    this.fetchIt(this.props.match.params.id);
  }

  componentWillReceiveProps(props) {
    let id = props.match.params.id;

    if (id != this.state.id) this.fetchIt(id);
  }

  render() {
    const { loading, initialValues } = this.state;

    const { meta } = this.props;

    return (
      <ShowEntity
        values={
          initialValues
            ? [
                {
                  label: "GROUP",
                  value: (
                    <Link to={`/admin/group/${initialValues.id}`}>
                      {(initialValues.group && initialValues.group.leader) ||
                        ""}
                    </Link>
                  )
                },
                {
                  label: "EMAIL",
                  value: initialValues.email
                },
                {
                  label: "FIRST NAME",
                  value: initialValues.first_name
                },
                {
                  label: "LAST NAME",
                  value: initialValues.last_name
                },
                {
                  label: "SECT",
                  value: meta && meta.sectKeys[initialValues.sect]
                },
                {
                  label: "STAFF",
                  value: initialValues.staff ? "true" : "false"
                },
                {
                  label: "ASSITANT",
                  value: initialValues.assistant ? "true" : "false"
                },
                {
                  label: "GENDER",
                  value:
                    meta &&
                    meta.genders.find(
                      item => item.value === initialValues.gender
                    ).label
                },
                {
                  label: "PLACE OF BIRTH",
                  value: initialValues.place_of_birth
                },
                {
                  label: "LANGUAGE",
                  value: initialValues.language.title
                },
                {
                  label: "BORN ON",
                  value: initialValues.born_on
                },
                {
                  label: "PASSPORT",
                  value: initialValues.passport
                },
                {
                  label: "NATIONALITY",
                  value: initialValues.nationality
                },
                {
                  label: "ZIP CODE",
                  value: initialValues.zipcode
                },
                {
                  label: "USERNAME",
                  value: initialValues.username
                },
                {
                  label: "PHONE",
                  value: initialValues.phone
                },
                {
                  label: "KSA PHONE",
                  value: initialValues.ksa_phone
                },
                {
                  label: "VISA STATUS",
                  value: initialValues.visa_status
                }
              ]
            : []
        }
        loading={loading}
        label={`Show ${initialValues
          ? `${initialValues.first_name} ${initialValues.last_name}`
          : ""}`}
      />
    );
  }
}

const mapStateToProps = ({ user, metaData }) => {
  return {
    loading: user.loading,
    error: user.error,
    meta: metaData.metaData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doAddUsers: (obj, props) => dispatch(addUser(obj, props))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowUser);
