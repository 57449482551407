import React from 'react';
import ShowEntity from '../../shared/components/ShowEntity';
import { addGuide, editGuide } from '../../redux/actions/guidesActions';
import { connect } from 'react-redux';
import webservice from '../../utils/networkApis';
import LeafletMap from '../GoogleMap/components/BasicMap';
import { Link } from 'react-router-dom';

class ShowLostReport extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null,
  };

  fetchIt = (id) => {
    this.setState({ loading: true, id });
    webservice.getOneLostReport(id).then((resp) => {
      let data = resp.data.data;

      console.log({ data });

      this.setState({ initialValues: data, loading: false });
    });
  };

  componentDidMount() {
    this.fetchIt(this.props.match.params.id);
  }

  componentWillReceiveProps(props) {
    let id = props.match.params.id;

    if (id != this.state.id) this.fetchIt(id);
  }

  render() {
    const { loading, initialValues } = this.state;

    return (
      <ShowEntity
        values={
          initialValues
            ? [
                {
                  label: 'USER',
                  value: (
                    <Link to={`/admin/user/${initialValues.user_id}`}>
                      {(initialValues.users &&
                        `${initialValues.users.first_name} ${initialValues.users.last_name}`) ||
                        ''}
                    </Link>
                  ),
                },
                {
                  label: 'RESOLVED BY',
                  value: initialValues.resolved_by,
                },
                { label: 'ID', value: initialValues.id },
                {
                  label: 'RESOLVED AT',
                  value: initialValues.resolved_at,
                },
                {
                  label: 'LAT',
                  value: initialValues.lat,
                },
                {
                  label: 'LONG',
                  value: initialValues.long,
                },
                {
                  label: 'Location',
                  value: (
                    <LeafletMap
                      value={
                        (initialValues.lat &&
                          initialValues.long && {
                            lat: initialValues.lat,
                            lng: initialValues.long,
                          }) ||
                        null
                      }
                    />
                  ),
                },
                {
                  label: 'CREATED AT',
                  value: initialValues.created_at,
                },
                {
                  label: 'UPDATED AT',
                  value: initialValues.updated_at,
                },
              ]
            : []
        }
        loading={loading}
        label={`Show Lost Report`}
      />
    );
  }
}

const mapStateToProps = ({ guide, metaData }) => {
  return {
    loading: guide.loading,
    error: guide.error,
    contents: guide.contents,
    meta: metaData.metaData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddGuide: (obj, props) => dispatch(addGuide(obj, props)),
    doEditGuide: (obj, id) => dispatch(editGuide(obj, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowLostReport);
