import React, { Component } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "../../../shared/components/form/Select";
import TimetableIcon from "mdi-react/TimetableIcon";
import renderDateTimePickerField from "../../../shared/components/form/DateTimePicker";
import validate from "./validate";
import PropTypes from "prop-types";
import renderMultiSelectField from "../../../shared/components/form/MultiSelect";
import renderFileInputField from "../../../shared/components/form/FileInput";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

class AddFormInput extends Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    // error: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { handleSubmit, reset, t } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Groups</span>
          <div className="form__form-group-field">
            <Field
              name="groups"
              component={renderMultiSelectField}
              options={this.props.group}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Sort index</span>
          <div className="form__form-group-field">
            <Field
              name="sort_index"
              component={renderField}
              type={"number"}
              placeholder="Sort Index"
              min={0}
              defaultValue="0"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Title</span>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            className="form__form-group-field"
          >
            {this.props.language.map((item) => {
              return (
                <div
                  key={item.value}
                  style={{ display: "block", marginLeft: 20 }}
                >
                  <Field
                    name={`translations.${item.value}.title`}
                    component={renderField}
                    type="text"
                    placeholder={item.label}
                  />
                </div>
              );
            })}
            {/* <FieldArray name="title" component={RenderMembers} data={languages}  /> */}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Pick Image</span>
          <div className="form__form-group-field">
            <Field
              name="avatar"
              component={renderFileInputField}
              accept="image/*"
              dataAllowedFileExtensions="jpg png bmp"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Phone</span>
          <div className="form__form-group-field">
            <Field
              name="phone"
              component={renderField}
              type="text"
              placeholder="Phone"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Room in Medina</span>
          <div className="form__form-group-field">
            <Field
              name="room_medina"
              component={renderField}
              type="text"
              placeholder="Room number in Medina"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Room in Makkah</span>
          <div className="form__form-group-field">
            <Field
              name="room_makkah"
              component={renderField}
              type="text"
              placeholder="Room number in Makkah"
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">
            Submit
          </Button>
          <Button type="button" onClick={reset}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: "add_user_form",
  validate, // a unique identifier for this form
})(AddFormInput);
