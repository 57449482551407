import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import Table from "./components/DataTable";

import WhiteBoardForm from "./components/WhiteBoardForm";
import { connect } from "react-redux";
import Alert from "../../shared/components/Alert";
import { transformTransalationsForBackend } from "../../utils/dataTransformerForAPI";

import { addWhiteboard } from "../../redux/actions/whiteboardActions";
import { translateTo } from "../../redux/actions/googleTranslation";

class AddWhiteBoard extends React.Component {
  onTranslate = (terms, source, target, callback) => {
    this.props.doTranslate(terms, source, target, () => {
      callback(this.props.translations);
    });
  };

  render() {
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">New Whiteboard</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Alert
                  color="danger"
                  visible={this.props.error !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Warning!</span>
                    {this.props.error}
                  </p>
                </Alert>
                <WhiteBoardForm
                  languages={this.props.meta ? this.props.meta.languages : []}
                  groups={this.props.meta ? this.props.meta.groups : []}
                  onTranslate={this.onTranslate}
                  onSubmit={(values) => {
                    console.log({
                      ...values,
                      translations: transformTransalationsForBackend(
                        values.translations || {}
                      ),
                    });
                    this.props.doAddWhiteBoard(
                      {
                        ...values,
                        translations: transformTransalationsForBackend(
                          values.translations || {}
                        ),
                      },
                      this.props.history
                    );
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ whiteboard, metaData, googleTranslation }) => {
  return {
    loading: whiteboard.loading || googleTranslation.loading,
    error: whiteboard.error || googleTranslation.error,
    whiteboards: whiteboard.whiteboards,
    meta: metaData.metaData,
    translations: googleTranslation.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddWhiteBoard: (obj, props) => dispatch(addWhiteboard(obj, props)),
    doTranslate: (terms, source, target, cb) =>
      dispatch(translateTo(terms, source, target, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWhiteBoard);
