import Webservice from '../../utils/networkApis';

export const ANNOUNCEMENTS = 'ANNOUNCEMENTS';
export const ANNOUNCEMENTS_SUCCESS = 'ANNOUNCEMENTS_SUCCESS';
export const ANNOUNCEMENTS_FAILED = 'ANNOUNCEMENTS_FAILED';

export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT';
export const ADD_ANNOUNCEMENT_SUCCESS = 'ADD_ANNOUNCEMENT_SUCCESS';
export const ADD_ANNOUNCEMENT_FAILED = 'ADD_ANNOUNCEMENT_FAILED';

export const EDIT_ANNOUNCEMENT = 'EDIT_ANNOUNCEMENT';
export const EDIT_ANNOUNCEMENT_SUCCESS = 'EDIT_ANNOUNCEMENT_SUCCESS';
export const EDIT_ANNOUNCEMENT_FAILED = 'EDIT_ANNOUNCEMENT_FAILED';

export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS';
export const DELETE_ANNOUNCEMENT_FAILED = 'DELETE_ANNOUNCEMENT_FAILED';

export function getAnnouncements() {
  return (dispatch) => {
    dispatch({ type: ANNOUNCEMENTS });
    Webservice.getAnnouncements().then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ANNOUNCEMENTS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: ANNOUNCEMENTS_FAILED, payload: response.error });
      }
    });
  };
}

export function addAnnouncement(data, history) {
  // console.log('addAnnouncement ' + JSON.stringify(data));
  return (dispatch) => {
    dispatch({ type: ADD_ANNOUNCEMENT });
    Webservice.addAnnouncement(data).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_ANNOUNCEMENT_SUCCESS, payload: response.data });
        history.push(`/admin/announcement/${response.data.id}`);
      } else {
        dispatch({ type: ADD_ANNOUNCEMENT_FAILED, error: response.error });
      }
    });
  };
}

export function editAnnouncement(data, id) {
  return (dispatch) => {
    dispatch({ type: EDIT_ANNOUNCEMENT });
    Webservice.editAnnouncement(id, data).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_ANNOUNCEMENT_SUCCESS,
          payload: {
            data: { ...response.data, id },
            message: response.message,
          },
        });
      } else {
        dispatch({ type: EDIT_ANNOUNCEMENT_FAILED, error: response.error });
      }
    });
  };
}

export function deleteAnnouncement(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_ANNOUNCEMENT });
    Webservice.deleteAnnouncement(id).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_ANNOUNCEMENT_SUCCESS,
          payload: { id },
        });
      } else {
        dispatch({ type: DELETE_ANNOUNCEMENT_FAILED, error: response.error });
      }
    });
  };
}
