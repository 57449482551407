import React, { Component } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm, Fields } from "redux-form";
import Webservice from "../../../utils/networkApis";
import renderSelectField from "../../../shared/components/form/Select";
import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import renderFileInputField from "../../../shared/components/form/FileInput";
import renderMultiSelectField from "../../../shared/components/form/MultiSelect";
import LeafletMap from "../../GoogleMap/components/BasicMap";
// import validate from "./validate";
import PropTypes from "prop-types";
import renderTextEditor from "../../../shared/components/text-editor/reduxFormPort";

const renderField = ({ input, placeholder, type, meta: { touched, error } }) =>
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched &&
      error &&
      <span className="form__form-group-error">
        {error}
      </span>}
  </div>;
const renderTextArea = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) =>
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <textarea {...input} placeholder={placeholder} type={type} />
    {touched &&
      error &&
      <span className="form__form-group-error">
        {error}
      </span>}
  </div>;

class AddWhiteBoardForm extends Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    // error: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      languages: [],
    };
  }

  render() {
    const { handleSubmit, reset, t } = this.props;

    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Property</span>
          <div className="form__form-group-field">
            <Field
              name="property"
              component={renderField}
              type="text"
              placeholder="Property"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Value</span>
          <div className="form__form-group-field">
            <Field
              name="value"
              component={renderField}
              type="text"
              placeholder="Value"
            />
          </div>
        </div>

        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">
            Submit
          </Button>
          <Button type="button" onClick={reset}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: "add_appsetting_form",
  //   validate // a unique identifier for this form
})(AddWhiteBoardForm);
