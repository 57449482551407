import React, { Component } from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { connect } from "react-redux";
class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { changeToDark, changeToLight, metaData } = this.props;

    const { is_super_admin } = metaData
      ? metaData.currentUser
      : { is_super_admin: null };

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {/* <SidebarCategory title="Admin users Management" icon="diamond"> */}

          {is_super_admin && (
            <SidebarLink
              title="Admin Users"
              route="/admin/admin-users"
              onClick={this.hideSidebar}
            />
          )}
          <SidebarLink
            title="Users"
            route="/admin/users"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Programs"
            route="/admin/itineraries"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Messages"
            route="/admin/announcements"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Help Me"
            route="/admin/helpmes"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="WhiteBoards"
            route="/admin/whiteboards"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Lost Reports"
            route="/admin/lost-reports"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Assistance"
            route="/admin/assistances"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Contacts"
            route="/admin/contacts"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Groups"
            route="/admin/groups"
            onClick={this.hideSidebar}
          />
          {is_super_admin && (
            <SidebarCategory title="Places" icon="layers">
              <SidebarLink
                title="All"
                route="/admin/contents/all"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Airports"
                route="/admin/contents/airport"
                onClick={this.hideSidebar}
              />

              <SidebarLink
                title="Gathering"
                route="/admin/contents/gathering"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Hotel"
                route="/admin/contents/hotel"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Restaurant"
                route="/admin/contents/restaurant"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Market"
                route="/admin/contents/market"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Shrine"
                route="/admin/contents/shrine"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Tourism"
                route="/admin/contents/tourism"
                onClick={this.hideSidebar}
              />
            </SidebarCategory>
          )}
          {is_super_admin && (
            <SidebarLink
              title="Guides"
              route="/admin/guides"
              onClick={this.hideSidebar}
            />
          )}
          {is_super_admin && (
            <SidebarLink
              title="Participants"
              route="/admin/attendances"
              onClick={this.hideSidebar}
            />
          )}
          {is_super_admin && (
            <SidebarLink
              title="Ratings"
              route="/admin/ratings"
              onClick={this.hideSidebar}
            />
          )}
          {is_super_admin && (
            <SidebarLink
              title="Languages"
              route="/admin/languages"
              onClick={this.hideSidebar}
            />
          )}
          {is_super_admin && (
            <SidebarLink
              title="App Translations"
              route="/admin/app-translations"
              onClick={this.hideSidebar}
            />
          )}
          {is_super_admin && (
            <SidebarLink
              title="App Settings"
              route="/admin/app-settings"
              onClick={this.hideSidebar}
            />
          )}
          {is_super_admin && (
            <SidebarLink
              title="Imported users"
              route="/admin/imported-users"
              onClick={this.hideSidebar}
            />
          )}
          {is_super_admin && (
            <SidebarLink
              title="Imported Groups"
              route="/admin/imported-groups"
              onClick={this.hideSidebar}
            />
          )}
          {is_super_admin && (
            <SidebarLink
              title="Imported Hotels"
              route="/admin/imported-hotels"
              onClick={this.hideSidebar}
            />
          )}
        </ul>
      </div>
    );
  }
}
const mapStateToProps = ({ adminUser, metaData }) => {
  return {
    metaData: metaData.metaData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContent);
