import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Table from './components/DataTable';
import { connect } from 'react-redux';
import {
  getImportedUsers,
  deleteImportedUser,
} from '../../redux/actions/importedUsersActions';

class ImportedUsers extends PureComponent {
  componentDidMount() {
    this.props.doGetImportedUser();
  }

  render() {
    const data = this.props.importedUsers.map((item) => {
      return {
        group_name: item.group_name,
        first_name: item.first_name,
        nationality: item.nationality,
        surname: item.surname,
        gender: item.gender,
        passport_no: item.passport_no,
        user_login: item.user_login,
        visa_status: item.visa_status,
        language: item.language,
        ksa_no: item.ksa_no,
        reg_id: item.reg_id,
        seminar_start_on: item.seminar_start_on,
        seminar_end_on: item.seminar_end_on,
      };
    });

    console.log('importedUsers', this.props.importedUsers);

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Imported users</h3>
          </Col>
        </Row>
        <Row>
          <Table
            // entityName={entityName}
            data={data || []}
            error={this.props.error}
            success={(id) => {
              this.props.doDeleteGroup(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ importedUser }) => {
  return {
    loading: importedUser.loading,
    error: importedUser.error,
    importedUsers: importedUser.importedUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetImportedUser: () => dispatch(getImportedUsers()),
    doDeleteImportedUser: (id) => dispatch(deleteImportedUser(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportedUsers);
