import { LOGIN, LOGIN_FAILED, LOGIN_SUCCESS } from "../actions/loginActions";

const initialState = {
  loading: false,
  user: null,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS:
      return { ...state, error: null, loading: false, user: action.payload };
    case LOGIN_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
