import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import AddUserFrom from './components/addUserForm';
import { addAdminUser } from '../../redux/actions/adminUsersActions';
import { connect } from 'react-redux';
import Alert from '../../shared/components/Alert';

class AddAdminUsers extends React.Component {
  render() {
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">New Admin User </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Add Admin User</h5>
                  <h5 className="subhead">Labels are left from fields</h5>
                </div>
                <Alert
                  color="danger"
                  visible={this.props.error !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Warning!</span>
                    {this.props.error}
                  </p>
                </Alert>
                <AddUserFrom
                  onSubmit={(values) => {
                    //console.log(values)
                    this.props.doAddAdminUsers(values, this.props.history);
                  }}
                  group={this.props.meta ? this.props.meta.groups : []}
                  error={this.props.error}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ adminUser, metaData }) => {
  return {
    loading: adminUser.loading,
    error: adminUser.error,
    adminUsers: adminUser.adminUsers,
    meta: metaData.metaData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddAdminUsers: (obj, props) => dispatch(addAdminUser(obj, props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAdminUsers);
