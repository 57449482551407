import {
  USERS,
  USERS_FAILED,
  USERS_SUCCESS,
  ADD_USER,
  ADD_USER_FAILED,
  ADD_USER_SUCCESS,
  EDIT_USER,
  EDIT_USER_FAILED,
  EDIT_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  IMPORT_AVATARS_USER,
  IMPORT_AVATARS_USER_FAILED,
  IMPORT_AVATARS_USER_SUCCESS,
  VPP_FILENAME_CHANGER,
  VPP_FILENAME_CHANGER_SUCCESS,
  VPP_FILENAME_CHANGER_FAILED,
} from "../actions/usersActions";

const initialState = {
  loading: false,
  users: [],
  message: null,
  data: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USERS:
      return { ...state, loading: true, error: null };
    case USERS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        users: action.payload,
      };
    case USERS_FAILED:
      return { ...state, loading: false, error: action.payload };
    case ADD_USER:
      return { ...state, loading: true, error: null };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        users: [...state.users, action.payload],
      };
    case ADD_USER_FAILED:
      return { ...state, loading: false, error: action.error };
    case DELETE_USER:
      return { ...state, loading: true, error: null };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        users: state.users.filter((item) => item.id !== action.payload.id),
      };
    case DELETE_USER_FAILED:
      return { ...state, loading: false, error: action.error };
    case IMPORT_AVATARS_USER:
      return { ...state, loading: true, error: null };
    case IMPORT_AVATARS_USER_SUCCESS:
      return {
        ...state,
        error: null,
        message: action.payload.message,
        loading: false,
      };
    case IMPORT_AVATARS_USER_FAILED:
      return { ...state, loading: false, error: action.error };
    case VPP_FILENAME_CHANGER:
      return { ...state, loading: true, error: null };
    case VPP_FILENAME_CHANGER_SUCCESS:
      return {
        ...state,
        error: null,
        message: action.payload.message,
        loading: false,
      };
    case VPP_FILENAME_CHANGER_FAILED:
      return { ...state, loading: false, error: action.error };
    case EDIT_USER:
      return { ...state, loading: true, error: null };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        message: action.payload.message,
        users: state.users.map((item) =>
          item.id == action.payload.data.id ? action.payload.data : item
        ),
      };
    case EDIT_USER_FAILED:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
}
