import {
  ADD_APP_TRANSLATION,
  ADD_APP_TRANSLATION_FAILED,
  ADD_APP_TRANSLATION_SUCCESS,
  APP_TRANSLATIONS,
  APP_TRANSLATIONS_FAILED,
  APP_TRANSLATIONS_SUCCESS,
  EDIT_APP_TRANSLATION,
  EDIT_APP_TRANSLATION_FAILED,
  EDIT_APP_TRANSLATION_SUCCESS,
  DELETE_APP_TRANSLATION,
  DELETE_APP_TRANSLATION_FAILED,
  DELETE_APP_TRANSLATION_SUCCESS
} from "../actions/appTranslationsActions";

const initialState = {
  loading: false,
  appTranslations: [],
  message: null,
  data: null,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case APP_TRANSLATIONS:
      return { ...state, loading: true, error: null };
    case APP_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        appTranslations: action.payload
      };
    case APP_TRANSLATIONS_FAILED:
      return { ...state, loading: false, error: action.payload };
    case ADD_APP_TRANSLATION:
      return { ...state, loading: true, error: null };
    case ADD_APP_TRANSLATION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        appTranslations: [...state.appTranslations, action.payload]
      };
    case ADD_APP_TRANSLATION_FAILED:
      return { ...state, loading: false, error: action.error };
    case DELETE_APP_TRANSLATION:
      return { ...state, loading: true, error: null };
    case DELETE_APP_TRANSLATION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        appTranslations: state.appTranslations.filter(
          item => item.id !== action.payload.id
        )
      };
    case DELETE_APP_TRANSLATION_FAILED:
      return { ...state, loading: false, error: action.error };
    case EDIT_APP_TRANSLATION:
      return { ...state, loading: true, error: null };
    case EDIT_APP_TRANSLATION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        message: action.payload.message,
        appTranslations: state.appTranslations.map(
          item =>
            item.id == action.payload.data.id ? action.payload.data : item
        )
      };
    case EDIT_APP_TRANSLATION_FAILED:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
}
