import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import webservice from "../../utils/networkApis";
import { normalizeDate } from "../../utils/date";
import LeafletMap from "../GoogleMap/components/BasicMap";
import ShowEntity from "../../shared/components/ShowEntity";
import { addGuide, editGuide } from "../../redux/actions/guidesActions";
import { changeTimezoneOfDateBy, formatDate } from "../../utils/general";
import moment from "moment-timezone";

class ShowItinerary extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null,
  };

  fetchIt = (id) => {
    this.setState({ loading: true, id });
    webservice.showitinerary(id).then((resp) => {
      let data = resp.data.data;

      // data = { ...data, time: moment(data.time).format('YYYY-MM-DDThh:mm:ss') };
      // this.setState({
      //   initialValues: data,
      //   loading: false,
      // });

      this.setState({
        initialValues: data,
        loading: false,
      });
    });
  };

  componentDidMount() {
    this.fetchIt(this.props.match.params.id);
  }

  componentWillReceiveProps(props) {
    let id = props.match.params.id;

    if (id != this.state.id) this.fetchIt(id);
  }

  render() {
    const { loading, initialValues } = this.state;

    return (
      initialValues && (
        <ShowEntity
          editButton={`/admin/itinerary/${initialValues.id}/edit`}
          values={
            initialValues
              ? [
                  { label: "ID", value: initialValues.id },
                  {
                    label: "PLACE",
                    value: (
                      <Link to={`/admin/content/${initialValues.content_id}`}>
                        {(initialValues.places && initialValues.places.title) ||
                          ""}
                      </Link>
                    ),
                  },
                  {
                    label: "TITLE",
                    value: initialValues.translations.title,
                  },
                  {
                    label: "DESCRIPTION",
                    value: initialValues.translations.description,
                  },
                  {
                    label: "GROUP",
                    value: (
                      <Link to={`/admin/group/${initialValues.group_id}`}>
                        {(initialValues.group && initialValues.group.leader) ||
                          ""}
                      </Link>
                    ),
                  },
                  {
                    label: "HAS PARTICIPANT",
                    value: initialValues.has_attendance ? "true" : "false",
                  },
                  {
                    label: "HAS EVALUATION",
                    value: initialValues.has_evaluation ? "true" : "false",
                  },
                  {
                    label: "PARTICIPANTS",
                    value: "none",
                  },
                  {
                    label: "TIME",
                    value: formatDate(
                      initialValues.time,
                      initialValues.zone || "Asia/Riyadh"
                    ),
                  },
                  {
                    label: "ZONE",
                    value: initialValues.zone || "Asia/Riyadh",
                  },
                  {
                    label: "CREATED AT",
                    value: formatDate(initialValues.createdAt),
                  },
                  {
                    label: "UPDATED AT",
                    value: formatDate(initialValues.updatedAt),
                  },
                ]
              : []
          }
          loading={loading}
          label={`Show ${
            initialValues ? initialValues.translations.title : ""
          }`}
        />
      )
    );
  }
}

const mapStateToProps = ({ guide, metaData }) => {
  return {
    loading: guide.loading,
    error: guide.error,
    contents: guide.contents,
    meta: metaData.metaData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddGuide: (obj, props) => dispatch(addGuide(obj, props)),
    doEditGuide: (obj, id) => dispatch(editGuide(obj, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowItinerary);
