import React, { PureComponent } from "react";
import { Col, Container, Row } from "reactstrap";
import Table from "./components/DataTable";
import { connect } from "react-redux";
import {
  getAttendances,
  deleteAttendance,
} from "../../redux/actions/attendancesActions";

class Ratings extends PureComponent {
  componentDidMount() {
    const itineraryId = this.props.match.params.id;
    const groupId = this.props.match.params.groupId;
    this.props.doGetAttendances(groupId, itineraryId, "rating");
  }

  render() {
    const data = this.props.attendances.map((item) => {
      console.log({ item });
      return {
        id: item.id,
        user: item.user ? `${item.user.first_name} ${item.user.last_name}` : "",
        group: item.itinerary
          ? item.itinerary.group
            ? item.itinerary.group.leader
            : "N/A"
          : "N/A",
        itinerary: item.title || "N/A",
        attending: item.attending ? "true" : "false",
        persons: item.person_count,
        rating: item.rating ? item.rating : "-",
        review: item.review ? item.review : "---",
      };
    });

    console.log({ data });

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Ratings</h3>
          </Col>
        </Row>
        <Row>
          <Table
            // entityName={entityName}
            refreshData={() => {
              const itineraryId = this.props.match.params.id;
              const groupId = this.props.match.params.groupId;
              this.props.doGetAttendances(groupId, itineraryId, "rating");
            }}
            data={data || []}
            error={this.props.error}
            success={(id) => {
              alert(id);
              if (id) {
                this.props.doDeleteAttendances(id).then(() => {
                  const itineraryId = this.props.match.params.id;
                  const groupId = this.props.match.params.groupId;
                  this.props.doGetAttendances(groupId, itineraryId, "rating");
                });
              }
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ attendance }) => {
  return {
    loading: attendance.loading,
    error: attendance.error,
    attendances: attendance.attendances,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAttendances: (groupId, itineraryId, mode) =>
      dispatch(getAttendances(groupId, itineraryId, mode)),
    doDeleteAttendances: (ids) => dispatch(deleteAttendance(ids)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ratings);
