import React, { Component } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm, FieldArray } from "redux-form";
import Webservice from "../../../utils/networkApis";
import renderSelectField from "../../../shared/components/form/Select";
import renderTextEditor from "../../../shared/components/text-editor/reduxFormPort";
// import validate from "./validate";
import PropTypes from "prop-types";
import renderFileInputField from "../../../shared/components/form/FileInput";
import renderFileInputRemoverField from "../../../shared/components/form/FileInputRemover";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

class AddPlaceForm extends Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    isEditing: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      languages: [],
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  onTranslateClick = (e) => {
    const title = this.title_no.value || this.title_en.value;
    const desc = this.desc_no.value || this.desc_en.value;
    const source = this.title_no.value ? "no" : "en";

    this.props.onTranslate([title, desc], source, "fa", (translations) => {
      if (translations.length) {
        this.props.change(this.title_fa.name, translations[0].translatedText);
        this.props.change(this.desc_fa.name, translations[1].translatedText);
      }
    });

    this.props.onTranslate([title, desc], source, "ar", (translations) => {
      if (translations.length) {
        this.props.change(this.title_ar.name, translations[0].translatedText);
        this.props.change(this.desc_ar.name, translations[1].translatedText);
      }
    });

    this.props.onTranslate([title, desc], source, "ur", (translations) => {
      if (translations.length) {
        this.props.change(this.title_ur.name, translations[0].translatedText);
        this.props.change(this.desc_ur.name, translations[1].translatedText);
      }
    });

    this.props.onTranslate(
      [title, desc],
      source,
      source === "no" ? "en" : "no",
      (translations) => {
        if (translations.length) {
          this.props.change(
            this[`title_${source === "no" ? "en" : "no"}`].name,
            translations[0].translatedText
          );
          this.props.change(
            this[`desc_${source === "no" ? "en" : "no"}`].name,
            translations[1].translatedText
          );
        }
      }
    );
  };

  render() {
    const { handleSubmit, reset, isEditing, t } = this.props;
    const sectOptions = [
      { value: 0, label: "Both", name: "sect" },
      { value: 1, label: "Sunni", name: "sect" },
      { value: 2, label: "Shia", name: "sect" },
    ];
    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Parent</span>
          <div className="form__form-group-field">
            <Field
              name="parent_id"
              component={renderSelectField}
              options={this.props.guides}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Sect</span>
          <div className="form__form-group-field">
            <Field
              name="sect"
              component={renderSelectField}
              options={sectOptions}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Order</span>
          <div className="form__form-group-field">
            <Field
              name="order"
              component={renderField}
              type="text"
              placeholder="Order"
            />
          </div>
        </div>
        {isEditing && (
          <div className="form__form-group">
            <span className="form__form-group-label">Files</span>
            <div className="form__form-group-field">
              <Field
                name="images"
                component={renderFileInputRemoverField}
                multiple
              />
            </div>
          </div>
        )}
        <div className="form__form-group">
          <span className="form__form-group-label">Add Files</span>
          <div className="form__form-group-field">
            <Field
              name="add_images"
              component={renderFileInputField}
              multiple
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Title</span>
          <div
            className="form__form-group-field"
            style={{
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {this.props.languages.map((item) => {
              return (
                <Col key={item.value} style={{ padding: 0 }} sm={12} md={5}>
                  <Field
                    name={`translations.${item.value}.title`}
                    component={renderField}
                    type="text"
                    placeholder={item.label}
                    ref={(ref) => (this[`title_${item.value}`] = ref)}
                  />
                </Col>
              );
            })}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Description</span>
          <div className="form__form-group-field wraped">
            {this.props.languages.map((item) => {
              return (
                <div
                  key={item.value}
                  style={{ display: "block", marginLeft: 20 }}
                >
                  <Field
                    name={`translations.${item.value}.description`}
                    component={renderTextEditor}
                    type="text"
                    placeholder={item.label}
                    ref={(ref) => (this[`desc_${item.value}`] = ref)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">
            Submit
          </Button>
          <Button type="button" onClick={reset}>
            Cancel
          </Button>
          <Button color="info" onClick={this.onTranslateClick}>
            Translate
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: "add_guide_form",
  // validate // a unique identifier for this form
})(AddPlaceForm);
