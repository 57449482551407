import React from 'react'
import ShowEntity from '../../shared/components/ShowEntity'
import { addGuide, editGuide } from '../../redux/actions/guidesActions'
import { connect } from 'react-redux'
import webservice from '../../utils/networkApis'
import { Link } from 'react-router-dom'
import RenderFile from '../../shared/components/RenderFile'

class ShowGuides extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null
  }

  fetchIt = id => {
    this.setState({ loading: true, id })
    webservice.getOneGuide(id, true).then(resp => {
      let data = resp.data.data

      this.setState({ initialValues: data, loading: false })
    })
  }

  componentDidMount () {
    this.fetchIt(this.props.match.params.id)
  }

  componentWillReceiveProps (props) {
    let id = props.match.params.id

    if (id != this.state.id) this.fetchIt(id)
  }

  render () {
    const { loading, initialValues } = this.state

    const { meta } = this.props
    const sectOptions = [
      { value: 0, label: 'Both', name: 'sect' },
      { value: 1, label: 'Sunni', name: 'sect' },
      { value: 2, label: 'Shia', name: 'sect' }
    ]
    const sectkeys = {
      0: 'Both',
      1: 'Sunni',
      2: 'Shia'
    }
    return (
      <ShowEntity
        values={
          initialValues
            ? [
                { label: 'ID', value: initialValues.id },
                {
                  label: 'Parent',
                  value: (
                    <Link to={`/admin/guide/${initialValues.parent_id}`}>
                      {(initialValues.parent_translations &&
                        initialValues.parent_translations.title) ||
                        ''}
                    </Link>
                  )
                },
                {
                  label: 'Order',
                  value: initialValues.order
                },
                {
                  label: 'TITLE',
                  value: initialValues.translations.title
                },
                {
                  label: 'Sect',
                  value: sectkeys[initialValues.sect] || 'loading..'
                },
                {
                  label: 'Files',
                  value: (initialValues.images || []).map(file => (
                    <RenderFile {...file} />
                  ))
                },
                {
                  label: 'Description',
                  value: initialValues.translations.description
                },
                {
                  label: 'updatedAt',
                  value: initialValues.updated_at
                }
              ]
            : []
        }
        loading={loading}
        editButton={this.state.id && `/admin/guide/${this.state.id}/edit`}
        label={`Show ${initialValues ? initialValues.translations.title : ''}`}
      />
    )
  }
}

const mapStateToProps = ({ guide, metaData }) => {
  return {
    loading: guide.loading,
    error: guide.error,
    contents: guide.contents,
    meta: metaData.metaData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    doAddGuide: (obj, props) => dispatch(addGuide(obj, props)),
    doEditGuide: (obj, id) => dispatch(editGuide(obj, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowGuides)
