import {
  CONTENTS,
  CONTENTS_FAILED,
  CONTENTS_SUCCESS,
  ADD_CONTENT,
  ADD_CONTENT_FAILED,
  ADD_CONTENT_SUCCESS,
  EDIT_CONTENT,
  EDIT_CONTENT_FAILED,
  EDIT_CONTENT_SUCCESS,
  DELETE_CONTENT,
  DELETE_CONTENT_FAILED,
  DELETE_CONTENT_SUCCESS,
  AIRPORT_CONTENTS,
  AIRPORT_CONTENTS_FAILED,
  AIRPORT_CONTENTS_SUCCESS,
  GATHERING_CONTENTS,
  GATHERING_CONTENTS_FAILED,
  GATHERING_CONTENTS_SUCCESS,
  MARKET_CONTENTS,
  MARKET_CONTENTS_FAILED,
  MARKET_CONTENTS_SUCCESS,
  HOTEL_CONTENTS,
  HOTEL_CONTENTS_FAILED,
  HOTEL_CONTENTS_SUCCESS,
  SHRINE_CONTENTS,
  SHRINE_CONTENTS_FAILED,
  SHRINE_CONTENTS_SUCCESS,
  TOURISM_CONTENTS,
  TOURISM_CONTENTS_FAILED,
  TOURISM_CONTENTS_SUCCESS
} from "../actions/contentsActions";

const initialState = {
  loading: false,
  contents: [],
  message: null,
  data: null,
  deleteError: null,
  addError: null,
  editError: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CONTENTS:
      return { ...state, loading: true, error: null };
    case CONTENTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        contents: action.payload
      };
    case CONTENTS_FAILED:
      return { ...state, loading: false, error: action.payload };
    case ADD_CONTENT:
      return { ...state, loading: true, addError: null };
    case ADD_CONTENT_SUCCESS:
      return {
        ...state,
        addError: null,
        loading: false,
        contents: [...state.contents, action.payload]
      };
    case ADD_CONTENT_FAILED:
      return { ...state, loading: false, addError: action.error };
    case DELETE_CONTENT:
      return { ...state, loading: true, deleteError: null };
    case DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        deleteError: null,
        loading: false,
        contents: state.contents.filter(
          item => item.content_id !== action.payload.id
        )
      };
    case DELETE_CONTENT_FAILED:
      return { ...state, loading: false, error: action.error };
    case EDIT_CONTENT:
      return { ...state, loading: true, editError: null };
    case EDIT_CONTENT_SUCCESS:
      return {
        ...state,
        editError: null,
        loading: false,
        message: action.payload.message,
        contents: state.contents.map(
          item =>
            item.id == action.payload.data.id ? action.payload.data : item
        )
      };
    case EDIT_CONTENT_FAILED:
      return { ...state, loading: false, editError: action.error };

    default:
      return state;
  }
}
