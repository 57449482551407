import React from "react";
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  Button,
  ButtonToolbar,
} from "reactstrap";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import TimetableIcon from "mdi-react/TimetableIcon";
import EyeIcon from "mdi-react/EyeIcon";
import DatePicker from "react-datepicker";

import { editUser } from "../../redux/actions/usersActions";
import Alert from "../../shared/components/Alert";
import Webservice from "../../utils/networkApis";

const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};
class EditAdminUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      place_of_birth: "",
      languageOptions: [
        { value: 0, label: "English", name: "language_id" },
        { value: 1, label: "Norsk", name: "language_id" },
        { value: 2, label: "Farsi", name: "language_id" },
        { value: 3, label: "urdu", name: "language_id" },
        { value: 4, label: "arabic", name: "language_id" },
      ],
      sectOptions: [
        { value: 0, label: "Sunni", name: "sect" },
        { value: 1, label: "Shia", name: "sect" },
      ],
      genderOptions: [
        { value: 0, label: "Male", name: "gender" },
        { value: 1, label: "Female", name: "gender" },
      ],
      visaOptions: [
        { value: 0, label: "New", name: "visa_status" },
        {
          value: 1,
          label: "Issued",
          name: "visa_status",
        },
        {
          value: 2,
          label: "Finished",
          name: "visa_status",
        },
        { value: 3, label: "Canceled", name: "visa_status" },
      ],
      sect: null,
      gender: null,
      language_id: null,
      visa_status: null,
      born_on: new Date(),
      passport: "",
      phone: "",
      ksa_phone: "",
      created_at: "",
      nationality: "",
      zipcode: "",
      username: "",
      encrypted_password: "",
      staff: false,
      assistant: false,
      assigned_groups: null,
      group_id: null,
    };
  }
  componentDidMount() {
    const id = this.props.match.params.id;
    Webservice.getOneUser(id).then((resp) => {
      const data = resp.data.data;

      this.setState({
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        created_at: data.created_at,
        sect: data.sect,
        gender: data.gender,
        visa_status: data.visa_status,
        born_on: new Date(data.born_on),
        language_id: data.language_id,
        place_of_birth: data.place_of_birth,
        passport: data.passport,
        phone: data.phone,
        ksa_phone: data.ksa_phone,
        nationality: data.nationality,
        zipcode: data.zipcode,
        username: data.username,
        encrypted_password: "",
        staff: data.staff,
        assistant: data.assistant,
        assigned_groups: (data.assigned_groups &&
          data.assigned_groups.split(",")) || [`${data.group_id}`],
      });
    });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeHandlerDropdown = (e) => {
    this.setState({ [e.name]: e.value });
  };

  changeHandlerMultiGroupsSelect = (values) => {
    this.setState({ assigned_groups: values.map((item) => item.value) });
  };

  changeHandlerCreatorForDate = (name) => (value) => {
    this.setState({ [name]: value });
  };
  componentDidUpdate() {
    scrollToTop();
  }
  editUser = (e) => {
    e.preventDefault();
    const id = this.props.match.params.id;

    const setDataForPost = {
      email: this.state.email,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      sect: this.state.sect,
      gender: this.state.gender,
      visa_status: this.state.visa_status,
      language_id: this.state.language_id,
      place_of_birth: this.state.place_of_birth,
      passport: this.state.passport,
      phone: this.state.phone,
      ksa_phone: this.state.ksa_phone,
      nationality: this.state.nationality,
      born_on: this.state.born_on.toDateString(),
      zipcode: this.state.zipcode,
      username: this.state.username,
      encrypted_password: this.state.encrypted_password,
      assigned_groups:
        (this.state.assigned_groups && this.state.assigned_groups.join(",")) ||
        null,
    };

    this.props.doUpdateUser(setDataForPost, id);
    //const id = this.props.match.params.id
    this.props.history.push(`/admin/user/${id}`);
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { showPassword } = this.state;
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title"> Edit User </h3>
            {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
                information
          </h3> */}
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Edit</h5>
                </div>
                <Alert
                  color="success"
                  visible={this.props.message !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Success </span>
                    {this.props.message}
                  </p>
                </Alert>
                <form
                  onSubmit={this.editUser}
                  className="form form--horizontal"
                >
                  {(this.state.staff || this.state.assistant)&& (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Assigned Groups
                      </span>
                      <div className="form__form-group-field">
                        <Select
                          isMulti
                          name={"assigned_groups"}
                          value={
                            this.state.assigned_groups &&
                            this.state.assigned_groups.map((value) =>
                              (
                                (this.props.meta && this.props.meta.groups) ||
                                []
                              ).find((o) => o.value == value)
                            )
                          }
                          onChange={this.changeHandlerMultiGroupsSelect}
                          options={
                            (this.props.meta && this.props.meta.groups) || []
                          }
                          clearable={false}
                          closeOnSelect={false}
                          removeSelected={false}
                          className="react-select"
                          placeholder={"Assign one or more groups.."}
                          classNamePrefix="react-select"
                        />
                      </div>
                    </div>
                  )}
                  <div className="form__form-group">
                    <span className="form__form-group-label">First name</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        // placeholder="Email"
                        name="first_name"
                        value={this.state.first_name}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Last name</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        // placeholder="Email"
                        name="last_name"
                        value={this.state.last_name}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Username</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="username"
                        name="Username"
                        value={this.state.username}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Password</span>
                    <div className="form__form-group-field">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        name="encrypted_password"
                        value={this.state.encrypted_password}
                        onChange={this.changeHandler}
                      />
                      <button
                        className={`form__form-group-button${
                          showPassword ? " active" : ""
                        }`}
                        onClick={(e) => this.showPassword(e)}
                        type="button"
                      >
                        <EyeIcon />
                      </button>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Sect</span>
                    <div className="form__form-group-field">
                      <Select
                        name="sect"
                        value={this.state.sectOptions.filter(
                          (item) => item.value === this.state.sect
                        )}
                        onChange={this.changeHandlerDropdown}
                        options={this.state.sectOptions}
                        clearable={false}
                        className="react-select"
                        // classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Gender</span>
                    <div className="form__form-group-field">
                      <Select
                        name="gender"
                        // value={value}
                        onChange={this.changeHandlerDropdown}
                        value={this.state.genderOptions.filter(
                          (item) => item.value === this.state.gender
                        )}
                        options={this.state.genderOptions}
                        clearable={false}
                        className="react-select"
                        // classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Place Of Birth
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Place Of Birth"
                        name="place_of_birth"
                        value={this.state.place_of_birth}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Language</span>
                    <div className="form__form-group-field">
                      <Select
                        name="language_id"
                        value={this.state.languageOptions.filter(
                          (item) =>
                            item.value === parseInt(this.state.language_id)
                        )}
                        onChange={this.changeHandlerDropdown}
                        options={this.state.languageOptions}
                        clearable={false}
                        className="react-select"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Born On</span>
                    <div className="form__form-group-field">
                      {this.state.born_on && (
                        <DatePicker
                          name="born_on"
                          className="form__form-group-datepicker"
                          selected={moment(this.state.born_on).toDate()}
                          onChange={this.changeHandlerCreatorForDate("born_on")}
                          dateFormat="MMMM d, yyyy"
                          dropDownMode="select"
                        />
                      )}
                      <div className="form__form-group-icon">
                        <TimetableIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Passport</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Place Of Birth"
                        name="passport"
                        value={this.state.passport}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Phone</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Phone"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">KSA Phone</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Place Of Birth"
                        name="ksa_phone"
                        value={this.state.ksa_phone}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Nationality</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Nationality"
                        name="nationality"
                        value={this.state.nationality}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Zipcode</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder=""
                        name="zipcode"
                        value={this.state.zipcode}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Visa status</span>
                    <div className="form__form-group-field">
                      <Select
                        name="visa_status"
                        onChange={this.changeHandlerDropdown}
                        value={this.state.visaOptions.filter(
                          (item) => item.value === this.state.visa_status
                        )}
                        options={this.state.visaOptions}
                        clearable={false}
                        className="react-select"
                        // classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Update
                    </Button>
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ user, metaData }) => {
  return {
    loading: user.loading,
    error: user.error,
    users: user.adminUsers,
    message: user.message,
    meta: metaData.metaData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doUpdateUser: (obj, id) => dispatch(editUser(obj, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAdminUsers);
