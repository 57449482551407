import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import PropTypes from "prop-types";
// import Table from "./components/DataTable";
import AddFormInput from "./components/addFormInput";
import { addAppTranslation } from "../../redux/actions/appTranslationsActions";
import { connect } from "react-redux";
import Alert from "../../shared/components/Alert";
import { transformTransalationsForBackend } from "../../utils/dataTransformerForAPI";

class AddContacts extends React.Component {
  render() {
    // const { languages, genders, sect } = this.props.meta;
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">New App Translation </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Alert
                  color="danger"
                  visible={this.props.error !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Warning!</span>
                    {this.props.error}
                  </p>
                </Alert>
                <AddFormInput
                  language={this.props.meta ? this.props.meta.languages : []}
                  onSubmit={(values) => {
                    this.props.doAddAppTranslation(
                      {
                        ...values,
                        translations: transformTransalationsForBackend(
                          values.translations || {}
                        ),
                      },
                      this.props.history
                    );
                  }}
                  //   error={this.props.error}
                  // alert={}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ user, metaData }) => {
  return {
    loading: user.loading,
    error: user.error,
    meta: metaData.metaData,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddAppTranslation: (obj, props) =>
      dispatch(addAppTranslation(obj, props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContacts);
