// /* eslint-disable consistent-return */
// import React, { PureComponent } from 'react';
// import { TreeTable, TreeState } from 'cp-react-tree-table';

// import PropTypes from 'prop-types';

// export default class CollapsibleTable extends PureComponent {
// 	constructor(props) {
// 		super(props);
// 		console.log(props);
// 		this.state = {
// 			data: props.rows,
// 			treeValue: props.rows && props.rows.length && TreeState.create(props.rows) || null,
// 		};
// 	}
// 	static propTypes = {
// 		heads: PropTypes.arrayOf(
// 			PropTypes.shape({
// 				key: PropTypes.string,
// 				name: PropTypes.string,
// 				editable: PropTypes.bool,
// 				sortable: PropTypes.bool,
// 			})
// 		).isRequired,
// 		rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
// 		getActions: PropTypes.func,
// 	};
// 	handleOnExpandAll = () => {
// 		this.setState(state => {
// 			return {
// 				treeValue: TreeState.expandAll(state.treeValue),
// 			};
// 		});
// 	};
// 	handleOnCollapseAll = () => {
// 		this.setState(state => {
// 			return {
// 				treeValue: TreeState.collapseAll(state.treeValue),
// 			};
// 		});
// 	};
// 	static getDerivedStateFromProps(props, state) {
// 		console.log(props, state, state.data != props.rows);
// 		if (state.data != props.rows) {
// 			console.log(true);
// 			return {
// 				data: props.rows,
// 				treeValue: props.rows && props.rows.length && TreeState.create(props.rows) || null,
// 			};
// 		} else return null;
// 	}
// 	bindEvents = (events, ...args) =>
// 		Object.keys(events)
// 			.map(key => ({ [key]: ev => events[key](ev, ...args) }))
// 			.reduce(Object.assign);
// 	render() {
// 		const { treeValue, heads, getActions } = this.props;
// 		return (
// 			<React.Fragment>
// 				<button onClick={this.handleOnExpandAll}>Expand all</button>
// 				<button onClick={this.handleOnCollapseAll}>Collapse all</button>

// 				<TreeTable value={treeValue}>
// 					{heads.map(head => (
// 						<TreeTable.Column
// 							renderCell={row => (
// 								<span {...this.bindEvents(head.events, row)} className={head.cellClass}>
// 									{head.render ? head.render(row) : row.data[head.key]}
// 								</span>
// 							)}
// 							renderHeaderCell={() => <span>{head.name}</span>}
// 						/>
// 					))}
// 					{/* {getActions && (
// 						<TreeTable.Column
// 							renderCell={getActions}
// 							renderHeaderCell={() => <span>Actions</span>}
// 						/>
// 					)} */}
// 				</TreeTable>
// 			</React.Fragment>
// 		);
// 	}
// }

import React, { Component } from 'react';

import { TreeTable, TreeState } from 'cp-react-tree-table';

export default class DemoApp extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      data: props.rows,
      treeValue:
        (props.rows && props.rows.length && TreeState.create(props.rows)) ||
        null,
    };
  }

  treeTableRef = React.createRef();
  static getDerivedStateFromProps(props, state) {
    console.log(props, state, state.data != props.rows);
    if (state.data != props.rows) {
      console.log(true);
      return {
        data: props.rows,
        treeValue:
          (props.rows && props.rows.length && TreeState.create(props.rows)) ||
          null,
      };
    } else return null;
  }
  bindEvents = (events, ...args) =>
    Object.keys(events)
      .map((key) => ({ [key]: (ev) => events[key](ev, ...args) }))
      .reduce(Object.assign);
  render() {
    const { treeValue } = this.state;

    return (
      <div className="wrapper">
        <div className="controls">
          <button onClick={this.handleOnExpandAll}>Expand all</button>
          <button onClick={this.handleOnCollapseAll}>Collapse all</button>
        </div>
        {treeValue && (
          <TreeTable
            className="tree-table"
            headerHeight="32"
            height="400"
            value={treeValue}
            onChange={this.handleOnChange}
          >
            {/* <TreeTable.Column
                                        renderCell={this.renderIndexCell}
                                        renderHeaderCell={this.renderHeaderCell('Name')}
                                        basis="180px"
                                        grow="0"
                                    /> */}
            {this.props.heads.map((head) => (
              <TreeTable.Column
                renderCell={(row) => (
                  <span
                    {...((head.events && this.bindEvents(head.events, row)) ||
                      {})}
                    className={head.cellClass}
                  >
                    {head.render ? head.render(row) : row.data[head.key]}
                  </span>
                )}
                renderHeaderCell={() => <span>{head.name}</span>}
              />
            ))}
            {this.props.getActions && (
              <TreeTable.Column
                basis="120px"
                renderCell={this.props.getActions}
                renderHeaderCell={() => <span>Actions</span>}
              />
            )}
            {/* <TreeTable.Column
                                        renderCell={this.renderEditableCell}
                                        renderHeaderCell={this.renderHeaderCell('Contact person')}
                                    />
                                    <TreeTable.Column
                                        renderCell={this.renderEmployeesCell}
                                        renderHeaderCell={this.renderHeaderCell('Employees', false)}
                                    />
                                    <TreeTable.Column
                                        renderCell={this.renderExpensesCell}
                                        renderHeaderCell={this.renderHeaderCell('Expenses ($)', false)}
                                    /> */}
          </TreeTable>
        )}
      </div>
    );
  }

  handleOnChange = (newValue) => {
    console.log('newValue', newValue);
    this.setState({ treeValue: newValue });
  };

  handleOnExpandAll = () => {
    console.log('Expand all');
    this.setState((state) => {
      return {
        treeValue: TreeState.expandAll(state.treeValue),
      };
    });
  };

  handleOnCollapseAll = () => {
    console.log('Collapse all');
    this.setState((state) => {
      return {
        treeValue: TreeState.collapseAll(state.treeValue),
      };
    });
  };
}
