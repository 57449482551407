/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import DataPaginationTable from '../../../shared/components/table/DataPaginationTable';
import Pagination from '../../../shared/components/pagination/Pagination';
import Modal from '../../../shared/components/Modal';
import Alert from '../../../shared/components/Alert';

const filterRows = (originalRows, pageNumber, rowsOnPage = 10) => {
  const rowsFrom = rowsOnPage * (pageNumber - 1);
  const rowsTo = rowsFrom + rowsOnPage;
  return originalRows.slice(rowsFrom, rowsTo);
};

class DataTable extends PureComponent {
  constructor(props) {
    super(props);
    this.heads = [
      {
        key: 'id',
        name: 'Id',
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/app-translation/${args.rowId}`);
          },
        },
        width: 60,
      },
      {
        key: 'key',
        name: 'Key',
        sortable: true,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/app-translation/${args.rowId}`);
          },
        },
      },
      {
        key: 'value',
        name: 'Value',
        sortable: true,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/app-translation/${args.rowId}`);
          },
        },
      },
      {
        key: 'created_at',
        name: 'Created At',
        sortable: true,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/app-translation/${args.rowId}`);
          },
        },
        width: 120,
      },
      {
        key: 'updated_at',
        name: 'Updated At',
        sortable: true,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/app-translation/${args.rowId}`);
          },
        },
        width: 120,
      },
      {
        key: 'actions',
        name: 'Actions',
        cellClass: 'ActionCell',
      },
    ];
    const paramNumber = this.props.location.pathname.slice(24);
    const initialPageNumber = paramNumber ? Number(paramNumber) : 1;
    const initialRowsCount = 50;

    const originalRows = this.props.data;
    const currentPageRows = filterRows(
      this.props.data,
      initialPageNumber,
      initialRowsCount
    );

    this.state = {
      rows: originalRows,
      rowsToShow: currentPageRows,
      pageOfItems: initialPageNumber,
      itemsToShow: initialRowsCount,
      modal: false,
      id: null,
    };
  }

  nameActions = (row) => [
    {
      icon: (
        <Button
          style={{ marginRight: 10 }}
          size="sm"
          className="badge badge-success"
        >
          Destroy
        </Button>
      ),
      callback: (id) => {
        console.log('id', row, this.props.data);
        this.setState({ modal: true, id: row.id });
      },
    },
    {
      icon: (
        <Button
          style={{ marginRight: 10 }}
          size="sm"
          className="badge badge-success"
        >
          Edit
        </Button>
      ),
      callback: (id) => {
        this.props.history.push(`/admin/app-translation/${row.id}/edit`);
      },
    },
  ];

  onChangePage = (pageOfItems) => {
    const { rows, itemsToShow } = this.state;
    if (pageOfItems) {
      const rowsToShow = filterRows(rows, pageOfItems, itemsToShow);
      this.props.history.push(`/admin/app-translations/${pageOfItems}`)(
        () => undefined
      )({ rowsToShow, pageOfItems, itemsToShow, props: this.props });
      this.setState({ rowsToShow, pageOfItems });
    }
  };

  toggle = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  getCellActions = (column, row) => {
    const cellActions = {
      actions: this.nameActions(row),
    };
    return cellActions[column.key];
  };

  onSorting = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    };
    const { rows, pageOfItems, itemsToShow } = this.state;
    if (sortDirection !== 'NONE') {
      let sortRows = [...rows].sort(comparer);
      sortRows = filterRows(sortRows, pageOfItems, itemsToShow);
      this.setState({ rowsToShow: sortRows });
      return sortRows;
    }
    const sortRows = filterRows(rows, pageOfItems, itemsToShow);
    this.setState({ rowsToShow: sortRows });
    return sortRows;
  };
  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.rows) {
      return {
        rows: props.data,
        rowsToShow: filterRows(
          props.data,
          state.pageOfItems,
          state.itemsToShow
        ),
      };
    }
    return null;
  }
  render() {
    const { rows, itemsToShow, pageOfItems, rowsToShow } = this.state;
    const { entityName } = this.props;
    console.log({ pageOfItems });
    return (
      <Col md={12} lg={12}>
        <Modal
          open={this.state.modal}
          success={() => {
            this.props.success(this.state.id);
            this.setState({ modal: false });
          }}
          toggle={this.toggle}
          color="danger"
          title="Warning!"
          colored
          btn="Danger"
          message={`Are you sure you want to delete the program ?`}
        />
        <Card>
          <CardBody>
            <Alert
              color="danger"
              visible={this.props.error !== null ? true : false}
            >
              <p>
                <span className="bold-text">Error! </span>
                We can't Delete it Because its used
              </p>
            </Alert>
            <DataPaginationTable
              heads={this.heads}
              rows={rowsToShow}
              key={rows.length}
              onSorting={this.onSorting}
              getCellActions={this.getCellActions}
            />
            <Pagination
              itemsCount={rows.length}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withRouter(DataTable);
