import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from './components/DataTable';
import {
  getAdminUsers,
  deleteAdminUser,
} from '../../redux/actions/adminUsersActions';
import { connect } from 'react-redux';

class DataTable extends React.Component {
  componentDidMount() {
    this.props.doGetAdminUsers();
  }

  render() {
    console.log(this.props.adminUser);
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title"> Admin users </h3>
            {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
                information
          </h3> */}
          </Col>
          <Col style={{ paddingLeft: 50 }} md={2}>
            <Link
              to="/admin/add-admin-users"
              className="btn btn-primary products-list__btn-add"
            >
              New User
            </Link>
          </Col>
        </Row>
        <Row>
          <Table
            data={this.props.adminUsers || []}
            success={(id) => {
              this.props.doDeleteAdminUser(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ adminUser, login }) => {
  return {
    loading: adminUser.loading,
    error: adminUser.error,
    adminUsers: adminUser.adminUsers,
    adminUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAdminUsers: () => dispatch(getAdminUsers()),
    doDeleteAdminUser: (id) => dispatch(deleteAdminUser(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
