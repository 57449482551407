import {
    ATTENDANCES,
    ATTENDANCES_SUCCESS,
    ATTENDANCES_FAILED,
    ADD_ATTENDANCE,
    ADD_ATTENDANCE_SUCCESS,
    ADD_ATTENDANCE_FAILED,
    DELETE_ATTENDANCE,
    DELETE_ATTENDANCE_FAILED,
    DELETE_ATTENDANCE_SUCCESS,
    EDIT_ATTENDANCE,
    EDIT_ATTENDANCE_FAILED,
    EDIT_ATTENDANCE_SUCCESS,
  } from "../actions/attendancesActions";
  
  const initialState = {
    loading: false,
    attendances: [],
    message: null,
    data: null,
    error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case ATTENDANCES:
        return { ...state, loading: true, error: null };
      case ATTENDANCES_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          attendances: action.payload
        };
      case ATTENDANCES_FAILED:
        return { ...state, loading: false, error: action.payload };
      case ADD_ATTENDANCE:
        return { ...state, loading: true, error: null };
      case ADD_ATTENDANCE_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          attendances: [...state.attendances, action.payload]
        };
      case ADD_ATTENDANCE_FAILED:
        return { ...state, loading: false, error: action.error };
      case DELETE_ATTENDANCE:
        return { ...state, loading: true, error: null };
      case DELETE_ATTENDANCE_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          attendances: state.attendances.filter(
            item => item.id !== action.payload.id
          )
        };
      case DELETE_ATTENDANCE_FAILED:
        return { ...state, loading: false, error: action.error };
      case EDIT_ATTENDANCE:
        return { ...state, loading: true, error: null };
      case EDIT_ATTENDANCE_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          message: action.payload.message,
          attendances: state.attendances.map(
            item =>
              item.id == action.payload.data.id ? action.payload.data : item
          )
        };
      case EDIT_ATTENDANCE_FAILED:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  }
  