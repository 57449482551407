import React, { Component } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm, FieldArray } from "redux-form";
import Webservice from "../../../utils/networkApis";
import renderSelectField from "../../../shared/components/form/Select";
import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import renderDateTimePickerField from "../../../shared/components/form/DateTimePicker";
import TimetableIcon from "mdi-react/TimetableIcon";
// import validate from "./validate";
import PropTypes from "prop-types";
import renderTextEditor from "../../../shared/components/text-editor/reduxFormPort";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);
const renderTextArea = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <textarea {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

class AddItinerary extends Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    // error: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      languages: [],
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  onTranslateClick = (e) => {
    const title = this.title_no.value || this.title_en.value;
    const desc = this.desc_no.value || this.desc_en.value;
    const source = this.title_no.value ? "no" : "en";

    this.props.onTranslate([title, desc], source, "fa", (translations) => {
      if (translations.length) {
        this.props.change(this.title_fa.name, translations[0].translatedText);
        this.props.change(this.desc_fa.name, translations[1].translatedText);
      }
    });

    this.props.onTranslate([title, desc], source, "ar", (translations) => {
      if (translations.length) {
        this.props.change(this.title_ar.name, translations[0].translatedText);
        this.props.change(this.desc_ar.name, translations[1].translatedText);
      }
    });

    this.props.onTranslate([title, desc], source, "ur", (translations) => {
      if (translations.length) {
        this.props.change(this.title_ur.name, translations[0].translatedText);
        this.props.change(this.desc_ur.name, translations[1].translatedText);
      }
    });

    this.props.onTranslate(
      [title, desc],
      source,
      source === "no" ? "en" : "no",
      (translations) => {
        if (translations.length) {
          this.props.change(
            this[`title_${source === "no" ? "en" : "no"}`].name,
            translations[0].translatedText
          );
          this.props.change(
            this[`desc_${source === "no" ? "en" : "no"}`].name,
            translations[1].translatedText
          );
        }
      }
    );
  };

  render() {
    const { handleSubmit, reset, t } = this.props;
    const { showPassword, languages } = this.state;

    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Group</span>
          <div className="form__form-group-field">
            <Field
              name="group_id"
              component={renderSelectField}
              options={this.props.groups}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Place</span>
          <div className="form__form-group-field">
            <Field
              name="content_id"
              component={renderSelectField}
              options={this.props.places}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Time Zone</span>
          <div className="form__form-group-field">
            <Field
              name="zone"
              component={renderSelectField}
              options={this.props.zones}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Time</span>
          <div className="form__form-group-field">
            <Field name="time" component={renderDateTimePickerField} />
            <div className="form__form-group-icon">
              <TimetableIcon />
            </div>
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="has_attendance"
              component={renderCheckBoxField}
              type="checkbox"
              id="attendance"
              label="Has participants"
              defaultChecked
            />
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="has_evaluation"
              component={renderCheckBoxField}
              type="checkbox"
              id="evaluation"
              label="Has evaluation"
              defaultChecked
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Title</span>
          <div
            className="form__form-group-field"
            style={{
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {this.props.languages.map((item) => {
              return (
                <Col key={item.value} style={{ padding: 0 }} sm={12} md={5}>
                  <Field
                    name={`translations.${item.value}.title`}
                    component={renderField}
                    type="text"
                    placeholder={item.label}
                    ref={(ref) => (this[`title_${item.value}`] = ref)}
                  />
                </Col>
              );
            })}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Description</span>
          <div className="form__form-group-field wraped">
            {this.props.languages.map((item) => {
              return (
                <div
                  key={item.value}
                  style={{ display: "block", width: "100%", maxWidth: "100%" }}
                >
                  <Field
                    name={`translations.${item.value}.description`}
                    component={renderTextEditor}
                    type="text"
                    placeholder={item.label}
                    ref={(ref) => (this[`desc_${item.value}`] = ref)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {this.props.sendUpdateNotify && (
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="update_push_notify"
                component={renderCheckBoxField}
                type="checkbox"
                id="notify"
                label="Send update push notify"
              />
            </div>
          </div>
        )}
        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">
            Submit
          </Button>
          <Button type="button" onClick={reset}>
            Cancel
          </Button>
          <Button color="info" onClick={this.onTranslateClick}>
            Translate
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: "add_itinerary_form",
  initialValues: {
    group_id: "17",
  },
  // validate // a unique identifier for this form
})(AddItinerary);
