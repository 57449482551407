import Webservice from '../../utils/networkApis';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export function login(data, props) {
  return (dispatch) => {
    dispatch({ type: LOGIN });
    Webservice.login(data).then((resp) => {
      const response = resp.data;
      console.log('response', response);
      if (response.success) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('admin_groups', response.data.admin_groups);
        Webservice.setUser(response.data.token);
        props.history.replace('/admin');
        dispatch({ type: LOGIN_SUCCESS, payload: response });
      } else {
        dispatch({ type: LOGIN_FAILED, payload: response });
      }
    });
  };
}
