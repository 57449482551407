import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";
import LogIn from "../LogIn/index";
import AdminUsers from "../AdminUsers/index";
import AddAdminUsers from "../AddAdminUsers/index";
import EditAdminUsers from "../EditAdminUser/index";
import Users from "../Users/index";
import AddUsers from "../AddUsers/index";
import EditUsers from "../EditUser/index";
import Languages from "../Languages/index";
import AddLanguages from "../AddLanguages/index";
import EditLanguage from "../EditLanguages/index";
import LostReports from "../LostReports/index";
import EditLostReports from "../EditLostReports/index";
import Contacts from "../Contacts/index";
import AddContact from "../AddContacts/index";
import EditContact from "../AddContacts/edit";
import Guides from "../Guides/index";
import AddGuides from "../AddGuides/index";
import EditGuide from "../AddGuides/edit";
import Announcements from "../Announcements/index";
import AddAnnouncement from "../AddAnnouncement/index";
import EditAnnouncement from "../AddAnnouncement/edit";
import HelpMe from "../HelpMe/index";
import ShowHelpMe from "../HelpMe/show";
import EditHelpMe from "../HelpMe/edit";
import AppSettings from "../AppSettings/index";
import Show from "../AppSettings/show";
import AddAppSetting from "../AppSettings/add";
import Whiteboards from "../WhiteBoards/index";
import AddWhiteboard from "../WhiteBoards/add";
import EditWhiteboard from "../WhiteBoards/edit";
import ShowWhiteboard from "../WhiteBoards/show";
import PlacesHOC from "../Places/index";
import AddPlaces from "../Places/add";
import EditPlaces from "../Places/edit";
import Groups from "../Groups/index";
import GoogleMap from "../GoogleMap/index";
import Itineraries from "../Itineraries/index";
import AddItinerary from "../AddItinerary/index";
import EditItinerary from "../AddItinerary/edit";
import GroupItinerary from "../GroupItinerary/index";
import AppTranslations from "../AppTranslations/index";
import AddAppTranslation from "../AddAppTranslation/index";
import EditAppTranslations from "../AddAppTranslation/edit";
import Attendances from "../Attendances/index";
import Ratings from "../Ratings/index";
import ImportedUsers from "../ImportedUsers/index";
import ImportedGroups from "../ImportedGroups/index";
import ImportedHotels from "../ImportedHotels/index";
import ShowGuide from "../AddGuides/show";
import ShowContact from "../AddContacts/show";
import ShowAdminUser from "../AddAdminUsers/show";
import ShowUser from "../AddUsers/show";
import ShowLostReport from "../EditLostReports/show";
import ShowLanguage from "../AddLanguages/show";
import ShowGroups from "../Groups/show";
import ShowItinerary from "../AddItinerary/show";
import ShowAnnouncement from "../AddAnnouncement/show";
import ShowAppTranslation from "../AddAppTranslation/show";
import ShowContent from "../Places/show";
import { connect } from "react-redux";
import Assistances from "../Assistances";

const AdminComponent = ({ metaData }) => {
  console.log({ metaData });
  const { is_super_admin } = metaData
    ? metaData.currentUser
    : { is_super_admin: null };
  console.log(is_super_admin);
  return (
    <Switch>
      <Route path="/admin/googleMap" component={GoogleMap} />
      {is_super_admin && (
        <Route path="/admin/admin-users" component={AdminUsers} />
      )}
      <Route path="/admin/admin-user/:id" component={ShowAdminUser} />
      <Route path="/admin/add-admin-users" component={AddAdminUsers} />
      <Route path="/admin/edit-admin-users/:id" component={EditAdminUsers} />
      <Route path="/admin/users" component={Users} />
      <Route path="/admin/user/:id" component={ShowUser} />
      <Route path="/admin/add-users" component={AddUsers} />
      <Route path="/admin/edit-users/:id" component={EditUsers} />
      {is_super_admin && (
        <Route path="/admin/languages" component={Languages} />
      )}
      <Route path="/admin/language/:id" component={ShowLanguage} />
      <Route path="/admin/add-languages" component={AddLanguages} />
      <Route path="/admin/edit-language/:id" component={EditLanguage} />
      <Route path="/admin/lost-reports" component={LostReports} />
      <Route path="/admin/lost-report/:id" component={ShowLostReport} />
      <Route path="/admin/lost-report/:id/edit" component={EditLostReports} />
      <Route path="/admin/contacts" component={Contacts} />
      <Route path="/admin/contact/:id" exact component={ShowContact} />
      <Route path="/admin/add-contacts" component={AddContact} />
      <Route path="/admin/contact/:id/edit" component={EditContact} />
      {is_super_admin && <Route path="/admin/guides" component={Guides} />}
      <Route path="/admin/guide/:id" exact component={ShowGuide} />
      <Route path="/admin/guide/:id/edit" exact component={EditGuide} />
      <Route path="/admin/new-guide" component={AddGuides} />
      {is_super_admin && (
        <Route
          path="/admin/contents/all"
          component={() => <PlacesHOC title="Contents" entityName="content" />}
        />
      )}
      {is_super_admin && (
        <Route path="/admin/content/:id" exact component={ShowContent} />
      )}
      <Route path="/admin/add-content" exact component={AddPlaces} />
      <Route path="/admin/content/:id/edit" exact component={EditPlaces} />
      <Route path="/admin/announcements" component={Announcements} />
      <Route
        path="/admin/announcement/:id"
        exact
        component={ShowAnnouncement}
      />
      <Route path="/admin/add-announcement" component={AddAnnouncement} />
      <Route path="/admin/announcement/:id/edit" component={EditAnnouncement} />
      {is_super_admin && (
        <Route path="/admin/app-settings" component={AppSettings} />
      )}
      <Route path="/admin/app-setting/:id" component={Show} />
      <Route path="/admin/add-app-settings" component={AddAppSetting} />
      <Route path="/admin/helpmes" component={HelpMe} />
      <Route path="/admin/helpme/:id" exact component={ShowHelpMe} />
      <Route path="/admin/helpme/:id/edit" exact component={EditHelpMe} />
      <Route path="/admin/whiteboards" component={Whiteboards} />
      <Route path="/admin/whiteboard/:id" exact component={ShowWhiteboard} />
      <Route path="/admin/add-whiteboard" component={AddWhiteboard} />
      <Route path="/admin/whiteboard/:id/edit" component={EditWhiteboard} />
      <Route path="/admin/itineraries" component={Itineraries} />
      <Route path="/admin/itinerary/new" component={AddItinerary} />
      <Route path="/admin/itinerary/:id/edit" component={EditItinerary} />
      <Route path="/admin/itinerary/:id" component={ShowItinerary} />
      <Route path="/admin/groups/:id/itineraries" component={GroupItinerary} />
      {is_super_admin && (
        <Route path="/admin/app-translations" component={AppTranslations} />
      )}
      <Route path="/admin/app-translations/:id" component={AppTranslations} />
      <Route
        exact
        path="/admin/app-translation/:id"
        component={ShowAppTranslation}
      />
      <Route
        path="/admin/app-translation/:id/edit"
        exact
        component={EditAppTranslations}
      />
      <Route path="/admin/add-app-translation" component={AddAppTranslation} />
      {is_super_admin && (
        <Route path="/admin/attendances" component={Attendances} exact />
      )}
      {is_super_admin && (
        <Route path="/admin/attendances/:id" component={Attendances} exact />
      )}
      {is_super_admin && (
        <Route path="/admin/attendances/:groupId/:id" component={Attendances} />
      )}
      {is_super_admin && (
        <Route path="/admin/ratings" component={Ratings} exact />
      )}
      {is_super_admin && (
        <Route path="/admin/ratings/:id" component={Ratings} exact />
      )}
      {is_super_admin && (
        <Route path="/admin/ratings/:groupId/:id" component={Ratings} />
      )}
      {is_super_admin && (
        <Route path="/admin/imported-users" component={ImportedUsers} />
      )}
      {is_super_admin && (
        <Route path="/admin/imported-groups" component={ImportedGroups} />
      )}
      {is_super_admin && (
        <Route path="/admin/imported-hotels" component={ImportedHotels} />
      )}
      <Route path="/admin/groups" component={Groups} />
      <Route path="/admin/group/:id" component={ShowGroups} />
      {is_super_admin && (
        <Route
          path="/admin/contents/airport"
          component={() => (
            <PlacesHOC title="Airports" type="Airport" entityName="Airport" />
          )}
        />
      )}
      {is_super_admin && (
        <Route
          path="/admin/contents/gathering"
          component={() => (
            <PlacesHOC
              title="Gatherings"
              type="Gathering"
              entityName="Gathering"
            />
          )}
        />
      )}
      {is_super_admin && (
        <Route
          path="/admin/contents/hotel"
          component={() => (
            <PlacesHOC title="Hotels" type="Hotel" entityName="Hotel" />
          )}
        />
      )}
      {is_super_admin && (
        <Route
          path="/admin/contents/restaurant"
          component={() => (
            <PlacesHOC
              title="Restaurant"
              type="Restaurant"
              entityName="Restaurant"
            />
          )}
        />
      )}
      {is_super_admin && (
        <Route
          path="/admin/contents/market"
          component={() => (
            <PlacesHOC title="Markets" type="Market" entityName="Market" />
          )}
        />
      )}
      {is_super_admin && (
        <Route
          path="/admin/contents/shrine"
          component={() => (
            <PlacesHOC title="Shrines" type="Shrine" entityName="Shrine" />
          )}
        />
      )}
      {is_super_admin && (
        <Route
          path="/admin/contents/tourism"
          component={() => (
            <PlacesHOC title="Tourisms" type="Tourism" entityName="Tourism" />
          )}
        />
      )}
      {is_super_admin && (
        <Route path="/admin/assistances" component={Assistances} />
      )}
    </Switch>
  );
};
const Admin = connect(({ adminUser, metaData }) => ({
  metaData: metaData.metaData,
}))(AdminComponent);

const WrappedRoutes = ({ metaData }) => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/admin" component={() => <Admin metaData={metaData} />} />
    </div>
  </div>
);

class Router extends React.Component {
  render() {
    return (
      <MainWrapper>
        <main>
          <Switch>
            <Route exact path="/" component={LogIn} />
            <Route exact path="/log_in" component={LogIn} />
            <PrivateRoute
              path="/"
              component={() => <WrappedRoutes metaData={this.props.metaData} />}
            />
          </Switch>
        </main>
      </MainWrapper>
    );
  }
}
export default Router;
