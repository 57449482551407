import {
  IMPORTED_GROUPS,
  IMPORTED_GROUPS_FAILED,
  IMPORTED_GROUPS_SUCCESS,
  ADD_IMPORTED_GROUP,
  ADD_IMPORTED_GROUP_FAILED,
  ADD_IMPORTED_GROUP_SUCCESS,
  DELETE_IMPORTED_GROUP,
  DELETE_IMPORTED_GROUP_FAILED,
  DELETE_IMPORTED_GROUP_SUCCESS,
  EDIT_IMPORTED_GROUP,
  EDIT_IMPORTED_GROUP_FAILED,
  EDIT_IMPORTED_GROUP_SUCCESS
} from "../actions/importedGroupsActions";

const initialState = {
  loading: false,
  importedGroups: [],
  message: null,
  data: null,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case IMPORTED_GROUPS:
      return { ...state, loading: true, error: null };
    case IMPORTED_GROUPS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        importedGroups: action.payload
      };
    case IMPORTED_GROUPS_FAILED:
      return { ...state, loading: false, error: action.payload };
    case ADD_IMPORTED_GROUP:
      return { ...state, loading: true, error: null };
    case ADD_IMPORTED_GROUP_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        importedGroups: [...state.importedGroups, action.payload]
      };
    case ADD_IMPORTED_GROUP_FAILED:
      return { ...state, loading: false, error: action.error };
    case DELETE_IMPORTED_GROUP:
      return { ...state, loading: true, error: null };
    case DELETE_IMPORTED_GROUP_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        importedGroups: state.importedGroups.filter(
          item => item.id !== action.payload.id
        )
      };
    case DELETE_IMPORTED_GROUP_FAILED:
      return { ...state, loading: false, error: action.error };
    case EDIT_IMPORTED_GROUP:
      return { ...state, loading: true, error: null };
    case EDIT_IMPORTED_GROUP_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        message: action.payload.message,
        importedGroups: state.importedGroups.map(
          item =>
            item.id == action.payload.data.id ? action.payload.data : item
        )
      };
    case EDIT_IMPORTED_GROUP_FAILED:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
}
