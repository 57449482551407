import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AddAnnouncementForm from "./components/addAnnouncementForm";
import { connect } from "react-redux";
import webservice from "../../utils/networkApis";
import {
  transformTransalationsFromBackend,
  transformTransalationsForBackend,
} from "../../utils/dataTransformerForAPI";
import Alert from "../../shared/components/Alert";
import { editAnnouncement } from "../../redux/actions/announcementsActions";
import { translateTo } from "../../redux/actions/googleTranslation";
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};
class EditAnnouncement extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null,
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    webservice.getOneAnnouncement(id).then((resp) => {
      let data = resp.data.data;
      console.log(data);
      data = {
        ...data,
        translations: transformTransalationsFromBackend(
          data ? data.translations : []
        ),
      };

      this.setState({ initialValues: data, loading: false });
    });
  }

  componentDidUpdate() {
    scrollToTop();
  }

  onTranslate = (terms, source, target, callback) => {
    this.props.doTranslate(terms, source, target, () => {
      callback(this.props.translations);
    });
  };

  render() {
    const { loading, initialValues } = this.state;

    const { error } = this.props;
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Edit Message </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <Alert
                color="success"
                visible={this.props.message !== null ? true : false}
              >
                <p>
                  <span className="bold-text">Success </span>
                  {this.props.message}
                </p>
              </Alert>
              <CardBody>
                {loading ? (
                  <div className={`load`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path
                          fill="#4ce1b6"
                          d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <AddAnnouncementForm
                    _error={error}
                    initialValues={initialValues}
                    languages={this.props.meta ? this.props.meta.languages : []}
                    groups={this.props.meta ? this.props.meta.groups : []}
                    isEditing={true}
                    onTranslate={this.onTranslate}
                    onSubmit={(values) => {
                      this.props.doEditAnnouncement(
                        {
                          ...values,
                          translations: transformTransalationsForBackend(
                            values.translations || {}
                          ),
                        },
                        this.props.match.params.id
                      );
                      const id = this.props.match.params.id;
                      this.props.history.push(`/admin/announcement/${id}`);
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ announcement, metaData, googleTranslation }) => {
  return {
    loading: announcement.loading || googleTranslation.loading,
    error: announcement.error || googleTranslation.error,
    contents: announcement.contents,
    message: announcement.message || googleTranslation.message,
    meta: metaData.metaData,
    translations: googleTranslation.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doEditAnnouncement: (obj, id) => dispatch(editAnnouncement(obj, id)),
    doTranslate: (terms, source, target, cb) =>
      dispatch(translateTo(terms, source, target, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAnnouncement);
