/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import RenderFile from "../RenderFile";

class FileInputField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    value: null,
  };

  render() {
    const { onChange, name, value, multiple, accept } = this.props;
    let values = (multiple ? value : [value]) || [];
    return (
      <div className="form__form-group-file">
        {values
          .filter((v) => v.path)
          .map((v) => (
            <RenderFile style={{ maxWidth: 300 }} {...v} />
          ))}
        <label htmlFor={name}>Choose the file</label>
        <span>{values.map((v) => v.name).join()}</span>
        <input
          type="file"
          name={name}
          multiple={multiple}
          accept={accept}
          id={name}
          onChange={(e) => {
            e.preventDefault();
            // convert files to an array
            const files = [...e.target.files];
            onChange(multiple ? files : files[0]);
          }}
        />
      </div>
    );
  }
}

const renderFileInputField = (props) => {
  const { input, meta, multiple, type = "text", accept } = props;
  return (
    <div className="form__form-group-input-wrap">
      <FileInputField
        {...input}
        type={type}
        multiple={multiple}
        accept={accept}
      />
      {meta.touched && meta.error && (
        <span className="form__form-group-error">{meta.error}</span>
      )}
    </div>
  );
};

renderFileInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderFileInputField.defaultProps = {
  meta: null,
};

export default renderFileInputField;
