import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Table from './components/DataTable';
import {
  getContacts,
  deleteContact,
} from '../../redux/actions/contactsActions';
import { connect } from 'react-redux';
import Loading from '../../shared/components/Loading';

class Contacts extends React.Component {
  componentDidMount() {
    this.props.doGetContacts();
  }

  render() {
    //  console.log(this.props.contacts)
    const contactsData = this.props.contacts;
    //.map(item => {  //  return {
    //    id: item.contact_id,
    //    group_id: item.group_id,
    //    title: item.title,
    //    phone: item.phone,
    //    group: item.group ? item.group.leader : ""
    //  };
    //});

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Contacts</h3>
          </Col>
          <Col md={2}>
            <Link
              to="/admin/add-contacts"
              className="btn btn-primary products-list__btn-add"
            >
              New Contact
            </Link>
          </Col>
        </Row>
        <Row>
          {this.props.loading && (
            <Loading loading={!this.props.loading ? true : false} />
          )}

          <Table
            refreshData={() => this.props.doGetContacts()}
            meta={this.props.meta}
            data={contactsData || []}
            error={this.props.error}
            success={(id) => {
              this.props.doDeleteContact(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ contact, metaData }) => {
  return {
    loading: contact.loading,
    error: contact.error,
    contacts: contact.contacts,
    meta: metaData.metaData,

    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetContacts: () => dispatch(getContacts()),
    doDeleteContact: (id) => dispatch(deleteContact(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
