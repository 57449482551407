import React, { useEffect } from 'react';
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { editAdminUser } from '../../redux/actions/adminUsersActions';
import Alert from '../../shared/components/Alert';
import Webservice from '../../utils/networkApis';
import { connect } from 'react-redux';
import renderMultiSelectField from '../../shared/components/form/MultiSelect';
import { Field, reduxForm } from 'redux-form';
import RadioButton from '../../shared/components/form/CheckBox';
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

class EditAdminUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      encrypted_password: '',
      groups: [],
      is_super_admin: false,
      //is_super_adminEdit: false
    };
  }
  componentDidMount() {
    const id = this.props.match.params.id;
    Webservice.getOneAdminUser(id).then((resp) => {
      const data = resp.data.data;
      //console.log(data, this.props)
      //var arr = []
      //if (data.groups.length && this.props.meta) {
      //	let groups = data.groups
      //	for (var i = 0; i < groups.length; i++) {
      //		arr.push({
      //			value: groups[i],
      //			label: this.props.meta.groupsKeys[groups[i]]
      //		})
      //	}
      //	console.log(arr)
      //}
      this.setState({
        user: data,
        email: data.email,
        groups: data.groups,
        encrypted_password: data.encrypted_password,
        is_super_admin: data.is_super_admin ? false : true,
      });
    });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  editUser = (e) => {
    e.preventDefault();
    //console.log(e)
    const id = this.props.match.params.id;

    this.props.doUpdateAdminUsers(this.state, id);
    //const id = this.props.match.params.id
    this.props.history.push(`/admin/admin-user/${id}`);
  };
  componentDidUpdate() {
    scrollToTop();
  }
  render() {
    console.log(this.state);
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title"> Edit Admin User </h3>
            {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
                information
          </h3> */}
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Edit</h5>
                </div>
                <Alert
                  color="success"
                  visible={this.props.message !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Success </span>
                    {this.props.message}
                  </p>
                </Alert>
                <form
                  onSubmit={this.editUser}
                  className="form form--horizontal"
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={this.changeHandler}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Super Admin</span>
                    <div className="form__form-group-field">
                      <Field
                        name="is_super_admin"
                        value={this.state.is_super_admin}
                        defaultChecked={this.state.is_super_admin}
                        checked={this.state.is_super_admin}
                        component={RadioButton}
                        onChange={(ev) => {
                          this.setState(
                            {
                              is_super_admin: this.state.is_super_admin
                                ? false
                                : true,
                            },
                            () => {
                              console.log(
                                'changeee',
                                this.state.is_super_admin
                              );
                            }
                          );
                        }}
                        label={'yes'}
                        type="text"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  {!this.state.is_super_admin && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">Groups</span>
                      <div className="form__form-group-field">
                        <Field
                          name="groups"
                          //defaultValue={this.state.groups}
                          newValueMethod={this.state.groups}
                          value={this.state.groups}
                          component={renderMultiSelectField}
                          onChange={(e) => this.setState({ groups: e })}
                          options={
                            this.props.meta ? this.props.meta.groups : []
                          }
                        />
                      </div>
                    </div>
                  )}
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Update
                    </Button>
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ adminUser, metaData }) => {
  return {
    loading: adminUser.loading,
    error: adminUser.error,
    adminUsers: adminUser.adminUsers,
    message: adminUser.message,
    meta: metaData.metaData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doUpdateAdminUsers: (obj, id) => dispatch(editAdminUser(obj, id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'add_admin_user_form',
    //validate // a unique identifier for this form
  })(EditAdminUsers)
);
