import React, { PureComponent } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Table from "./components/Datatable";
import { connect } from "react-redux";
import {
  getHelpme,
  // deleteWhiteboard,
} from "../../redux/actions/helpmeActions";
import moment from "moment";

class HelpMe extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // data: Whiteboards._parseData(props.announcements || [], props.meta),
      helpme: [],
    };
  }

  componentDidMount() {
    this.props.doGetHelpMe();
  }

  render() {
    console.log("helpme", this.props.helpme);

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Help ME</h3>
          </Col>
          {/* <Col md={2}>
            <Link
              to="/admin/add-whiteboard"
              className="btn btn-primary products-list__btn-add"
            >
              
            </Link>
          </Col> */}
        </Row>
        <Row>
          <Table
            // entityName={entityName}
            meta={this.state.meta}
            // refreshData={() => this.props.doGetWhiteboards()}
            data={(this.props.helpme || []).map((item) => ({
              ...item,
              createdAt: moment(item.createdAt).format("M/DD/YYYY HH:mm"),
            }))}
            error={this.props.error}
            success={(id) => {
              console.log("id", id);
              // this.props.doDeleteWhiteboard(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ helpme }) => {
  return {
    loading: helpme.loading,
    error: helpme.error,
    helpme: helpme.helpme,
    // meta: metaData.metaData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetHelpMe: () => dispatch(getHelpme()),
    // doDeleteWhiteboard: id => dispatch(deleteWhiteboard(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpMe);
