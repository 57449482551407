import {
    GROUPS,
    GROUPS_FAILED,
    GROUPS_SUCCESS,
    ADD_GROUP,
    ADD_GROUP_FAILED,
    ADD_GROUP_SUCCESS,
    EDIT_GROUP,
    EDIT_GROUP_FAILED,
    EDIT_GROUP_SUCCESS,
    DELETE_GROUP,
    DELETE_GROUP_FAILED,
    DELETE_GROUP_SUCCESS
  } from "../actions/groupsActions";
  
  const initialState = {
    loading: false,
    groups: [],
    message: null,
    data: null,
    error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case GROUPS:
        return { ...state, loading: true, error: null };
      case GROUPS_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          groups: action.payload
        };
      case GROUPS_FAILED:
        return { ...state, loading: false, error: action.payload };
      case ADD_GROUP:
        return { ...state, loading: true, error: null };
      case ADD_GROUP_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          groups: [...state.groups, action.payload]
        };
      case ADD_GROUP_FAILED:
        return { ...state, loading: false, error: action.error };
      case DELETE_GROUP:
        return { ...state, loading: true, error: null };
      case DELETE_GROUP_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          groups: state.groups.filter(
            item => item.id !== action.payload.id
          )
        };
      case DELETE_GROUP_FAILED:
        return { ...state, loading: false, error: action.error };
      case EDIT_GROUP:
        return { ...state, loading: true, error: null };
      case EDIT_GROUP_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          message: action.payload.message,
          groups: state.groups.map(
            item =>
              item.id == action.payload.data.id ? action.payload.data : item
          )
        };
      case EDIT_GROUP_FAILED:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  }
  