import {
    HELPME,
    HELPME_FAILED,
    HELPME_SUCCESS,
    DELETE_HELPME,
    DELETE_HELPME_FAILED,
    DELETE_HELPME_SUCCESS,
    EDIT_HELPME,
    EDIT_HELPME_FAILED,
    EDIT_HELPME_SUCCESS
  } from "../actions/helpmeActions";
  
  const initialState = {
    loading: false,
    helpme: [],
    message: null,
    data: null,
    error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case HELPME:
        return { ...state, loading: true, error: null };
      case HELPME_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          helpme: action.payload
        };
      case HELPME_FAILED:
        return { ...state, loading: false, error: action.payload };
    //   case ADD_HELPME:
    //     return { ...state, loading: true, error: null };
    //   case ADD_HELPME_SUCCESS:
    //     return {
    //       ...state,
    //       error: null,
    //       loading: false,
    //       helpme: [...state.helpme, action.payload]
    //     };
    //   case ADD_HELPME_FAILED:
    //     return { ...state, loading: false, error: action.error };
      case DELETE_HELPME:
        return { ...state, loading: true, error: null };
      case DELETE_HELPME_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          helpme: state.helpme.filter(
            item => item.id !== action.payload.id
          )
        };
      case DELETE_HELPME_FAILED:
        return { ...state, loading: false, error: action.error };
      case EDIT_HELPME:
        return { ...state, loading: true, error: null };
      case EDIT_HELPME_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          message: action.payload.message,
          helpme: state.helpme.map(
            item =>
              item.id == action.payload.data.id ? action.payload.data : item
          )
        };
      case EDIT_HELPME_FAILED:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  }
  