import moment from "moment-timezone";

import locales from "./zones.json";

const getZones = () =>
  Object.keys(locales.zones).map((item) => ({ value: item, label: item }));

const changeTimezoneOfDateBy = (date, zone) => {
  debugger;
  const formattedDate = moment(date).format("YYYY-MM-DD HH:mm");
  const newFormat = moment.tz(formattedDate, zone).format();
  return moment(newFormat).format();
};

const replaceZoneGMTByLocalGMT = (date, zone) => {
  const zoneGMT = moment.tz.zone(zone).abbrs;
  debugger;
  const tempDate = moment(date).toDate().toString();
  return tempDate.replace(
    tempDate.substring(tempDate.indexOf("GMT")),
    `GMT${zoneGMT}`
  );
};

const formatDate = (date, zone) =>
  zone
    ? moment(date).tz(zone).format("MM/DD/YYYY HH:mm")
    : moment(date).format("MM/DD/YYYY HH:mm");

export {
  getZones,
  replaceZoneGMTByLocalGMT,
  formatDate,
  changeTimezoneOfDateBy,
};
