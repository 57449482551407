import React from "react";

import TextEditor from "./TextEditor";

const renderTextEditor = ({ input, placeholder, meta: { touched, error } }) =>
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
      {placeholder && <span>{placeholder}</span>}
    <TextEditor {...input} />
    {touched &&
      error &&
      <span className="form__form-group-error">
        {error}
      </span>}
  </div>;

export default renderTextEditor;
