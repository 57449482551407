import Webservice from "../../utils/networkApis";

export const APP_SETTINGS = "APP_SETTINGS";
export const APP_SETTINGS_SUCCESS = "APP_SETTINGS_SUCCESS";
export const APP_SETTINGS_FAILED = "APP_SETTINGS_FAILED";

export const ADD_APP_SETTING = "ADD_APP_SETTING";
export const ADD_APP_SETTING_SUCCESS = "ADD_APP_SETTING_SUCCESS";
export const ADD_APP_SETTING_FAILED = "ADD_APP_SETTING_FAILED";

export const EDIT_APP_SETTING = "EDIT_APP_SETTING";
export const EDIT_APP_SETTING_SUCCESS = "EDIT_APP_SETTING_SUCCESS";
export const EDIT_APP_SETTING_FAILED = "EDIT_APP_SETTING_FAILED";

export const DELETE_APP_SETTING = "DELETE_APP_SETTING";
export const DELETE_APP_SETTING_SUCCESS = "DELETE_APP_SETTING_SUCCESS";
export const DELETE_APP_SETTING_FAILED = "DELETE_APP_SETTING_FAILED";

export function getAppSettings() {
  return dispatch => {
    dispatch({ type: APP_SETTINGS });
    Webservice.getAppSettings().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: APP_SETTINGS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: APP_SETTINGS_FAILED, payload: response.error });
      }
    });
  };
}


export function addAppSetting(data, history) {
  return dispatch => {
    dispatch({ type: ADD_APP_SETTING });
    Webservice.addAppSetting(data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_APP_SETTING_SUCCESS, payload: response.data });
        history.push(`/admin/app-setting/${response.data.id}`);
      } else {
        dispatch({ type: ADD_APP_SETTING_FAILED, error: response.error });
      }
    });
  };
}

export function editAppSetting(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_APP_SETTING });
    Webservice.editAppSetting(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_APP_SETTING_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_APP_SETTING_FAILED, error: response.error });
      }
    });
  };
}

export function deleteAppSetting(id) {
  return dispatch => {
    dispatch({ type: DELETE_APP_SETTING });
    Webservice.deleteAppSetting(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_APP_SETTING_SUCCESS,
          payload: { id }
        });
      } else {
        dispatch({ type: DELETE_APP_SETTING_FAILED, error: response.error });
      }
    });
  };
}
