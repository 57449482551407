import React from "react";
import AddPlaceForm from "./components/Form/index";
import { addContent, editContent } from "../../redux/actions/contentsActions";
import { translateTo } from "../../redux/actions/googleTranslation";
import { connect } from "react-redux";
import {
  transformTransalationsForBackend,
  transformTransalationsFromBackend,
} from "../../utils/dataTransformerForAPI";
import webservice from "../../utils/networkApis";

class AddPlaces extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null,
  };
  componentDidMount() {
    const id = this.props.match.params.id;
    webservice.getOneContent(id).then((resp) => {
      let data = resp.data.data;
      data = {
        ...data,
        translations: transformTransalationsFromBackend(
          data.translations || []
        ),
      };
      this.setState({ initialValues: data, loading: false });
    });
  }

  onTranslate = (terms, source, target, callback) => {
    this.props.doTranslate(terms, source, target, () => {
      callback(this.props.translations);
    });
  };

  render() {
    const { loading, initialValues } = this.state;
    const { error } = this.props;
    return loading ? (
      <div className={`load`}>
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path
              fill="#4ce1b6"
              d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
            />
          </svg>
        </div>
      </div>
    ) : (
      <AddPlaceForm
        heading={
          "Edit " +
          ((initialValues.translations["en"] &&
            initialValues.translations["en"].title) ||
            "Place")
        }
        _error={error}
        isEditing
        initialValues={initialValues}
        languages={this.props.meta ? this.props.meta.languages : []}
        onTranslate={this.onTranslate}
        onSubmit={(values) => {
          this.props.doEditContent(
            {
              ...values,
              translations: transformTransalationsForBackend(
                values.translations || {}
              ),
            },
            this.props.match.params.id
          );
          const id = this.props.match.params.id;
          this.props.history.push(`/admin/content/${id}`);
        }}
      />
    );
  }
}

const mapStateToProps = ({ content, metaData, googleTranslation }) => {
  return {
    loading: content.loading || googleTranslation.loading,
    error: content.editError || googleTranslation.error || null,
    contents: content.contents,
    meta: metaData.metaData,
    translations: googleTranslation.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doEditContent: (obj, id) => dispatch(editContent(obj, id)),
    doTranslate: (terms, source, target, cb) =>
      dispatch(translateTo(terms, source, target, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlaces);
