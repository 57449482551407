import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Table from './components/DataTable';
import { connect } from 'react-redux';
import {
  getImportedGroups,
  deleteImportedGroup,
} from '../../redux/actions/importedGroupsActions';

class ImportedGroups extends PureComponent {
  componentDidMount() {
    this.props.doGetImportedGroup();
  }

  render() {
    // const data = this.props.attendances.map(item => {
    //   return {
    //     user: item.User ? `${item.User.first_name} ${item.User.last_name}` : "",
    //     itinerary: item.title,
    //     attending: item.attending ? "true" : "false",
    //     rating: item.rating ? item.rating : "-"
    //   };
    // });

    console.log('importedGroups', this.props.importedGroups);

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Imported Groups</h3>
          </Col>
        </Row>
        <Row>
          <Table
            // entityName={entityName}
            data={this.props.importedGroups || []}
            error={this.props.error}
            success={(id) => {
              this.props.doDeleteImportedGroup(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ importedGroup }) => {
  return {
    loading: importedGroup.loading,
    error: importedGroup.error,
    importedGroups: importedGroup.importedGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetImportedGroup: () => dispatch(getImportedGroups()),
    doDeleteImportedGroup: (id) => dispatch(deleteImportedGroup(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportedGroups);
