import React, { PureComponent, Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Table from "./components/Datatable";
import { connect } from "react-redux";
import {
  getAppSettings,
  deleteAppSetting,
} from "../../redux/actions/appSettingsActions";
import { RunReplications } from "./components/RunReplication";

class AppSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: Whiteboards._parseData(props.announcements || [], props.meta),
      appSetting: [],
    };
  }

  componentDidMount() {
    this.props.doGetAppSetting();
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md={4}>
            <h3 className="page-title">App Settings</h3>
          </Col>
          <Col md={8}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <RunReplications />
              <Link
                to="/admin/add-app-settings"
                className="btn btn-primary products-list__btn-add"
              >
                Add AppSettings
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Table
            // entityName={entityName}
            meta={this.state.meta}
            refreshData={() => this.props.doGetAppSetting()}
            data={this.props.appSetting || []}
            error={this.props.error}
            success={(id) => {
              this.props.doDeleteAppSetting(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ appSetting }) => {
  return {
    loading: appSetting.loading,
    error: appSetting.error,
    appSetting: appSetting.appSettings,
    // meta: metaData.metaData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAppSetting: () => dispatch(getAppSettings()),
    doDeleteAppSetting: (id) => dispatch(deleteAppSetting(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSetting);
