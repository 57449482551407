import Webservice from "../../utils/networkApis";

export const IMPORTED_GROUPS = "IMPORTED_GROUPS";
export const IMPORTED_GROUPS_SUCCESS = "IMPORTED_GROUPS_SUCCESS";
export const IMPORTED_GROUPS_FAILED = "IMPORTED_GROUPS_FAILED";

export const ADD_IMPORTED_GROUP = "ADD_IMPORTED_GROUP";
export const ADD_IMPORTED_GROUP_SUCCESS = "ADD_IMPORTED_GROUP_SUCCESS";
export const ADD_IMPORTED_GROUP_FAILED = "ADD_IMPORTED_GROUP_FAILED";

export const EDIT_IMPORTED_GROUP = "EDIT_IMPORTED_GROUP";
export const EDIT_IMPORTED_GROUP_SUCCESS = "EDIT_IMPORTED_GROUP_SUCCESS";
export const EDIT_IMPORTED_GROUP_FAILED = "EDIT_IMPORTED_GROUP_FAILED";

export const DELETE_IMPORTED_GROUP = "DELETE_IMPORTED_GROUP";
export const DELETE_IMPORTED_GROUP_SUCCESS = "DELETE_IMPORTED_GROUP_SUCCESS";
export const DELETE_IMPORTED_GROUP_FAILED = "DELETE_IMPORTED_GROUP_FAILED";

export function getImportedGroups() {
  return dispatch => {
    dispatch({ type: IMPORTED_GROUPS });
    Webservice.getImportedGroups().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: IMPORTED_GROUPS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: IMPORTED_GROUPS_FAILED, payload: response.error });
      }
    });
  };
}

export function addImportedGroup(data, history) {
  return dispatch => {
    dispatch({ type: ADD_IMPORTED_GROUP });
    Webservice.addImportedGroup(data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_IMPORTED_GROUP_SUCCESS, payload: response.data });
        history.push("/admin/importedGroups");
      } else {
        dispatch({ type: ADD_IMPORTED_GROUP_FAILED, error: response.error });
      }
    });
  };
}

export function editImportedGroup(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_IMPORTED_GROUP });
    Webservice.editImportedGroup(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_IMPORTED_GROUP_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_IMPORTED_GROUP_FAILED, error: response.error });
      }
    });
  };
}

export function deleteImportedGroup(id) {
  return dispatch => {
    dispatch({ type: DELETE_IMPORTED_GROUP });
    Webservice.deleteImportedGroup(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_IMPORTED_GROUP_SUCCESS,
          payload: { id }
        });
      } else {
        dispatch({ type: DELETE_IMPORTED_GROUP_FAILED, error: response.error });
      }
    });
  };
}
