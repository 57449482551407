import React, { Component } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm, Fields } from "redux-form";
import Webservice from "../../../utils/networkApis";
import renderSelectField from "../../../shared/components/form/Select";
import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import renderFileInputField from "../../../shared/components/form/FileInput";
import renderMultiSelectField from "../../../shared/components/form/MultiSelect";
import LeafletMap from "../../GoogleMap/components/BasicMap";
// import validate from "./validate";
import PropTypes from "prop-types";
import renderTextEditor from "../../../shared/components/text-editor/reduxFormPort";
import renderFileInputRemoverField from "../../../shared/components/form/FileInputRemover";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);
const renderTextArea = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <textarea {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

class AddWhiteBoardForm extends Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    // error: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      languages: [],
    };
  }

  onTranslateClick = (e) => {
    const title = this.title_no.value || this.title_en.value;
    const desc = this.desc_no.value || this.desc_en.value;
    const source = this.title_no.value ? "no" : "en";

    this.props.onTranslate([title, desc], source, "fa", (translations) => {
      if (translations.length) {
        this.props.change(this.title_fa.name, translations[0].translatedText);
        this.props.change(this.desc_fa.name, translations[1].translatedText);
      }
    });

    this.props.onTranslate([title, desc], source, "ar", (translations) => {
      if (translations.length) {
        this.props.change(this.title_ar.name, translations[0].translatedText);
        this.props.change(this.desc_ar.name, translations[1].translatedText);
      }
    });

    this.props.onTranslate([title, desc], source, "ur", (translations) => {
      if (translations.length) {
        this.props.change(this.title_ur.name, translations[0].translatedText);
        this.props.change(this.desc_ur.name, translations[1].translatedText);
      }
    });

    this.props.onTranslate(
      [title, desc],
      source,
      source === "no" ? "en" : "no",
      (translations) => {
        if (translations.length) {
          this.props.change(
            this[`title_${source === "no" ? "en" : "no"}`].name,
            translations[0].translatedText
          );
          this.props.change(
            this[`desc_${source === "no" ? "en" : "no"}`].name,
            translations[1].translatedText
          );
        }
      }
    );
  };

  render() {
    const { handleSubmit, reset, isEditing, t } = this.props;

    return (
      <form className="form form--horizontal" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Groups</span>
          <div className="form__form-group-field">
            <Field
              name="groups"
              component={renderMultiSelectField}
              options={this.props.groups}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Sort index</span>
          <div className="form__form-group-field">
            <Field
              name="sort_index"
              component={renderField}
              type={"number"}
              placeholder="Sort Index"
              min={0}
              defaultValue="0"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Title</span>
          <div
            className="form__form-group-field"
            style={{
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {this.props.languages.map((item) => {
              return (
                <Col key={item.value} style={{ padding: 0 }} sm={12} md={5}>
                  <Field
                    name={`translations.${item.value}.title`}
                    component={renderField}
                    type="text"
                    placeholder={item.label}
                    ref={(ref) => (this[`title_${item.value}`] = ref)}
                  />
                </Col>
              );
            })}
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="important"
              component={renderCheckBoxField}
              type="checkbox"
              id="important"
              label="important"
              // defaultChecked
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Body</span>
          <div className="form__form-group-field wraped">
            {this.props.languages.map((item) => {
              return (
                <div
                  key={item.value}
                  style={{ display: "block", width: "100%", maxWidth: "100%" }}
                >
                  <Field
                    name={`translations.${item.value}.body`}
                    component={renderTextEditor}
                    type="text"
                    placeholder={item.label}
                    ref={(ref) => (this[`desc_${item.value}`] = ref)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {isEditing && (
          <div className="form__form-group">
            <span className="form__form-group-label">Files</span>
            <div className="form__form-group-field">
              <Field
                name="attachment"
                component={renderFileInputRemoverField}
                multiple
              />
            </div>
          </div>
        )}
        <div className="form__form-group">
          <span className="form__form-group-label">Add Files</span>
          <div className="form__form-group-field">
            <Field
              name="add_attachment"
              component={renderFileInputField}
              multiple
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Link</span>
          <div className="form__form-group-field">
            <Field
              name="link"
              component={renderField}
              type="text"
              placeholder="Link"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Lat</span>
          <div className="form__form-group-field">
            <Field
              name="lat"
              component={renderField}
              type="text"
              placeholder="Latitude"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Long</span>
          <div className="form__form-group-field">
            <Field
              name="long"
              component={renderField}
              type="text"
              placeholder="Longitude"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Location</span>
          <Fields
            names={["lat", "long"]}
            component={({ lat: { input: lat }, long: { input: long } }) => (
              <LeafletMap
                value={
                  (lat.value &&
                    long.value && {
                      lat: lat.value,
                      lng: long.value,
                    }) ||
                  null
                }
                onChange={({ lat: lt, lng }) => {
                  lat.onChange(lt);
                  long.onChange(lng);
                }}
              />
            )}
          />
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">
            Submit
          </Button>
          <Button type="button" onClick={reset}>
            Cancel
          </Button>
          <Button color="info" onClick={this.onTranslateClick}>
            Translate
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: "add_whiteboard_form",
  //   validate // a unique identifier for this form
})(AddWhiteBoardForm);
