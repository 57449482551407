import Webservice from "../../utils/networkApis";

export const ATTENDANCES = "APP_TRANSLATIONS";
export const ATTENDANCES_SUCCESS = "ATTENDANCES_SUCCESS";
export const ATTENDANCES_FAILED = "ATTENDANCES_FAILED";

export const ADD_ATTENDANCE = "ADD_ATTENDANCE";
export const ADD_ATTENDANCE_SUCCESS = "ADD_ATTENDANCE_SUCCESS";
export const ADD_ATTENDANCE_FAILED = "ADD_ATTENDANCE_FAILED";

export const EDIT_ATTENDANCE = "EDIT_ATTENDANCE";
export const EDIT_ATTENDANCE_SUCCESS = "EDIT_ATTENDANCE_SUCCESS";
export const EDIT_ATTENDANCE_FAILED = "EDIT_ATTENDANCE_FAILED";

export const DELETE_ATTENDANCE = "DELETE_ATTENDANCE";
export const DELETE_ATTENDANCE_SUCCESS = "DELETE_ATTENDANCE_SUCCESS";
export const DELETE_ATTENDANCE_FAILED = "DELETE_ATTENDANCE_FAILED";

export function getAttendances(groupId, itineraryId, mode = "both") {
  return (dispatch) => {
    dispatch({ type: ATTENDANCES });
    Webservice.getAttendances(groupId, itineraryId, mode).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ATTENDANCES_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: ATTENDANCES_FAILED, payload: response.error });
      }
    });
  };
}

export function addAttendance(data, history) {
  return (dispatch) => {
    dispatch({ type: ADD_ATTENDANCE });
    Webservice.addAttendance(data).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_ATTENDANCE_SUCCESS, payload: response.data });
        history.push("/admin/attendances");
      } else {
        dispatch({ type: ADD_ATTENDANCE_FAILED, error: response.error });
      }
    });
  };
}

export function editAttendance(data, id) {
  return (dispatch) => {
    dispatch({ type: EDIT_ATTENDANCE });
    Webservice.editAttendance(id, data).then((resp) => {
      const response = resp.data;

      if (response.success) {
        dispatch({
          type: EDIT_ATTENDANCE_SUCCESS,
          payload: {
            data: { ...response.data, id },
            message: response.message,
          },
        });
      } else {
        dispatch({ type: EDIT_ATTENDANCE_FAILED, error: response.error });
      }
    });
  };
}

export function deleteAttendance(ids) {
  // alert(ids);
  return (dispatch) => {
    dispatch({ type: DELETE_ATTENDANCE });
    return Webservice.deleteAttendance({ attendancesIds: ids }).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_ATTENDANCE_SUCCESS,
          payload: { ids },
        });
      } else {
        dispatch({ type: DELETE_ATTENDANCE_FAILED, error: response.error });
      }
    });
  };
}
