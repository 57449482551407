/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import RenderFile from '../RenderFile'

class FileInputRemoverField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string
      }),
      PropTypes.string
    ])
  }

  static defaultProps = {
    value: null
  }

  render () {
    const { onChange, name, value } = this.props
    const files = (value || []).filter(({ remove }) => !remove)

    return (
      <div className='form__form-group-file'>
        {files.length
          ? files.map(v => (
              <>
                <RenderFile key={v.id} style={{ maxWidth: 300 }} {...v} />
                <label
                  onClick={() =>
                    onChange(
                      value.map(_ =>
                        _.id == v.id ? { ..._, remove: true } : _
                      )
                    )
                  }
                >
                  Remove
                </label>
              </>
            ))
          : 'No Files'}
      </div>
    )
  }
}

const renderFileInputRemoverField = props => {
  const { input, meta, multiple } = props
  return (
    <div className='form__form-group-input-wrap'>
      <FileInputRemoverField {...input} multiple={multiple} />
      {meta.touched && meta.error && (
        <span className='form__form-group-error'>{meta.error}</span>
      )}
    </div>
  )
}

renderFileInputRemoverField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
}

renderFileInputRemoverField.defaultProps = {
  meta: null
}

export default renderFileInputRemoverField
