import {
  META_DATA,
  META_DATA_FAILED,
  META_DATA_SUCCESS
} from "../actions/metaDataActions";

const initialState = {
  loading: false,
  metaData: null,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case META_DATA:
      return { ...state, loading: true, error: null };
    case META_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        metaData: action.payload
      };
    case META_DATA_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
