import React, { createRef, Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

var myIcon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

export default class LeafletMap extends Component {
  state = {
    center: {
      lat: 21.3891,
      lng: 39.8579,
    },
    marker: null,
    zoom: 11,
    // draggable: true,
  };
  // $FlowFixMe: ref
  refmarker = createRef();

  toggleDraggable = () => {
    this.setState({ draggable: !this.state.draggable });
  };

  updatePosition = () => {
    const marker = this.refmarker.current;
    if (marker != null && this.props.onChange) {
      this.props.onChange(marker.leafletElement.getLatLng());
    }
  };

  render() {
    const center = this.props.value || this.props.center || this.state.center;
    console.log(this.props.value, this.props.center, this.state.center);
    const position = [center.lat, center.lng];
    const marker = this.props.value && (
      <Marker
        draggable={!!this.props.onChange}
        onDragend={this.updatePosition}
        position={[this.props.value.lat, this.props.value.lng]}
        ref={this.refmarker}
      />
    );

    console.log({ position });
    return (
      <Map
        center={position}
        onClick={({ latlng }) =>
          this.props.onChange ? this.props.onChange(latlng) : null
        }
        onZoomEnd={(ev) => {
          this.setState({ zoom: ev.target.getZoom() });
        }}
        style={{ width: 500, height: 500, zIndex: '0' }}
        zoom={this.state.zoom}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {marker}
      </Map>
    );
  }
}

// export default withTranslation('common')(BasicMap);
