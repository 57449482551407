import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Table from "./components/DataTable";
import {
  getContents,
  deleteContent,
} from "../../redux/actions/contentsActions";
import { connect } from "react-redux";

class Contents extends React.Component {
  componentDidMount() {
    this.props.doGetContent(this.props.type);
  }

  render() {
    const { title, entityName } = this.props;
    const data = this.props.contents
      .map((item) => {
        return {
          id: item.content_id,
          order: item.order || "N/A",
          title: item.title || "N/A",
          type: item.type || "N/A",
          city: item.city || "N/A",
          category: item.category || "N/A",
          created_by: item.admin_user ? item.admin_user.email : "N/A",
        };
      })
      .filter((item) => item.id);
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">{title}</h3>
          </Col>
          <Col md={2}>
            <Link
              to={"/admin/add-content"}
              className="btn btn-primary products-list__btn-add"
            >
              New {entityName}
            </Link>
          </Col>
        </Row>
        <Row>
          <Table
            type={this.props.type}
            addFilter={!this.props.type}
            entityName={entityName}
            data={data || []}
            error={this.props.error}
            success={(id) => {
              this.props.doDeleteContent(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ content }) => {
  return {
    loading: content.loading,
    error: content.error,
    contents: content.contents,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetContent: (type) => dispatch(getContents(type)),
    doDeleteContent: (id) => dispatch(deleteContent(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contents);
