import {
  ITINERARIES,
  ITINERARIES_FAILED,
  ITINERARIES_SUCCESS,
  ADD_ITINERARIE,
  ADD_ITINERARIE_FAILED,
  ADD_ITINERARIE_SUCCESS,
  EDIT_ITINERARIE,
  EDIT_ITINERARIE_FAILED,
  EDIT_ITINERARIE_SUCCESS,
  DELETE_ITINERARIE,
  DELETE_ITINERARIE_FAILED,
  DELETE_ITINERARIE_SUCCESS
} from "../actions/itinerariesActions";

const initialState = {
  loading: false,
  itineraries: [],
  message: null,
  data: null,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ITINERARIES:
      return { ...state, loading: true, error: null };
    case ITINERARIES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        itineraries: action.payload
      };
    case ITINERARIES_FAILED:
      return { ...state, loading: false, error: action.payload };
    case ADD_ITINERARIE:
      return { ...state, loading: true, error: null };
    case ADD_ITINERARIE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        itineraries: [...state.itineraries, action.payload]
      };
    case ADD_ITINERARIE_FAILED:
      return { ...state, loading: false, error: action.error };
    case DELETE_ITINERARIE:
      return { ...state, loading: true, error: null };
    case DELETE_ITINERARIE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        itineraries: state.itineraries.filter(
          item => item.id !== action.payload.id
        )
      };
    case DELETE_ITINERARIE_FAILED:
      return { ...state, loading: false, error: action.error };
    case EDIT_ITINERARIE:
      return { ...state, loading: true, error: null };
    case EDIT_ITINERARIE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        message: action.payload.message,
        itineraries: state.itineraries.map(
          item =>
            item.id == action.payload.data.id ? action.payload.data : item
        )
      };
    case EDIT_ITINERARIE_FAILED:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
}
