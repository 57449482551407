import {
    IMPORTED_USERS,
    IMPORTED_USERS_FAILED,
    IMPORTED_USERS_SUCCESS,
    ADD_IMPORTED_USER,
    ADD_IMPORTED_USER_FAILED,
    ADD_IMPORTED_USER_SUCCESS,
    DELETE_IMPORTED_USER,
    DELETE_IMPORTED_USER_FAILED,
    DELETE_IMPORTED_USER_SUCCESS,
    EDIT_IMPORTED_USER,
    EDIT_IMPORTED_USER_FAILED,
    EDIT_IMPORTED_USER_SUCCESS
  } from "../actions/importedUsersActions";
  
  const initialState = {
    loading: false,
    importedUsers: [],
    message: null,
    data: null,
    error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case IMPORTED_USERS:
        return { ...state, loading: true, error: null };
      case IMPORTED_USERS_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          importedUsers: action.payload
        };
      case IMPORTED_USERS_FAILED:
        return { ...state, loading: false, error: action.payload };
      case ADD_IMPORTED_USER:
        return { ...state, loading: true, error: null };
      case ADD_IMPORTED_USER_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          importedUsers: [...state.importedUsers, action.payload]
        };
      case ADD_IMPORTED_USER_FAILED:
        return { ...state, loading: false, error: action.error };
      case DELETE_IMPORTED_USER:
        return { ...state, loading: true, error: null };
      case DELETE_IMPORTED_USER_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          importedUsers: state.importedUsers.filter(
            item => item.id !== action.payload.id
          )
        };
      case DELETE_IMPORTED_USER_FAILED:
        return { ...state, loading: false, error: action.error };
      case EDIT_IMPORTED_USER:
        return { ...state, loading: true, error: null };
      case EDIT_IMPORTED_USER_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          message: action.payload.message,
          importedUsers: state.importedUsers.map(
            item =>
              item.id == action.payload.data.id ? action.payload.data : item
          )
        };
      case EDIT_IMPORTED_USER_FAILED:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  }
  