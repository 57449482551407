import {
    LANGUAGES,
    LANGUAGES_SUCCESS,
    LANGUAGES_FAILED,
    ADD_LANGUAGE,
    ADD_LANGUAGE_FAILED,
    ADD_LANGUAGE_SUCCESS,
    DELETE_LANGUAGE,
    DELETE_LANGUAGE_FAILED,
    DELETE_LANGUAGE_SUCCESS,
    EDIT_LANGUAGE,
    EDIT_LANGUAGE_FAILED,
    EDIT_LANGUAGE_SUCCESS
  } from "../actions/languagesActions";
  
  const initialState = {
    loading: false,
    languages: [],
    message: null,
    data: null,
    error: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LANGUAGES:
        return { ...state, loading: true, error: null };
      case LANGUAGES_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          languages: action.payload
        };
      case LANGUAGES_FAILED:
        return { ...state, loading: false, error: action.payload };
      case ADD_LANGUAGE:
        return { ...state, loading: true, error: null };
      case ADD_LANGUAGE_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          languages: [...state.languages, action.payload]
        };
      case ADD_LANGUAGE_FAILED:
        return { ...state, loading: false, error: action.error };
      case DELETE_LANGUAGE:
        return { ...state, loading: true, error: null };
      case DELETE_LANGUAGE_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          languages: state.languages.filter(
            item => item.id !== action.payload.id
          )
        };
      case DELETE_LANGUAGE_FAILED:
        return { ...state, loading: false, error: action.error };
      case EDIT_LANGUAGE:
        return { ...state, loading: true, error: null };
      case EDIT_LANGUAGE_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          message: action.payload.message,
          languages: state.languages.map(
            item =>
              item.id == action.payload.data.id ? action.payload.data : item
          )
        };
      case EDIT_LANGUAGE_FAILED:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  }
  