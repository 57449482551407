import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import AddFormInput from './components/addFormInput';
import { editContact } from '../../redux/actions/contactsActions';
import { connect } from 'react-redux';
import webservice from '../../utils/networkApis';
import {
  transformTransalationsFromBackend,
  transformTransalationsForBackend,
} from '../../utils/dataTransformerForAPI';
import Alert from '../../shared/components/Alert';
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};
class EditContacts extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null,
  };
  componentDidMount() {
    const id = this.props.match.params.id;
    webservice.getOneContact(id).then((resp) => {
      let data = resp.data.data;
      console.log(resp);
      data = {
        ...data,
        translations: transformTransalationsFromBackend(
          data.translations || []
        ),
      };
      this.setState({ initialValues: data, loading: false });
    });
  }
  componentDidUpdate() {
    scrollToTop();
  }

  render() {
    const { loading, initialValues } = this.state;

    const { error } = this.props;
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Edit Contact </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <Alert
                color="success"
                visible={this.props.message !== null ? true : false}
              >
                <p>
                  <span className="bold-text">Success</span>
                  {this.props.message}
                </p>
              </Alert>
              <CardBody>
                {loading ? (
                  <div className={`load`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path
                          fill="#4ce1b6"
                          d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <AddFormInput
                    _error={error}
                    initialValues={initialValues}
                    language={this.props.meta ? this.props.meta.languages : []}
                    group={this.props.meta ? this.props.meta.groups : []}
                    onSubmit={(values) => {
                      console.log('values', values);
                      this.props.doEditContact(
                        {
                          ...values,
                          translations: transformTransalationsForBackend(
                            values.translations || {}
                          ),
                        },
                        this.props.match.params.id
                      );
                      const id = this.props.match.params.id;
                      this.props.history.push(`/admin/contact/${id}`);
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ contact, metaData }) => {
  return {
    loading: contact.loading,
    error: contact.error,
    contents: contact.contents,
    message: contact.message,
    meta: metaData.metaData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doEditContact: (obj, id) => dispatch(editContact(obj, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditContacts);
