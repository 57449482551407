import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ShowEntity extends Component {
  static propTypes = {
    label: PropTypes.string,
    loading: PropTypes.bool,
    editButton: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.objectOf({
        label: PropTypes.string,
        value: PropTypes.elementType,
      })
    ),
  };
  constructor(props) {
    super(props);
  }

  render() {
    const { values, loading, label, editButton } = this.props;

    // console.log("sect", sect);

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">{label}</h3>
          </Col>
          {editButton && (
            <Col md={2}>
              <Link
                to={editButton}
                className="btn btn-primary products-list__btn-add"
              >
                Edit
              </Link>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                {loading ? (
                  <div className={`load`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path
                          fill="#4ce1b6"
                          d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <div className="form form--horizontal">
                    {values.map(({ label, value }) => (
                      <div className="form__form-group">
                        <span className="form__form-group-label">{label}</span>
                        <div className="form__form-group-field wraped">
                          {label === 'DESCRIPTION' ? (
                            <div dangerouslySetInnerHTML={{ __html: value }} />
                          ) : (
                            value
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ShowEntity;
