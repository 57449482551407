import Webservice from '../../utils/networkApis';

export const CONTACTS = 'CONTACTS';
export const CONTACTS_SUCCESS = 'CONTACTS_SUCCESS';
export const CONTACTS_FAILED = 'CONTACTS_FAILED';

export const ADD_CONTACT = 'ADD_CONTACT';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_FAILED = 'ADD_CONTACT_FAILED';

export const EDIT_CONTACT = 'EDIT_CONTACT';
export const EDIT_CONTACT_SUCCESS = 'EDIT_CONTACT_SUCCESS';
export const EDIT_CONTACT_FAILED = 'EDIT_CONTACT_FAILED';

export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILED = 'DELETE_CONTACT_FAILED';

export function getContacts() {
  return (dispatch) => {
    dispatch({ type: CONTACTS });
    Webservice.getContacts().then((resp) => {
      const response = resp.data;
      console.log('response', response);
      if (response.success) {
        dispatch({ type: CONTACTS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: CONTACTS_FAILED, payload: response.error });
      }
    });
  };
}

export function addContact(data, history) {
  return (dispatch) => {
    dispatch({ type: ADD_CONTACT });
    Webservice.addContact(data).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_CONTACT_SUCCESS, payload: response.data });
        history.push('/admin/contacts');
      } else {
        dispatch({ type: ADD_CONTACT_FAILED, error: response.error });
      }
    });
  };
}

export function editContact(data, id) {
  return (dispatch) => {
    dispatch({ type: EDIT_CONTACT });
    Webservice.editContact(id, data).then((resp) => {
      const response = resp.data;
      if (response.success) {
        console.log('resp', resp);
        dispatch({
          type: EDIT_CONTACT_SUCCESS,
          payload: {
            data: { ...response.data, id },
            message: response.message,
          },
        });
      } else {
        dispatch({ type: EDIT_CONTACT_FAILED, error: response.error });
      }
    });
  };
}

export function deleteContact(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_CONTACT });
    Webservice.deleteContact(id).then((resp) => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_CONTACT_SUCCESS,
          payload: { id },
        });
      } else {
        dispatch({ type: DELETE_CONTACT_FAILED, error: response.error });
      }
    });
  };
}
