import Webservice from "../../utils/networkApis";

export const IMPORTED_USERS = "IMPORTED_USERS";
export const IMPORTED_USERS_SUCCESS = "IMPORTED_USERS_SUCCESS";
export const IMPORTED_USERS_FAILED = "IMPORTED_USERS_FAILED";

export const ADD_IMPORTED_USER = "ADD_IMPORTED_USER";
export const ADD_IMPORTED_USER_SUCCESS = "ADD_IMPORTED_USER_SUCCESS";
export const ADD_IMPORTED_USER_FAILED = "ADD_IMPORTED_USER_FAILED";

export const EDIT_IMPORTED_USER = "EDIT_IMPORTED_USER";
export const EDIT_IMPORTED_USER_SUCCESS = "EDIT_IMPORTED_USER_SUCCESS";
export const EDIT_IMPORTED_USER_FAILED = "EDIT_IMPORTED_USER_FAILED";

export const DELETE_IMPORTED_USER = "DELETE_IMPORTED_USER";
export const DELETE_IMPORTED_USER_SUCCESS = "DELETE_IMPORTED_USER_SUCCESS";
export const DELETE_IMPORTED_USER_FAILED = "DELETE_IMPORTED_USER_FAILED";

export function getImportedUsers() {
  return dispatch => {
    dispatch({ type: IMPORTED_USERS });
    Webservice.getImportedUsers().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: IMPORTED_USERS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: IMPORTED_USERS_FAILED, payload: response.error });
      }
    });
  };
}

export function addImportedUser(data, history) {
  return dispatch => {
    dispatch({ type: ADD_IMPORTED_USER });
    Webservice.addImportedUser(data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_IMPORTED_USER_SUCCESS, payload: response.data });
        history.push("/admin/importedUsers");
      } else {
        dispatch({ type: ADD_IMPORTED_USER_FAILED, error: response.error });
      }
    });
  };
}

export function editImportedUser(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_IMPORTED_USER });
    Webservice.editImportedUser(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_IMPORTED_USER_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_IMPORTED_USER_FAILED, error: response.error });
      }
    });
  };
}

export function deleteImportedUser(id) {
  return dispatch => {
    dispatch({ type: DELETE_IMPORTED_USER });
    Webservice.deleteImportedUser(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_IMPORTED_USER_SUCCESS,
          payload: { id }
        });
      } else {
        dispatch({ type: DELETE_IMPORTED_USER_FAILED, error: response.error });
      }
    });
  };
}
