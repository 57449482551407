import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import PropTypes from "prop-types";
import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import validate from './validate';

const renderField = ({ input, placeholder, type, meta: { touched, error } }) =>
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched &&
      error &&
      <span className="form__form-group-error">
        {error}
      </span>}
  </div>;

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };
  }

  showPassword = e => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { handleSubmit, loading } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email"
              component={renderField}
              type="text"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="encrypted_password"
              component={renderField}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
            />
            <button
              className={`form__form-group-button${showPassword
                ? " active"
                : ""}`}
              onClick={e => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          {/* <div className="account__forgot-password">
            <a href="/">Forgot a password?</a>
          </div> */}
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Remember me"
            />
          </div>
        </div>
        <button
          type="submit"
          disabled={loading}
          className="btn btn-primary account__btn account__btn--small"
        >
          Sign In
        </button>
        {/* <Link className="btn btn-outline-primary account__btn account__btn--small" to="/log_in">Create Account</Link> */}
      </form>
    );
  }
}

export default reduxForm({
  form: "log_in_form",
  validate,
})(LogInForm);
