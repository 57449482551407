import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import PropTypes from "prop-types";
// import Table from "./components/DataTable";
import AddFormInput from "./components/addFormInput";
import { addUser } from "../../redux/actions/usersActions";
import { connect } from "react-redux";
import Alert from "../../shared/components/Alert";

class AddUsers extends React.Component {
  render() {
    // const { languages, genders, sect } = this.props.meta;

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">New User </h3>
            {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
                information
          </h3> */}
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Add Admin User</h5>
                  <h5 className="subhead">Labels are left from fields</h5>
                </div>
                <Alert
                  color="danger"
                  visible={this.props.error !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Warning!</span>
                    {this.props.error}
                  </p>
                </Alert>
                <AddFormInput
                  language={this.props.meta ? this.props.meta.languages : []}
                  gender={this.props.meta ? this.props.meta.genders : []}
                  sect={this.props.meta ? this.props.meta.sect : []}
                  onSubmit={(values) => {
                    const readyForPost = {
                      first_name: values.first_name,
                      last_name: values.last_name,
                      sect: values.sect,
                      gender: values.gender,
                      place_of_birth: values.place_of_birth,
                      born_on: new Date(values.born_on),
                      passport: values.passport,
                      phone: values.phone,
                      ksa_phone: values.ksa_phone,
                      nationality: values.nationality,
                      zipcode: values.zipcode,
                      username: values.username,
                      encrypted_password: values.encrypted_password,
                      // staff: false,
                      visa_status: 2,
                      group_id: 1,
                      language_id: 1,
                      email: values.email,
                    };

                    console.log({ readyForPost });
                    this.props.doAddUsers(readyForPost, this.props.history);
                  }}
                  error={this.props.error}
                  // alert={}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ user, metaData }) => {
  return {
    loading: user.loading,
    error: user.error,
    meta: metaData.metaData,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddUsers: (obj, props) => dispatch(addUser(obj, props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUsers);
