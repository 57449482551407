import React, { PureComponent } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Table from "../Itineraries/components/DataTable";
import {
  getItineraries,
  deleteItinerarie,
} from "../../redux/actions/itinerariesActions";
import Modal from "../../shared/components/Modal";
import Select from "react-select";
import { connect } from "react-redux";
import webservice from "../../utils/networkApis";
import Loading from "../../shared/components/Loading";

class GroupItinerary extends PureComponent {
  constructor() {
    super();

    this.state = {
      modal: false,
      targetGroup: null,
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.doGetItineraries(id);
  }

  toggle = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  render() {
    const id = this.props.match.params.id;
    const data = this.props.itineraries.map((item) => {
      return {
        id: item.id,
        leader: item.group ? item.group.leader : "N/A",
        group_id: item.group_id,
        title: item.title || "N/A",
        place: item.content ? item.content.type : "N/A",
        time: new Date(item.time).toLocaleString() || "N/A",
      };
    });

    const { meta } = this.props;

    console.log("itineraries", this.props.meta);
    return (
      <Container>
        <Row>
          <Col md={9}>
            <h3 className="page-title">Programs</h3>
          </Col>
          <Col md={3}>
            <Link
              to={`/admin/itinerary/new?groupId=${id}`}
              className="btn btn-primary products-list__btn-add"
            >
              New Program
            </Link>
            {/* <button
              onClick={() => this.setState({ modal: true })}
              className="btn btn-primary products-list__btn-add"
            >
              Copy to another group
            </button> */}
          </Col>
        </Row>
        {/* <Modal
          open={this.state.modal}
          success={() => {
            if (!this.state.targetGroup) return;
            // this.props.success(this.state.id);
            webservice.copyItinerary(id, this.state.targetGroup.value, 0);
            this.setState({ modal: false });
          }}
          toggle={this.toggle}
          color="primary"
          title="Copy Programs"
          header
          btn="Default"
          message={
            <div>
              <div className="form__form-group">
                <span className="form__form-group-label">Target Group</span>
                <div className="form__form-group-field">
                  <Select
                    name="visa_status"
                    onChange={(targetGroup) => this.setState({ targetGroup })}
                    value={this.state.targetGroup}
                    options={meta ? meta.groups : []}
                    // clearable={false}
                    className="react-select"
                    // classNamePrefix="react-select"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Shift (in days)</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                    <input type="number" className="input" />
                  </div>
                </div>
              </div>
            </div>
          }
        /> */}
        <Row>
          {this.props.loading ? (
            <Loading loading />
          ) : (
            <Table
              refreshData={() => this.props.doGetItineraries()}
              // entityName={entityName}
              data={data || []}
              meta={this.props.meta}
              error={this.props.error}
              success={(id) => {
                this.props.doDeleteItineraries(id);
              }}
              groupId={id}
            />
          )}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ Itinerarie, metaData }) => {
  return {
    loading: Itinerarie.loading,
    error: Itinerarie.error,
    itineraries: Itinerarie.itineraries,
    meta: metaData.metaData,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetItineraries: (type) => dispatch(getItineraries(type)),
    doDeleteItineraries: (id) => dispatch(deleteItinerarie(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupItinerary);
