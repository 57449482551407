/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import DataCustomTable from "../../../shared/components/table/DataCustomTable";
import Pagination from "../../../shared/components/pagination/Pagination";
import Modal from "../../../shared/components/Modal";
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import webservice from "../../../utils/networkApis";
import Alert from "../../../shared/components/Alert";

const filterRows = (originalRows, pageNumber, rowsOnPage = 10) => {
  const rowsFrom = rowsOnPage * (pageNumber - 1);
  const rowsTo = rowsFrom + rowsOnPage;
  return originalRows.slice(rowsFrom, rowsTo);
};

const selectors = Data.Selectors;
const { SingleSelectFilter, MultiSelectFilter } = Filters;

class DataTable extends PureComponent {
  constructor(props) {
    super(props);
    this.heads = [
      {
        key: "property",
        name: "Property",
        sortable: true,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/app-setting/${args.rowId}`);
          },
        },
      },
      {
        key: "value",
        name: "Value",
        // filterable: true,
        // filterRenderer: SingleSelectFilter,
        sortable: true,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/app-setting/${args.rowId}`);
          },
        },
      },
      {
        key: "created_at",
        name: "Created At",
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/app-setting/${args.rowId}`);
          },
        },
      },
      {
        key: "actions",
        name: "Actions",
        cellClass: "ActionCell",
      },
    ];

    const initialPageNumber = 1;
    const initialRowsCount = 50;

    const originalRows = this.props.data;
    const currentPageRows = filterRows(
      this.props.data,
      initialPageNumber,
      initialRowsCount
    );

    this.state = {
      rows: originalRows,
      rowsToShow: currentPageRows,
      pageOfItems: initialPageNumber,
      itemsToShow: initialRowsCount,
      selectedIndexes: [],
      selectedIds: [],
      modal: false,
      id: null,
    };
  }

  nameActions = (row) => [
    // {
    //   icon: (
    //     <Button
    //       style={{ marginRight: 10 }}
    //       size="sm"
    //       className="badge badge-success"
    //     >
    //       Destroy
    //     </Button>
    //   ),
    //   callback: (id) => {
    //     console.log('id', row);
    //     this.setState({ modal: true, id: row.id });
    //   },
    // },
    // {
    //   icon: (
    //     <Button
    //       style={{ marginRight: 10 }}
    //       size="sm"
    //       className="badge badge-success"
    //     >
    //       Edit
    //     </Button>
    //   ),
    //   callback: id => {
    //     this.props.history.push(`/admin/helpme/${row.id}/edit`);
    //   }
    // }
  ];

  onChangePage = (pageOfItems) => {
    const { rows, itemsToShow } = this.state;
    if (pageOfItems) {
      const rowsToShow = filterRows(rows, pageOfItems, itemsToShow);
      this.setState({ rowsToShow, pageOfItems });
    }
  };

  toggle = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  getCellActions = (column, row) => {
    const cellActions = {
      actions: this.nameActions(row),
    };
    return cellActions[column.key];
  };

  onRowsSelected = (rows) => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        rows.map((r) => r.rowIdx)
      ),
      selectedIds: this.state.selectedIds.concat(rows.map((r) => r.row.id)),
    });
  };

  onRowsDeselected = (rows) => {
    let rowIndexes = rows.map((r) => r.rowIdx);
    let rowIds = rows.map((r) => r.row.id);
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        (i) => rowIndexes.indexOf(i) === -1
      ),
      selectedIds: this.state.selectedIds.filter(
        (i) => rowIds.indexOf(i) === -1
      ),
    });
  };

  multipleDelete = () => {
    webservice.appSettingsBulkDelete(this.state.selectedIds).then(() => {
      this.setState({ selectedIndexes: [], selectedIds: [] });
      this.props.refreshData();
    });
  };

  handleFilterChange = (filter) => (filters) => {
    const newFilters = { ...filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    return newFilters;
  };

  setFilters = (temperIt) => {
    this.setState(
      typeof temperIt == "function"
        ? ({ filters }) => ({ filters: temperIt(filters), pageOfItems: 1 })
        : { filters: temperIt }
    );
  };

  getValidFilterValues(rows, columnId) {
    return rows
      .map((r) => r[columnId])
      .filter((item, i, a) => {
        return i === a.indexOf(item);
      });
  }

  getRows(rows, filters) {
    return selectors.getRows({ rows, filters });
  }

  onSorting = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    };
    const { rows } = this.state;
    if (sortDirection !== "NONE") {
      let sortRows = [...rows].sort(comparer);
      console.log(sortRows[0], rows[0]);
      this.setState({ rows: sortRows });
      return sortRows;
    }
    this.setState({ rows: this.props.data });
    return this.props.data;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.rows) {
      return {
        rows: props.data,
      };
    }
    return null;
  }
  render() {
    const {
      rows,
      itemsToShow,
      pageOfItems,
      filters,
      rowsToShow,
      selectedIndexes,
    } = this.state;
    // const { entityName } = this.props;
    const filteredRows = this.getRows(rows, filters);

    return (
      <Col md={12} lg={12}>
        <Modal
          open={this.state.modal}
          success={() => {
            this.state.id
              ? this.props.success(this.state.id)
              : this.multipleDelete();
            this.setState({ modal: false, id: null });
          }}
          toggle={this.toggle}
          color="danger"
          title="Warning!"
          colored
          btn="Danger"
          message={`Are you sure you want to delete WhiteBoard ?`}
        />
        <Card>
          <CardBody>
            {selectedIndexes.length !== 0 ? (
              <button
                onClick={() => this.setState({ modal: true })}
                className="btn btn-danger products-list__btn-add"
              >
                Delete Selected
              </button>
            ) : null}
            <Alert
              color="danger"
              visible={this.props.error !== null ? true : false}
            >
              <p>
                <span className="bold-text">Error! </span>
                We can't Delete it Because its used
              </p>
            </Alert>
            {(
              <DataCustomTable
                heads={this.heads}
                rows={filteredRows}
                onSorting={this.onSorting}
                rowKey="id"
                extraProps={{
                  rowSelection: {
                    showCheckbox: true,
                    enableShiftSelect: true,
                    onRowsSelected: this.onRowsSelected,
                    onRowsDeselected: this.onRowsDeselected,
                    selectBy: {
                      indexes: selectedIndexes,
                    },
                  },
                  toolbar: <Toolbar enableFilter={true} />,
                  onAddFilter: (filter) =>
                    this.setFilters(this.handleFilterChange(filter)),
                  onClearFilters: () => this.setFilters({}),
                  getValidFilterValues: (columnKey) =>
                    this.getValidFilterValues(rows, columnKey),
                }}
                getCellActions={this.getCellActions}
                pagination={{
                  itemsToShow,
                  pageOfItems,
                }}
              />
            ) || ""}
            <Pagination
              itemsCount={rows.length}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withRouter(DataTable);
