import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class SelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]).isRequired,
  };

  static defaultProps = {
    placeholder: '',
    options: [],
  };

  handleChange = (selectedOption) => {
    console.log(selectedOption);
    const { onChange } = this.props;
    onChange(selectedOption.value || selectedOption);
  };

  render() {
    const { value, name, placeholder, options, isMulti } = this.props;
    console.log(value);
    return (
      <Select
        name={name}
        value={value.value ? value : options.find((o) => o.value == value)}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        className="react-select"
        isMulti={isMulti}
        placeholder={placeholder}
        classNamePrefix="react-select"
      />
    );
  }
}

const renderSelectField = (props) => {
  const { input, meta, options, placeholder, className, isMulti } = props;
  console.log(input, input.value);
  return (
    <div className={`form__form-group-input-wrap ${className}`}>
      <SelectField
        {...input}
        options={options}
        isMulti={isMulti}
        placeholder={placeholder}
      />
      {meta.touched && meta.error && (
        <span className="form__form-group-error">{meta.error}</span>
      )}
    </div>
  );
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
  className: '',
};

export default renderSelectField;
