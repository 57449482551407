import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Container,
  Row,
} from "reactstrap";
import Alert from "../../../../shared/components/Alert";
import { Field, reduxForm, Fields } from "redux-form";
import renderSelectField from "../../../../shared/components/form/Select";
import validate from "./validate";
import PropTypes from "prop-types";
import LeafletMap from "../../../GoogleMap/components/BasicMap";
import renderTextEditor from "../../../../shared/components/text-editor/reduxFormPort";
import renderFileInputField from "../../../../shared/components/form/FileInput";
import renderFileInputRemoverField from "../../../../shared/components/form/FileInputRemover";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

const renderTextArea = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <textarea {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

class AddPlaceForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    heading: PropTypes.string.isRequired,
    languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    error: PropTypes.oneOf(PropTypes.string, PropTypes.string, PropTypes.any),
    reset: PropTypes.func.isRequired,
    isEditing: PropTypes.bool,
  };

  constructor() {
    super();
    this.state = { selectedType: "" };
  }

  onTranslateClick = (e) => {
    const title = this.title_no.value || this.title_en.value;
    const desc = this.description_no.value || this.description_en.value;
    const addr = this.address_no.value || this.address_en.value;
    const source = this.title_no.value ? "no" : "en";

    this.props.onTranslate(
      [title, desc, addr],
      source,
      "fa",
      (translations) => {
        if (translations.length) {
          this.props.change(this.title_fa.name, translations[0].translatedText);
          this.props.change(
            this.description_fa.name,
            translations[1].translatedText
          );
          this.props.change(
            this.address_fa.name,
            translations[2].translatedText
          );
        }
      }
    );

    this.props.onTranslate(
      [title, desc, addr],
      source,
      "ar",
      (translations) => {
        if (translations.length) {
          this.props.change(this.title_ar.name, translations[0].translatedText);
          this.props.change(
            this.description_ar.name,
            translations[1].translatedText
          );
          this.props.change(
            this.address_ar.name,
            translations[2].translatedText
          );
        }
      }
    );

    this.props.onTranslate(
      [title, desc, addr],
      source,
      "ur",
      (translations) => {
        if (translations.length) {
          this.props.change(this.title_ur.name, translations[0].translatedText);
          this.props.change(
            this.description_ur.name,
            translations[1].translatedText
          );
          this.props.change(
            this.address_ur.name,
            translations[2].translatedText
          );
        }
      }
    );

    this.props.onTranslate(
      [title, desc, addr],
      source,
      source === "no" ? "en" : "no",
      (translations) => {
        if (translations.length) {
          this.props.change(
            this[`title_${source === "no" ? "en" : "no"}`].name,
            translations[0].translatedText
          );
          this.props.change(
            this[`description_${source === "no" ? "en" : "no"}`].name,
            translations[1].translatedText
          );
          this.props.change(
            this[`address_${source === "no" ? "en" : "no"}`].name,
            translations[2].translatedText
          );
        }
      }
    );
  };

  render() {
    const {
      handleSubmit,
      reset,
      heading,
      languages,
      error,
      isEditing,
      _error,
    } = this.props;

    const { selectedType } = this.state;

    console.log(this.props);
    const e = error || _error;
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className="page-title">{heading}</h3>
          </Col>
          <Col xs={12}>
            <Card>
              <CardBody>
                <Alert color="danger" visible={!!e}>
                  <p>
                    <span className="bold-text">Warning!</span>
                    {e}
                  </p>
                </Alert>
                <form className="form form--horizontal" onSubmit={handleSubmit}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Type</span>
                    <div className="form__form-group-field">
                      <Field
                        name="type"
                        isMulti={false}
                        component={renderSelectField}
                        onChange={(selected) =>
                          this.setState({ selectedType: selected })
                        }
                        options={[
                          { value: "Airport", label: "Airport" },
                          { value: "Gathering", label: "Gathering" },
                          { value: "Hotel", label: "Hotel" },
                          { value: "Restaurant", label: "Restaurant" },
                          { value: "Market", label: "Market" },
                          { value: "Shrine", label: "Shrine" },
                          { value: "Tourism", label: "Tourism" },
                        ]}
                      />
                    </div>
                  </div>
                  {selectedType === "Restaurant" ? (
                    <div className="form__form-group">
                      <span className="form__form-group-label">City</span>
                      <div className="form__form-group-field">
                        <Field
                          name="city"
                          isMulti={false}
                          component={renderSelectField}
                          options={[
                            { value: "Medina", label: "Medina" },
                            { value: "Makkah", label: "Makkah" },
                          ]}
                        />
                      </div>
                    </div>
                  ) : null}
                  {selectedType === "Restaurant" ? (
                    <div className="form__form-group">
                      <span className="form__form-group-label">Category</span>
                      <div className="form__form-group-field">
                        <Field
                          name="category"
                          isMulti={false}
                          component={renderSelectField}
                          options={[
                            { value: "Restaurant", label: "Restaurant" },
                            { value: "FastFood", label: "Fast Food" },
                            { value: "Seafood", label: "Seafood" },
                          ]}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="form__form-group">
                    <span className="form__form-group-label">Order</span>
                    <div className="form__form-group-field">
                      <Field
                        name="order"
                        component={renderField}
                        type="number"
                        placeholder="Order"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Title</span>
                    <div
                      className="form__form-group-field"
                      style={{
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {languages.map((item) => {
                        return (
                          <Col
                            key={item.value}
                            style={{ padding: 0 }}
                            sm={12}
                            md={5}
                          >
                            <Field
                              name={`translations.${item.value}.title`}
                              component={renderField}
                              type="text"
                              placeholder={item.label}
                              ref={(ref) => (this[`title_${item.value}`] = ref)}
                            />
                          </Col>
                        );
                      })}
                    </div>
                  </div>
                  {[
                    { label: "Description", value: "description" },
                    { label: "Address", value: "address" },
                  ].map(({ label, value }) => (
                    <div className="form__form-group">
                      <span className="form__form-group-label">{label}</span>
                      {languages.map((item) => {
                        return (
                          <div className="form__form-group-field">
                            <div
                              key={item.value}
                              style={{
                                display: "block",
                                marginLeft: 20,
                                width: "100%",
                              }}
                            >
                              <Field
                                name={`translations.${item.value}.${value}`}
                                component={renderTextEditor}
                                type="text"
                                placeholder={item.label}
                                ref={(ref) =>
                                  (this[`${value}_${item.value}`] = ref)
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}

                  {isEditing && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">Files</span>
                      <div className="form__form-group-field">
                        <Field
                          name="images"
                          component={renderFileInputRemoverField}
                          multiple
                          type="file"
                          accept="image/*"
                        />
                      </div>
                    </div>
                  )}

                  <div className="form__form-group">
                    <span className="form__form-group-label">Add File</span>
                    <div className="form__form-group-field">
                      <Field
                        name="add_images"
                        component={renderFileInputField}
                        type="file"
                        accept="image/*"
                        multiple
                        dataAllowedFileExtensions="jpg png bmp"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Lat</span>
                    <div className="form__form-group-field">
                      <Field
                        name="lat"
                        component={renderField}
                        type="text"
                        placeholder="Latitude"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Long</span>
                    <div className="form__form-group-field">
                      <Field
                        name="long"
                        component={renderField}
                        type="text"
                        placeholder="Longitude"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Location</span>
                    <Fields
                      names={["lat", "long"]}
                      component={({
                        lat: { input: lat },
                        long: { input: long },
                      }) => (
                        <LeafletMap
                          value={
                            (lat.value &&
                              long.value && {
                                lat: lat.value,
                                lng: long.value,
                              }) ||
                            null
                          }
                          onChange={({ lat: lt, lng }) => {
                            lat.onChange(lt);
                            long.onChange(lng);
                          }}
                        />
                      )}
                    />
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button type="button" onClick={reset}>
                      Cancel
                    </Button>
                    <Button color="info" onClick={this.onTranslateClick}>
                      Translate
                    </Button>
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: "add_place_form",
  validate, // a unique identifier for this form
})(AddPlaceForm);
