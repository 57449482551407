import React, { PureComponent } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Table from "./components/DataTable";
import { getGroups, deleteGroup } from "../../redux/actions/groupsActions";
import { connect } from "react-redux";

class Groups extends PureComponent {
  componentDidMount() {
    console.log("groupsComponent ");
    this.props.doGetGroup();
  }

  render() {
    const data = this.props.groups.map((item) => {
      return {
        id: item.id,
        sort_index: item.sort_index,
        leader: item.leader,
        mobile: item.mobile,
        starts_on: item.starts_on,
        ends_on: item.ends_on,
        order_no: item.order_no,
      };
    });
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Groups</h3>
          </Col>
        </Row>
        <Row>
          <Table
            refreshData={() => this.props.doGetGroup()}
            entityName={"group"}
            data={data || []}
            error={this.props.error}
            success={(id) => {
              this.props.doDeleteGroup(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ group }) => {
  return {
    loading: group.loading,
    error: group.error,
    groups: group.groups,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetGroup: (type) => dispatch(getGroups(type)),
    doDeleteGroup: (id) => dispatch(deleteGroup(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
