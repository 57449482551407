/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import DataPaginationTable from "../../../shared/components/table/DataPaginationTable";
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import Pagination from "../../../shared/components/pagination/Pagination";
import Modal from "../../../shared/components/Modal";
import webservice from "../../../utils/networkApis";

const filterRows = (originalRows, pageNumber, rowsOnPage = 10) => {
  const rowsFrom = rowsOnPage * (pageNumber - 1);
  const rowsTo = rowsFrom + rowsOnPage;
  return originalRows.slice(rowsFrom, rowsTo);
};

const selectors = Data.Selectors;
const { SingleSelectFilter } = Filters;

class DataTable extends PureComponent {
  constructor(props) {
    super(props);
    this.heads = [
      {
        key: "staff",
        name: "Staff",
        filterable: false,
        filterRenderer: SingleSelectFilter,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/user/${args.rowId}`);
          },
        },
        width: 50,
        sortable: true,
      },
      {
        key: "assistant",
        name: "ASTNT",
        filterable: false,
        filterRenderer: SingleSelectFilter,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/user/${args.rowId}`);
          },
        },
        width: 50,
        sortable: true,
      },
      {
        key: "avatar",
        name: "Image",
        filterable: false,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/user/${args.rowId}`);
          },
        },
        formatter: ({ value }) => (
          <img
            className="topbar__avatar-img"
            src={
              !value
                ? "https://icon-library.com/images/no-profile-picture-icon/no-profile-picture-icon-18.jpg"
                : value
            }
            alt={value}
          />
        ),
        width: 60,
        sortable: false,
      },
      {
        key: "first_name",
        name: "First Name",
        filterable: true,
        filterRenderer: SingleSelectFilter,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/user/${args.rowId}`);
          },
        },
        sortable: true,
      },
      {
        key: "last_name",
        name: "Last Name",
        filterable: true,
        filterRenderer: SingleSelectFilter,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/user/${args.rowId}`);
          },
        },
        sortable: true,
      },
      {
        key: "username",
        name: "Username",
        filterable: true,
        filterRenderer: SingleSelectFilter,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/user/${args.rowId}`);
          },
        },
        sortable: true,
        width: 80,
      },
      {
        key: "nationality",
        name: "Nationality",
        filterable: true,
        filterRenderer: SingleSelectFilter,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/user/${args.rowId}`);
          },
        },
        sortable: true,
        width: 90,
      },
      {
        key: "language",
        name: "Language",
        filterable: true,
        filterRenderer: SingleSelectFilter,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/user/${args.rowId}`);
          },
        },
        sortable: true,
        width: 80,
      },
      // {
      //   key: "group",
      //   name: "Group",
      //   sortable: true
      // },
      {
        key: "actions",
        name: "Actions",
        cellClass: "ActionCell",
      },
    ];

    const initialPageNumber = 1;
    const initialRowsCount = 50;

    const originalRows = this.props.data;
    const currentPageRows = filterRows(
      this.props.data,
      initialPageNumber,
      initialRowsCount
    );

    this.state = {
      rows: originalRows,
      rowsToShow: currentPageRows,
      pageOfItems: initialPageNumber,
      filters: {},
      itemsToShow: initialRowsCount,
      modal: false,
      id: null,
      selectedIndexes: [],
      selectedIds: [],
    };
  }

  nameActions = (row) => [
    // {
    //   icon: (
    //     <Button
    //       style={{ marginRight: 10 }}
    //       size="sm"
    //       className="badge badge-success"
    //     >
    //       destroy
    //     </Button>
    //   ),
    //   callback: (id) => {
    //     this.setState({ modal: true, id: row.id });
    //   },
    // },
    {
      icon: (
        <Button
          style={{ marginRight: 10 }}
          size="sm"
          className="badge badge-success"
        >
          edit
        </Button>
      ),
      callback: (id) => {
        this.props.history.push(`/admin/edit-users/${row.id}`);
      },
    },
    {
      icon: (
        <Button
          style={{ marginRight: 10 }}
          size="sm"
          className="badge badge-success"
        >
          VISA
        </Button>
      ),
      callback: (id) => {
        window.open(row.visa, "_blank", "noreferrer");
      },
    },
  ];

  onRowsSelected = (rows) => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        rows.map((r) => r.rowIdx)
      ),
      selectedIds: this.state.selectedIds.concat(rows.map((r) => r.row.id)),
    });
  };

  onRowsDeselected = (rows) => {
    let rowIndexes = rows.map((r) => r.rowIdx);
    let rowIds = rows.map((r) => r.row.id);
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        (i) => rowIndexes.indexOf(i) === -1
      ),
      selectedIds: this.state.selectedIds.filter(
        (i) => rowIds.indexOf(i) === -1
      ),
    });
  };

  multipleDelete = () => {
    webservice.usersBulkDelete(this.state.selectedIds).then(() => {
      this.setState({ selectedIndexes: [], selectedIds: [] });
      if (this.props.refreshData) this.props.refreshData();
    });
  };

  onChangePage = (pageOfItems) => {
    const { rows, itemsToShow } = this.state;
    if (pageOfItems) {
      const rowsToShow = filterRows(rows, pageOfItems, itemsToShow);
      this.setState({ rowsToShow, pageOfItems });
    }
  };

  toggle = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  getCellActions = (column, row) => {
    const cellActions = {
      actions: this.nameActions(row),
    };
    return cellActions[column.key];
  };

  handleFilterChange = (filter) => (filters) => {
    const newFilters = { ...filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    return newFilters;
  };

  setFilters = (temperIt) => {
    this.setState(
      typeof temperIt == "function"
        ? ({ filters }) => ({ filters: temperIt(filters), pageOfItems: 1 })
        : { filters: temperIt }
    );
  };
  getValidFilterValues(rows, columnId) {
    return rows
      .map((r) => r[columnId])
      .filter((item, i, a) => {
        return i === a.indexOf(item);
      });
  }

  getRows(rows, filters) {
    return selectors.getRows({ rows, filters });
  }

  onSorting = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    };
    const { rows } = this.state;
    if (sortDirection !== "NONE") {
      let sortRows = [...rows].sort(comparer);

      this.setState({ rows: sortRows });
      return sortRows;
    }
    this.setState({ rows: this.props.data });
    return this.props.data;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data.length !== state.rows.length) {
      return {
        rows: props.data,
      };
    }
    return null;
  }
  render() {
    const {
      rows,
      itemsToShow,
      pageOfItems,
      rowsToShow,
      filters,
      selectedIndexes,
    } = this.state;
    const { entityName, modal } = this.props;

    const filteredRows = this.getRows(rows, filters);

    return (
      <Col md={12} lg={12}>
        <Modal
          open={this.state.modal}
          success={() => {
            // this.state.id
            //   ? this.props.success(this.state.id)
            //   : this.multipleDelete();
            this.multipleDelete();
            this.setState({ modal: false, id: null });
          }}
          toggle={this.toggle}
          color="danger"
          title="Warning!"
          colored
          btn="Danger"
          message={`Are you sure you want to delete ${entityName} ?`}
        />
        <Modal
          open={modal.open}
          success={modal.success}
          toggle={modal.toggle}
          color={modal.color}
          title={modal.title}
          colored
          message={modal.message}
        />
        <Card>
          <CardBody>
            {selectedIndexes.length !== 0 ? (
              <React.Fragment>
                <button
                  onClick={() => this.setState({ modal: true })}
                  className="btn btn-danger products-list__btn-add"
                >
                  Delete Selected
                </button>
              </React.Fragment>
            ) : null}
            <DataPaginationTable
              rowHeight={45}
              heads={this.heads}
              rows={filteredRows}
              key={rows.length}
              onSorting={this.onSorting}
              getCellActions={this.getCellActions}
              extraProps={{
                rowSelection: {
                  showCheckbox: true,
                  enableShiftSelect: true,
                  onRowsSelected: this.onRowsSelected,
                  onRowsDeselected: this.onRowsDeselected,
                  selectBy: {
                    indexes: selectedIndexes,
                  },
                },
                toolbar: <Toolbar enableFilter={true} />,
                onAddFilter: (filter) =>
                  this.setFilters(this.handleFilterChange(filter)),
                onClearFilters: () => this.setFilters({}),
                getValidFilterValues: (columnKey) =>
                  this.getValidFilterValues(rows, columnKey),
              }}
              pagination={{
                itemsToShow,
                pageOfItems,
              }}
            />
            <Pagination
              itemsCount={filteredRows.length}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withRouter(DataTable);
