import Webservice from "../../utils/networkApis";

export const WHITEBOARDS = "WHITEBOARDS";
export const WHITEBOARDS_SUCCESS = "WHITEBOARDS_SUCCESS";
export const WHITEBOARDS_FAILED = "WHITEBOARDS_FAILED";

export const ADD_WHITEBOARD = "ADD_WHITEBOARD";
export const ADD_WHITEBOARD_SUCCESS = "ADD_WHITEBOARD_SUCCESS";
export const ADD_WHITEBOARD_FAILED = "ADD_WHITEBOARD_FAILED";

export const EDIT_WHITEBOARD = "EDIT_WHITEBOARD";
export const EDIT_WHITEBOARD_SUCCESS = "EDIT_WHITEBOARD_SUCCESS";
export const EDIT_WHITEBOARD_FAILED = "EDIT_WHITEBOARD_FAILED";

export const DELETE_WHITEBOARD = "DELETE_WHITEBOARD";
export const DELETE_WHITEBOARD_SUCCESS = "DELETE_WHITEBOARD_SUCCESS";
export const DELETE_WHITEBOARD_FAILED = "DELETE_WHITEBOARD_FAILED";

export function getWhiteboards() {
  return dispatch => {
    dispatch({ type: WHITEBOARDS });
    Webservice.getWhiteboards().then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: WHITEBOARDS_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: WHITEBOARDS_FAILED, payload: response.error });
      }
    });
  };
}

export function addWhiteboard(data, history) {
  return dispatch => {
    dispatch({ type: ADD_WHITEBOARD });
    Webservice.addWhiteboard(data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({ type: ADD_WHITEBOARD_SUCCESS, payload: response.data });
        history.push(`/admin/whiteboard/${response.data.id}`);
      } else {
        dispatch({ type: ADD_WHITEBOARD_FAILED, error: response.error });
      }
    });
  };
}

export function editWhiteboard(data, id) {
  return dispatch => {
    dispatch({ type: EDIT_WHITEBOARD });
    Webservice.editWhiteboard(id, data).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: EDIT_WHITEBOARD_SUCCESS,
          payload: { data: { ...response.data, id }, message: response.message }
        });
      } else {
        dispatch({ type: EDIT_WHITEBOARD_FAILED, error: response.error });
      }
    });
  };
}

export function deleteWhiteboard(id) {
  return dispatch => {
    dispatch({ type: DELETE_WHITEBOARD });
    Webservice.deleteWhiteboard(id).then(resp => {
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: DELETE_WHITEBOARD_SUCCESS,
          payload: { id }
        });
      } else {
        dispatch({ type: DELETE_WHITEBOARD_FAILED, error: response.error });
      }
    });
  };
}
