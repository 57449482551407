import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import Table from "./components/DataTable";
import AddGuideForm from "./components/addGuideForm";
import { addGuide, deleteGuide } from "../../redux/actions/guidesActions";
import { translateTo } from "../../redux/actions/googleTranslation";
import { connect } from "react-redux";
import Alert from "../../shared/components/Alert";
import { transformTransalationsForBackend } from "../../utils/dataTransformerForAPI";

class AddGuides extends React.Component {
  onTranslate = (terms, source, target, callback) => {
    this.props.doTranslate(terms, source, target, () => {
      callback(this.props.translations);
    });
  };

  render() {
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">New Guide </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Adding Guides</h5>
                  {/* <h5 className="subhead">Labels are left from fields</h5> */}
                </div>
                <Alert
                  color="danger"
                  visible={this.props.error !== null ? true : false}
                >
                  <p>
                    <span className="bold-text">Warning!</span>
                    {this.props.error}
                  </p>
                </Alert>
                <AddGuideForm
                  languages={this.props.meta ? this.props.meta.languages : []}
                  guides={this.props.meta ? this.props.meta.guides : []}
                  sect={this.props.meta ? this.props.meta.sect : []}
                  onTranslate={this.onTranslate}
                  onSubmit={(values) => {
                    console.log({ values });

                    this.props.doAddGuide(
                      {
                        ...values,
                        translations: transformTransalationsForBackend(
                          values.translations || {}
                        ),
                      },
                      this.props.history
                    );
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ guide, metaData, googleTranslation }) => {
  return {
    loading: guide.loading || googleTranslation.loading,
    error: guide.error || googleTranslation.error,
    contents: guide.contents,
    meta: metaData.metaData,
    translations: googleTranslation.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doAddGuide: (obj, props) => dispatch(addGuide(obj, props)),
    doTranslate: (terms, source, target, cb) =>
      dispatch(translateTo(terms, source, target, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGuides);
