import {
  ASSISTANCES,
  ASSISTANCES_FAILED,
  ASSISTANCES_SUCCESS,
} from "../actions/assistanceActions";

const initialState = {
  loading: false,
  assistances: [],
  message: null,
  data: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ASSISTANCES:
      return { ...state, loading: true, error: null };
    case ASSISTANCES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        assistances: action.payload,
      };
    case ASSISTANCES_FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
}
