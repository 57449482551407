import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import EditLostReport from './components/editLostReport';
import { editLostReport } from '../../redux/actions/lostReportsActions';
import { connect } from 'react-redux';
import webservice from '../../utils/networkApis';
import Alert from '../../shared/components/Alert';

class EditLostReports extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null,
  };
  componentDidMount() {
    const id = this.props.match.params.id;
    webservice.getOneLostReport(id).then((resp) => {
      let data = resp.data.data;

      console.log('data', data);
      data = {
        ...data,
      };
      this.setState({ initialValues: data, loading: false });
    });
  }
  render() {
    const { loading, initialValues } = this.state;

    const { error } = this.props;
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Edit Lost Reports </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <Alert
                color="success"
                visible={this.props.message !== null ? true : false}
              >
                <p>
                  <span className="bold-text">Success </span>
                  {this.props.message}
                </p>
              </Alert>
              <CardBody>
                {loading ? (
                  <div className={`load`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path
                          fill="#4ce1b6"
                          d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <EditLostReport
                    _error={error}
                    initialValues={initialValues}
                    users={this.props.meta ? this.props.meta.users : []}
                    onSubmit={(values) => {
                      this.props.doEditLostReport(
                        values,
                        this.props.match.params.id
                      );
                      const id = this.props.match.params.id;
                      this.props.history.push(`/admin/lost-report/${id}`);
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ lostReport, metaData }) => {
  return {
    loading: lostReport.loading,
    error: lostReport.error,
    message: lostReport.message,
    meta: metaData.metaData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // doAddGuide: (obj, props) => dispatch(addGuide(obj, props)),
    doEditLostReport: (obj, id) => dispatch(editLostReport(obj, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLostReports);
