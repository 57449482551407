import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Table from './components/DataTableNew';
import { getGuidesTree, deleteGuide } from '../../redux/actions/guidesActions';
import { connect } from 'react-redux';

class Guides extends React.Component {
  componentDidMount() {
    this.props.doGetGuidesTree();
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title"> Guides </h3>
          </Col>
          <Col md={2}>
            <Link
              to="/admin/new-guide"
              className="btn btn-primary products-list__btn-add"
            >
              New Guide
            </Link>
          </Col>
        </Row>
        <Row>
          {this.props.guides && this.props.meta ? (
            <Table
              meta={this.props.meta}
              data={this.props.guides || []}
              doDeleteGuide={(id) => {
                //   console.log("id", id);
                this.props.doDeleteGuide(id);
              }}
            />
          ) : (
            'Loading...'
          )}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ guide, metaData }) => {
  return {
    loading: guide.loading,
    error: guide.error,
    guides: guide.guides,
    meta: metaData.metaData,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetGuidesTree: () => dispatch(getGuidesTree()),
    doDeleteGuide: (id) => dispatch(deleteGuide(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Guides);
