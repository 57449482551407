import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AddItinerary from "./components/addItineraryForm";
import { editItinerarie } from "../../redux/actions/itinerariesActions";
import { translateTo } from "../../redux/actions/googleTranslation";
import { connect } from "react-redux";
import webservice from "../../utils/networkApis";
import {
  transformTransalationsFromBackend,
  transformTransalationsForBackend,
} from "../../utils/dataTransformerForAPI";
import Alert from "../../shared/components/Alert";
import Loading from "../../shared/components/Loading";
import { normalizeDate } from "../../utils/date";
import moment from "moment-timezone";
import {
  changeTimezoneOfDateBy,
  formatDate,
  getZones,
} from "../../utils/general";
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};
class EditItineraries extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null,
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    webservice.getOneitinerary(id).then((resp) => {
      let data = resp.data.data;

      data = {
        ...data,
        translations: transformTransalationsFromBackend(
          data.translations || []
        ),
      };

      console.log(data.translations);
      this.setState({ initialValues: data, loading: false });
    });
  }

  componentDidUpdate() {
    scrollToTop();
  }

  onTranslate = (terms, source, target, callback) => {
    this.props.doTranslate(terms, source, target, () => {
      callback(this.props.translations);
    });
  }

  render() {
    const { loading, initialValues } = this.state;

    const { error } = this.props;
    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Edit Program </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <Alert
                color="success"
                visible={this.props.message !== null ? true : false}
              >
                <p>
                  <span className="bold-text">Success </span>
                  {this.props.message}
                </p>
              </Alert>
              <CardBody>
                {loading ? (
                  <Loading loading />
                ) : (
                  <AddItinerary
                    _error={error}
                    initialValues={{
                      ...initialValues,
                      time: formatDate(
                        initialValues.time,
                        initialValues.zone || "Asia/Riyadh"
                      ),
                    }}
                    languages={this.props.meta ? this.props.meta.languages : []}
                    groups={this.props.meta ? this.props.meta.groups : []}
                    places={this.props.meta ? this.props.meta.places : []}
                    zones={getZones()}
                    sendUpdateNotify={true}
                    onTranslate={this.onTranslate}
                    onSubmit={(values) => {
                      const id = this.props.match.params.id;
                      values.time = changeTimezoneOfDateBy(
                        values.time,
                        values.zone
                      );
                      this.setState({ loading: true });
                      this.props.doEditItinerary(
                        {
                          ...values,
                          contentId: values.content_id,
                          translations: transformTransalationsForBackend(
                            values.translations || {}
                          ),
                        },
                        id,
                        () => {
                          this.setState({
                            loading: false,
                          });
                          this.props.history.goBack();
                        }
                      );
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ Itinerarie, metaData, googleTranslation }) => {
  return {
    loading: Itinerarie.loading || googleTranslation.loading,
    error: Itinerarie.error || googleTranslation.error,
    contents: Itinerarie.contents,
    message: Itinerarie.message || googleTranslation.message,
    meta: metaData.metaData,
    translations: googleTranslation.translations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doEditItinerary: (obj, id, cb) => dispatch(editItinerarie(obj, id, cb)),
    doTranslate: (terms, source, target, cb) =>
      dispatch(translateTo(terms, source, target, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditItineraries);
