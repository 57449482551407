/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import DataPaginationTable from "../../../shared/components/table/DataPaginationTable";
import Pagination from "../../../shared/components/pagination/Pagination";
import Modal from "../../../shared/components/Modal";
import Alert from "../../../shared/components/Alert";
import webservice from "../../../utils/networkApis";

const filterRows = (originalRows, pageNumber, rowsOnPage = 10) => {
  const rowsFrom = rowsOnPage * (pageNumber - 1);
  const rowsTo = rowsFrom + rowsOnPage;
  return originalRows.slice(rowsFrom, rowsTo);
};

class DataTable extends PureComponent {
  constructor(props) {
    super(props);
    this.heads = [
      {
        key: "sort_index",
        name: "NO.",
        width: 60,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/group/${args.rowId}`);
          },
        },
      },
      {
        key: "leader",
        name: "Leader",
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/group/${args.rowId}`);
          },
        },
      },
      {
        key: "mobile",
        name: "Mobile",
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/group/${args.rowId}`);
          },
        },
        sortable: true,
        width: 100,
      },
      {
        key: "starts_on",
        name: "Starts On",
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/group/${args.rowId}`);
          },
        },
        sortable: true,
        width: 100,
      },
      {
        key: "ends_on",
        name: "Ends On",
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/group/${args.rowId}`);
          },
        },
        sortable: true,
        width: 100,
      },
      {
        key: "order_no",
        name: "Order No",
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/group/${args.rowId}`);
          },
        },
        sortable: true,
      },
      {
        key: "actions",
        name: "Actions",
        cellClass: "ActionCell",
      },
    ];

    const initialPageNumber = 1;
    const initialRowsCount = 10;

    const originalRows = this.props.data;
    const currentPageRows = filterRows(
      this.props.data,
      initialPageNumber,
      initialRowsCount
    );

    this.state = {
      rows: originalRows,
      rowsToShow: currentPageRows,
      pageOfItems: initialPageNumber,
      itemsToShow: initialRowsCount,
      modal: false,
      id: null,
      selectedIndexes: [],
      selectedIds: [],
    };
  }

  nameActions = (row) => [
    {
      icon: (
        <Button
          style={{ marginRight: 10 }}
          size="sm"
          className="badge badge-success"
        >
          New Message
        </Button>
      ),
      callback: (id) => {
        this.props.history.push(`/admin/add-announcement?group_id=${row.id}`);
      },
    },
    {
      icon: (
        <Button
          style={{ marginRight: 10 }}
          size="sm"
          className="badge badge-success"
        >
          Programs
        </Button>
      ),
      callback: (id) => {
        this.props.history.push(`/admin/groups/${row.id}/itineraries`);
      },
    },
  ];

  onRowsSelected = (rows) => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        rows.map((r) => r.rowIdx)
      ),
      selectedIds: this.state.selectedIds.concat(rows.map((r) => r.row.id)),
    });
  };

  onRowsDeselected = (rows) => {
    let rowIndexes = rows.map((r) => r.rowIdx);
    let rowIds = rows.map((r) => r.row.id);
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        (i) => rowIndexes.indexOf(i) === -1
      ),
      selectedIds: this.state.selectedIds.filter(
        (i) => rowIds.indexOf(i) === -1
      ),
    });
  };

  multipleDelete = () => {
    webservice.groupBulkDelete(this.state.selectedIds).then(() => {
      this.setState({ selectedIndexes: [], selectedIds: [] });
      if (this.props.refreshData) this.props.refreshData();
    });
  };

  onChangePage = (pageOfItems) => {
    const { rows, itemsToShow } = this.state;
    if (pageOfItems) {
      const rowsToShow = filterRows(rows, pageOfItems, itemsToShow);
      this.setState({ rowsToShow, pageOfItems });
    }
  };

  toggle = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  getCellActions = (column, row) => {
    const cellActions = {
      actions: this.nameActions(row),
    };
    return cellActions[column.key];
  };

  onSorting = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    };
    const { rows, pageOfItems, itemsToShow } = this.state;
    if (sortDirection !== "NONE") {
      let sortRows = [...rows].sort(comparer);
      sortRows = filterRows(sortRows, pageOfItems, itemsToShow);
      this.setState({ rowsToShow: sortRows });
      return sortRows;
    }
    const sortRows = filterRows(rows, pageOfItems, itemsToShow);
    this.setState({ rowsToShow: sortRows });
    return sortRows;
  };
  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.rows) {
      return {
        rows: props.data,
        rowsToShow: filterRows(
          props.data,
          state.pageOfItems,
          state.itemsToShow
        ),
      };
    }
    return null;
  }
  render() {
    const { rows, itemsToShow, pageOfItems, rowsToShow, selectedIndexes } =
      this.state;
    const { entityName } = this.props;
    return (
      <Col md={12} lg={12}>
        <Modal
          open={this.state.modal}
          success={() => {
            this.state.id
              ? this.props.success(this.state.id)
              : this.multipleDelete();
            this.setState({ modal: false, id: null });
          }}
          toggle={this.toggle}
          color="danger"
          title="Warning!"
          colored
          btn="Danger"
          message={`Are you sure you want to delete ${entityName} ?`}
        />
        <Card>
          <CardBody>
            {selectedIndexes.length !== 0 ? (
              <React.Fragment>
                <button
                  onClick={() => this.setState({ modal: true })}
                  className="btn btn-danger products-list__btn-add"
                >
                  Delete Selected
                </button>
              </React.Fragment>
            ) : null}
            <Alert
              color="danger"
              visible={this.props.error !== null ? true : false}
            >
              <p>
                <span className="bold-text">Error! </span>
                We can't Delete it Because its used
              </p>
            </Alert>
            <DataPaginationTable
              heads={this.heads}
              rows={rowsToShow}
              key={rows.length}
              onSorting={this.onSorting}
              extraProps={{
                rowSelection: {
                  showCheckbox: true,
                  enableShiftSelect: true,
                  onRowsSelected: this.onRowsSelected,
                  onRowsDeselected: this.onRowsDeselected,
                  selectBy: {
                    indexes: selectedIndexes,
                  },
                },
              }}
              getCellActions={this.getCellActions}
            />
            <Pagination
              itemsCount={rows.length}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withRouter(DataTable);
