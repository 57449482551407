/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import DataPaginationTable from "../../../shared/components/table/DataPaginationTable";
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import Pagination from "../../../shared/components/pagination/Pagination";
import Modal from "../../../shared/components/Modal";
import Alert from "../../../shared/components/Alert";

const filterRows = (originalRows, pageNumber, rowsOnPage = 10) => {
  const rowsFrom = rowsOnPage * (pageNumber - 1);
  const rowsTo = rowsFrom + rowsOnPage;
  return originalRows.slice(rowsFrom, rowsTo);
};

const selectors = Data.Selectors;
const { SingleSelectFilter } = Filters;

class DataTable extends PureComponent {
  constructor(props) {
    super(props);
    this.heads = [
      {
        key: "order",
        name: "Order",
        // filterable: true,
        // filterRenderer: SingleSelectFilter,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/content/${args.rowId}`);
          },
        },
      },
      {
        key: "title",
        name: "Title",
        filterable: true,
        filterRenderer: SingleSelectFilter,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/content/${args.rowId}`);
          },
        },
        sortable: true,
      },
      {
        key: "type",
        name: "Type",
        filterable: true,
        filterRenderer: SingleSelectFilter,
        events: {
          onClick: function (ev, args) {
            props.history.push(`/admin/content/${args.rowId}`);
          },
        },
        sortable: true,
      },
      ...(props.type && props.type.toLowerCase() === 'restaurant'
        ? [
            {
              key: "city",
              name: "City",
              filterable: true,
              filterRenderer: SingleSelectFilter,
              events: {
                onClick: function (ev, args) {
                  props.history.push(`/admin/content/${args.rowId}`);
                },
              },
              sortable: true,
            },
            {
              key: "category",
              name: "Category",
              filterable: true,
              filterRenderer: SingleSelectFilter,
              events: {
                onClick: function (ev, args) {
                  props.history.push(`/admin/content/${args.rowId}`);
                },
              },
              sortable: true,
            },
          ]
        : []),
      {
        key: "created_by",
        name: "Created By",
        sortable: true,
      },
      {
        key: "actions",
        name: "Actions",
        cellClass: "ActionCell",
      },
    ];

    const initialPageNumber = 1;
    const initialRowsCount = 10;

    const originalRows = this.props.data;
    const currentPageRows = filterRows(
      this.props.data,
      initialPageNumber,
      initialRowsCount
    );

    this.state = {
      rows: originalRows,
      rowsToShow: currentPageRows,
      pageOfItems: initialPageNumber,
      filters: {},
      itemsToShow: initialRowsCount,
      modal: false,
      id: null,
    };
  }

  nameActions = (row) => [
    {
      icon: (
        <Button
          style={{ marginRight: 10 }}
          size="sm"
          className="badge badge-success"
        >
          Destroy
        </Button>
      ),
      callback: (id) => {
        console.log("id", row);
        this.setState({ modal: true, id: row.id });
      },
    },
    {
      icon: (
        <Button
          style={{ marginRight: 10 }}
          size="sm"
          className="badge badge-success"
        >
          edit
        </Button>
      ),
      callback: (id) => {
        this.props.history.push(`/admin/content/${row.id}/edit`);
      },
    },
  ];

  onChangePage = (pageOfItems) => {
    const { rows, itemsToShow } = this.state;
    if (pageOfItems) {
      const rowsToShow = filterRows(rows, pageOfItems, itemsToShow);
      this.setState({ rowsToShow, pageOfItems });
    }
  };

  toggle = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  getCellActions = (column, row) => {
    const cellActions = {
      actions: this.nameActions(row),
    };
    return cellActions[column.key];
  };

  handleFilterChange = (filter) => (filters) => {
    const newFilters = { ...filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    return newFilters;
  };
  setFilters = (temperIt) => {
    this.setState(
      typeof temperIt == "function"
        ? ({ filters }) => ({ filters: temperIt(filters), pageOfItems: 1 })
        : { filters: temperIt }
    );
  };
  getValidFilterValues(rows, columnId) {
    return rows
      .map((r) => r[columnId])
      .filter((item, i, a) => {
        return i === a.indexOf(item);
      });
  }

  getRows(rows, filters) {
    return selectors.getRows({ rows, filters });
  }

  onSorting = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    };
    const { rows } = this.state;
    if (sortDirection !== "NONE") {
      let sortRows = [...rows].sort(comparer);

      this.setState({ rows: sortRows });
      return sortRows;
    }
    this.setState({ rows: this.props.data });
    return this.props.data;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data.length !== state.rows.length) {
      return {
        rows: props.data,
      };
    }
    return null;
  }
  render() {
    const { rows, itemsToShow, pageOfItems, rowsToShow, filters } = this.state;
    const { entityName } = this.props;

    const filteredRows = this.getRows(rows, filters);

    return (
      <Col md={12} lg={12}>
        <Modal
          open={this.state.modal}
          success={() => {
            this.props.success(this.state.id);
            this.setState({ modal: false });
          }}
          toggle={this.toggle}
          color="danger"
          title="Warning!"
          colored
          btn="Danger"
          message={`Are you sure you want to delete ${entityName} ?`}
        />
        <Card>
          <CardBody>
            <Alert
              color="danger"
              visible={this.props.error !== null ? true : false}
            >
              <p>
                <span className="bold-text">Error! </span>
                We can't Delete it Because its used
              </p>
            </Alert>
            <DataPaginationTable
              heads={this.heads}
              rows={filteredRows}
              key={rows.length}
              onSorting={this.onSorting}
              getCellActions={this.getCellActions}
              extraProps={
                this.props.addFilter
                  ? {
                      toolbar: <Toolbar enableFilter={true} />,
                      onAddFilter: (filter) =>
                        this.setFilters(this.handleFilterChange(filter)),
                      onClearFilters: () => this.setFilters({}),
                      getValidFilterValues: (columnKey) =>
                        this.getValidFilterValues(rows, columnKey),
                    }
                  : {}
              }
              pagination={{
                itemsToShow,
                pageOfItems,
              }}
            />
            <Pagination
              itemsCount={filteredRows.length}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withRouter(DataTable);
