import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import ShowEntity from "../../shared/components/ShowEntity";
import { addGuide, editGuide } from "../../redux/actions/guidesActions";
import { connect } from "react-redux";
import webservice from "../../utils/networkApis";

class ShowGuides extends React.Component {
  state = {
    loading: true,
    initialValues: null,
    error: null
  };
  fetchIt = id => {
    this.setState({ loading: true, id });
    webservice.getOneGroup(id).then(resp => {
      let data = resp.data.data;

      this.setState({ initialValues: data, loading: false });
    });
  };

  componentDidMount() {
    this.fetchIt(this.props.match.params.id);
  }

  componentWillReceiveProps(props) {
    let id = props.match.params.id;

    if (id != this.state.id) this.fetchIt(id);
  }

  render() {
    const { loading, initialValues } = this.state;

    const { meta } = this.props;

    return (
      <ShowEntity
        values={
          initialValues
            ? [
              { label: "ID", value: initialValues.id },
              {
                label: "ORDER NO",
                value: initialValues.order_no
              },
              {
                label: "TITLE",
                value: initialValues.translations && initialValues.translations.title
              },
              {
                label: "LEADER",
                value: initialValues.leader
              },
              {
                label: "AGENTS",
                value: initialValues.agents
              },
              {
                label: "EMAIL",
                value: initialValues.email
              },
              {
                label: "MOBILE",
                value: initialValues.mobile
              },
              {
                label: "PASSENGERS",
                value: initialValues.passengers
              },
              {
                label: "STARTS_ON",
                value: initialValues.starts_on
              },
              {
                label: "ENDS_ON",
                value: initialValues.ends_on
              },
              {
                label: "CREATED_AT",
                value: initialValues.created_at
              },
              {
                label: "UPDATED_AT",
                value: initialValues.updated_at
              }
            ]
            : []
        }
        loading={loading}
        label={`Show ${initialValues ? initialValues.leader : ""}`}
      />
    );
  }
}

const mapStateToProps = ({ guide, metaData }) => {
  return {
    loading: guide.loading,
    error: guide.error,
    contents: guide.contents,
    meta: metaData.metaData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doAddGuide: (obj, props) => dispatch(addGuide(obj, props)),
    doEditGuide: (obj, id) => dispatch(editGuide(obj, id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowGuides);
