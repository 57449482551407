import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Table from "./components/DataTable";
import { connect } from "react-redux";
import moment from "moment";

import {
  deleteLostReport,
  getLostReports,
} from "../../redux/actions/lostReportsActions";

class LostReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: LostReports._parseData(props.lostReports || [], props.meta),
      lostReports: props.lostReports,
      meta: props.meta,
    };
  }
  componentDidMount() {
    this.props.doGetLostReport();
  }
  static _parseData(lostReports, meta) {
    console.log({ lostReports, meta: JSON.stringify(meta, null, 0) });
    return lostReports.map((item) => {
      return {
        id: item.id,
        user: item.user
          ? `${item.user.first_name} ${item.user.last_name}`
          : "N/A",
        group:
          (item.user &&
            ((meta && meta.groupsKeys && meta.groupsKeys[item.user.group_id]) ||
              item.user.group_id)) ||
          "N/A",
        userId: item.user_id,
        createdAt: moment(item.createdAt).format("M/DD/YYYY HH:mm"),
        resolvedById: item.resolved_by_id,
        resolvedBy: item.resolved_by
          ? `${item.resolved_by.first_name} ${item.resolved_by.last_name}`
          : "N/A",
      };
    });
  }
  static getDerivedStateFromProps(props, state) {
    if (props.lostReports !== state.lostReports || state.meta !== props.meta) {
      return {
        data: LostReports._parseData(props.lostReports || [], props.meta),
        lostReports: props.lostReports,
        meta: props.meta,
      };
    }
    return null;
  }

  render() {
    console.log(this.state.data);

    return (
      <Container>
        <Row>
          <Col md={10}>
            <h3 className="page-title"> Lost Reports </h3>
          </Col>
        </Row>
        <Row>
          <Table
            refreshData={() => this.props.doGetLostReport()}
            data={
              (this.state.data && this.state.data.filter((el) => el.userId)) ||
              []
            }
            meta={this.state.meta}
            success={(id) => {
              this.props.doDeleteUser(id);
            }}
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ lostReport, metaData }) => {
  return {
    loading: lostReport.loading,
    error: lostReport.error,
    lostReports: lostReport.lostReports,
    meta: metaData.metaData,
    // user: login.salesQuotes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetLostReport: () => dispatch(getLostReports()),
    doDeleteLostReport: (id) => dispatch(deleteLostReport(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LostReports);
